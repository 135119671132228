<template>
    <section class="text-on-black">
        <div class="container-landing">
            <div class="text-on-black__wrapper">
                <p class="text-on-black__text">
                    Miejsce dla ludzi, którzy nie chcą marnować czasu na osoby szukające czegoś innego.
                </p>
                <SimpleButton
                    text="Zapisz się"
                    route="/register"
                    :is-on-black=true
                />
            </div>
        </div>
    </section>
</template>
  
<script>
    import SimpleButton from '@/components/landing-03/atoms/SimpleButton.vue';


    export default {
        data() {
            return {
            };
        },
        props: {
            
        },
        computed: {
            
        },
        components: {
            SimpleButton
        }
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";
    @import "@/assets/scss/breakpoints.scss";
    @import "@/assets/scss/containers.scss";
    @import "@/assets/scss/Nunito.css";
    @import "@/assets/scss/global.scss";


    .text-on-black {
        background-color: $black3;
        padding: 100px 0;
        @include breakpoint-max('mobile') {
            padding: 100px 0 ;
        }
        
        &__wrapper {
            a {
                @include breakpoint-max('mobile') {
                    display: none !important;
                }
            }
            @include breakpoint-max('mobile') {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
        &__text {
            max-width: 833px;
            font-size: 8rem;
            font-family: 'Promenade', sans-serif;
            color: $white;
            margin-bottom: 119px;
            @include breakpoint-max('mobile') {
                font-size: 48px;
                line-height: 58px;
                text-align: center;
                margin-bottom: 0px;
            }
        }
    }

</style>