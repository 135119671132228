<template>
    <section class="privacy">
        <HeaderComponent/>
        <div class="container">
            <div class="privacy__wrapper">
                <h1>Polityka prywatności 
Serwisu „Biuro Randkowe”</h1>
<h3>Informacje ogólne</h3>
<ul>
<li> <strong>[Polityka]</strong> Niniejsza Polityka prywatności (dalej: „Polityka”) przedstawia informacjena temat przetwarzania Twoich danych osobowych w serwisie „Biuro Randkowe” prowadzonym pod adresem internetowym: https://biurorandkowe.pl (dalej: „Serwis” lub „Biuro Randkowe”). </li>

<li> <strong>[Administrator]</strong> Administratorem Twoich danych osobowych przetwarzanych w Serwisie jest B87 spółka z ograniczoną odpowiedzialnością z siedzibą w Kaliszu (ul. Baśniowa 25, 62-800 Kalisz, KRS: 0001061711, NIP: 6182199989, REGON: 526571132), z którym możesz skontaktować się pod adresem email: rodo@biurorandkowe.pl. </li>

<li> <strong>[Bezpieczeństwo przetwarzania danych osobowych]</strong> Administrator przetwarza dane osobowe zgodnie z przepisami o ochronie danych osobowych, w szczególności Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych, Dz. U. UE. L. z 2016 r. Nr 119, str. 1 z późn. zm., dalej: RODO”) oraz Ustawy z dnia 10 maja 2018 r. o ochronie danych osobowych (t.j. Dz. U. z 2019 r. poz. 1781). </li>

<li> <strong>[Informacje o Użytkownikach]</strong> Serwis przetwarza informacje o osobach odwiedzających Serwis, w tym korzystających z funkcjonalności Serwisu (dalej: „Użytkownicy”): </li></ul>
<ul>
<li> informacje dobrowolnie wprowadzone w formularzach oraz podane w komunikacji przez Użytkownika; </li>
<li> zapisywane w urządzeniach końcowych pliki cookie (tzw. “ciasteczka”); </li>
<li> informacje dotyczące ich aktywności w Serwisie i gromadzone w logach serwera, w tym parametry połączenia (oznaczenie czasu, adres IP) w zakresie niezbędnym do administrowania Serwisem; dane te nie służą identyfikacji Użytkowników. </li>
</ul>
<ul>
<li> <strong>[Bezpieczeństwo]</strong> Administrator szanuje prawo do prywatności i dba o bezpieczeństwo danych. W tym celu używa m.in. bezpiecznego protokołu szyfrowania komunikacji (SSL) oraz adekwatnych środków technicznych i organizacyjnych zapewniających bezpieczeństwo przetwarzania danych osobowych. </li>

<li> <strong>[Znaczenie pojęć]</strong> Używane w Polityce prywatności pojęcia oznaczone wielką literą mają znaczenie nadane im w Polityce oraz w Regulaminie świadczenia usług w Serwisie „Biuro Randkowe” (dalej jako „Regulamin”). </li>
</ul>

<h3>Jak przetwarzane są Twoje dane osobowe</h3>
<ul>
<li> <strong>[Dobrowolność]</strong> Serwis zbiera wyłącznie informacje podane dobrowolnie przez Użytkowników.</li>

<li> <strong>[Zapis na listę oczekujących oraz rejestracja Konta w Serwisie]</strong> </li>

<li><strong>[Cel i podstawa przetwarzania]</strong> Aby zapisać się na listę oczekujących na Usługi	świadczone w Serwisie Biuro Randkowe Użytkownik musi założyć konto w Serwisie. </li>
</ul>
W tym celu:
<ul>
<li> podaje w polu zapisu swoje dane osobowe w postaci adresu email, jak również hasło do logowania w Serwisie – dane te są niezbędne do dokonania zapisu na listę oczekujących i służą założeniu konta w Serwisie w celu świadczenia usług na rzecz Użytkownika po ich uruchomieniu oraz weryfikacji Użytkowników dla zwiększenia bezpieczeństwa innych Użytkowników Serwisu, </li>
<li> otrzymuje na podany adres email wiadomość aktywującą założenie konta w Serwisie, </li>
<li> po zalogowaniu w Serwisie Użytkownik może dokonać zapisu na listę oczekujących na Usługi świadczone w Serwisie poprzez wypełnienie formularza zapisu – dane w formularzu służą wyłącznie zapisowi na listę oczekujących w celu sprawnego rozpoczęcia świadczenia Usług na rzecz Użytkownika zapisanego na listę oczekujących po uruchomieniu Usług; dane te nie są udostępniane innym Użytkownikom, </li>
<li> o uruchomieniu Usług Biura Randkowego Użytkownik zostanie powiadomiony wiadomością wysłaną na podane w formularzu zapisu adres email oraz numer telefonu. </li>
</ul>

Założenie Konta w Serwisie przebiega na zasadach określonych w Regulaminie udostępnianym w Serwisie.

 <strong>Powyżej wskazane dane Administrator przetwarza w celu:</strong>
<ul>
<li> założenia konta w Serwisie na podstawie udzielonej zgody (art. 6 ust. 1 lit. a RODO); w każdej chwili Użytkownik może usunąć konto w Serwisie, a po uruchomieniu Usług Użytkownik może dokonać wyboru świadczonych Usług w każdym czasie (z zastrzeżeniem okresu przechowywania danych opisanego niżej), </li>
<li> zapisu na listę oczekujących na usługi świadczone w Serwisie Biuro Randkowe na podstawie udzielonej zgody (art. 6 ust. 1 lit. a RODO), a jeśli ujawniona zostaje orientacja seksualna na podstawie wyraźnie udzielonej zgody (art. 9 ust. 2 lit. a RODO); podane dane mają pozwolić powiadomić Użytkownika o uruchomieniu Usług w Serwisie w odniesieniu do wybranej przez Użytkownika kategorii dotyczącej preferencji poszukiwania osoby w danej lokalizacji, </li>
<li> przesyłania powiadomień o uruchomieniu Usług w Serwisie w odniesieniu do wybranej przez Użytkownika kategorii dotyczącej preferencji poszukiwania osoby w danej lokalizacji na podstawie udzielonej zgody (art. 6 ust.1 lit. a RODO). </li>
</ul>
Podanie tych danych jest dobrowolne, ale niezbędne do zrealizowania na rzecz Użytkownika powyższych celów, a w zakresie danych kontaktowych do otrzymywania powiadomień o uruchomieniu Usług w Biurze Randkowym przez email lub SMS.
Udzielona zgoda może być w każdym czasie cofnięta bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem. 

 <strong>[Okres przechowywania danych]</strong> Administrator będzie przechowywać te dane osobowe do czasu cofnięcia zgody, a jeśli zgoda nie zostanie cofnięta, Administrator będzie przechowywał te dane przez okres 12 miesięcy od dnia przesłania Użytkownikowi powiadomienia o rozpoczęciu świadczenia Usług w Serwisie, a jeśli w tym terminie Użytkownik zakupi Usługę w Serwisie w wybranym Pakiecie, wówczas Administrator będzie kontynuował przetwarzanie tych danych w celach określonych przy świadczeniu Usług w Serwisie i na odpowiedniej do tego podstawie prawnej. 
 <strong>[Odbiorcy danych]</strong> Podane dane Administrator może przekazywać jedynie podmiotom świadczącym dla niego usługi związane z działaniem Serwisu w zakresie rejestracji Konta Użytkownika i prowadzenia listy oczekujących oraz zarządzania powiadomieniami.

 <strong>[Przetwarzanie danych osobowych z związku ze świadczonymi Usługami] </strong> 
	 <strong>[Cel i podstawa przetwarzania] </strong>
Przed zamówieniem Usługi w ramach dostępnych w Serwisie Pakietów Użytkownik wypełnia Formularz zgłoszeniowy na zasadach określonych w Regulaminie. 
Użytkownik, który wypełnił Formularz zgłoszeniowy może przekazać go Usługodawcy do weryfikacji według zasad określonych w Regulaminie.
Do czasu dokonania przez Użytkownika płatności za Pakiet Basic Usługodawca nie zapisuje i nie przetwarza wprowadzonych przez Użytkownika danych i informacji w Formularzu zgłoszeniowym w innym celu niż dla przechowywania ich na potrzeby uzupełnienia tego Formularza przez Użytkownika.

 <strong>Dane podane przy rejestracji Konta:</strong> Po zamówieniu przez Użytkownika Usługi w ramach wybranego Pakietu Usługodawca przetwarza dane kontaktowe Użytkownika podane podczas rejestracji Konta w Serwisie i zapisaniu się na listę oczekujących (lub w późniejszym czasie zaktualizowane) w następujących celach: 

 <strong>w celu świadczenia przez Usługodawcę na rzecz Użytkownika wybranej i opłaconej przez Użytkownika Usługi</strong> na podstawie niezbędności do zawarcia i wykonywania umowy (art. 6 ust. 1 lit. b RODO), tj. w następujących celach szczegółowych:

umożliwienia korzystania przez Użytkownika z Serwisu, zawartych na nim treści i dostępnych funkcjonalności,
utrzymywania Konta Użytkownika w Serwisie w ramach członkostwa w Biurze Randkowym,
przeprowadzenia Usługi indywidualnej, w postaci indywidualnej opieki i współpracy oraz możliwości konsultacji z Barbarą Strójwąs (dotyczy tylko Użytkowników korzystających z Pakietu Premium).

 <strong>w celu wysyłania Użytkownikom powiadomień</strong> informujących o zaproponowaniu przez Usługodawcę Dopasowania (jeżeli zostaje przedstawione) i umieszczeniu stosownej informacji w tym zakresie w Serwisie, na podstawie prawnie uzasadnionego interesu Administratora w postaci wysyłania powiadomień w związku ze świadczonymi Usługami (art. 6 ust. 1 lit. f RODO),
 <strong>w celu komunikacji z Użytkownikiem</strong> na podstawie prawnie uzasadnionego interesu Administratora w postaci prowadzenia komunikacji (art. 6 ust. 1 lit. f RODO),
 <strong>w celu dochodzenia lub obrony przed ewentualnymi roszczeniami</strong> na podstawie prawnie uzasadnionego interesu Administratora w postaci dochodzenia lub obrony przed ewentualnymi roszczeniami (art. 6 ust. 1 lit f RODO).

Podanie powyższych danych jest dobrowolne jednak brak podania tych danych uniemożliwi świadczenie Usług w Serwisie. 

 <strong>Dane zawarte w Formularzu zgłoszeniowym:</strong> Po zamówieniu przez Użytkownika Usługi w ramach wybranego Pakietu Usługodawca przetwarza dane zawarte w Formularzu zgłoszeniowym w następujących celach:

 <strong>w celu świadczenia przez Usługodawcę na rzecz Użytkownika wybranej i opłaconej przez Użytkownika Usługi</strong> na podstawie niezbędności do zawarcia i wykonywania umowy (art. 6 ust. 1 lit. b RODO), a w zakresie podanych danych stanowiących szczególne kategorie danych na podstawie wyraźnej zgody (art. 9 ust. 2 lit. a RODO), tj. w następujących celach szczegółowych (w zależności od zakresu wybranej Usługi):
<ul>
<li>umożliwienia korzystania przez Użytkownika z Serwisu, zawartych na nim treści i dostępnych funkcjonalności,</li>
<li>weryfikacji przez Usługodawcę wypełnionego przez Użytkownika Formularza zgłoszeniowego i stworzenia Profilu Singla, sprawdzenia zgodności i spójności przesłanych informacji,</li>
<li>analizy wypełnionego przez Użytkownika Formularza zgłoszeniowego w celu świadczenia Usług w ramach wybranego przez Użytkownika Pakietu,</li>
<li>rozpoczęcia przez Usługodawcę procesu poszukiwań w celu przedstawienia propozycji Dopasowania po dokonaniu płatności za odpowiedni Pakiet, </li>
<li>utrzymywania Konta Użytkownika w Serwisie w ramach członkostwa w Biurze Randkowym,</li>
<li>publikacji przez Usługodawcę Profilu Singla w bazie Biura Randkowego, który w wersji Profilu publicznego prezentowany jest dopasowanej osobie,</li>
<li>prezentacji przez Usługodawcę Profilu publicznego osobom korzystającym z Pakietu Plus lub Pakietu Premium w celu przeprowadzenia doboru, </li>
<li>umożliwienia korzystania Użytkownikowi z funkcji czatu i jego dodatkowych funkcjonalności w przypadku zaakceptowania przez obie strony Dopasowania, </li>
<li>przeprowadzenia Usługi indywidualnej, w postaci indywidualnej opieki i współpracy oraz możliwości konsultacji z Barbarą Strójwąs (dotyczy tylko Użytkowników korzystających z Pakietu Premium),</li>
<li>weryfikacji przez Usługodawcę zgodności korzystania przez Użytkownika z Serwisu, w tym moderacji treści.</li>
</ul>
 <strong>Informacje podane przez Użytkownika w Formularzu zgłoszeniowym stanowią:</strong>
<ul>
<li>dane osobowe zwykłe (art. 6 RODO) i będą przetwarzane przez Administratora w celu świadczenia usług w Serwisie na podstawie niezbędności do wykonywania umowy (art. 6 ust. 1 lit. b RODO),</li>
<li>szczególne kategorie danych (dane wrażliwe, art. 9 ust. 1 RODO), w szczególności ujawniające pochodzenie rasowe lub etniczne, poglądy polityczne, przekonania religijne lub światopoglądowe, seksualności lub orientacji seksualnej, zdrowiu tej osoby i będą przetwarzane przez Administratora w celu świadczenia usług w Serwisie jednak wyłącznie na podstawie wyraźnej zgody użytkownika (art. 9 ust. 2 lit. a RODO). </li>
</ul>
Zgoda może być cofnięta w każdym czasie, co nie wpływa na zgodność z prawem przetwarzania sprzed chwili cofnięcia. Użytkownik może cofnąć zgodę przesyłając oświadczenie w tym zakresie na adres email: rodo@biurorandkowe.pl, jednak w przypadku cofnięcia zgody dotyczącej wszystkich powyższych rodzajów danych osobowych lub któregokolwiek z nich, Administrator będzie uprawniony do zaprzestania dalszego świadczenia Usług w Serwisie w wybranym przez Użytkownika Pakiecie, jeżeli od tych danych będzie uzależnione wykonanie Usługi, w szczególności z uwagi na brak możliwości przeprowadzenia procesu Dopasowania Użytkowników. W takiej sytuacji Użytkownikowi nie przysługuje zwrot dokonanych za Usługi płatności.

 <strong>Odpowiedzi na pytania umieszczone w Formularzu zgłoszeniowym</strong> będą znane wyłącznie Administratorowi dla celu świadczenia Usług (lub upoważnionym przez Administratora osobom lub podmiotom w celu prawidłowego świadczenia Usług), a w Profilu Użytkownika widocznym dla innych użytkowników (Profil publiczny) widoczne będą jedynie odpowiedzi na pytania oznaczone w formularzu jako „Informacja widoczna w Profilu publicznym”. Użytkownik, który nie zgadza się na przekazanie tych informacji lub danych powinien zrezygnować z dalszego wypełniania i przesyłania Formularza zgłoszeniowego. Informacje z Profilu publicznego (informacje oznaczone jako widoczne w Profilu) będą udostępniane innym Użytkownikom w Serwisie w ramach Dopasowania na podstawie udzielonej przez Użytkownika zgody (art. 6 ust.1 lit. a lub art. 9 ust. 2 lit. a RODO) w celu świadczenia usług Biura Randkowego w sposób określony w Regulaminie. Zgoda może być cofnięta w każdym czasie, bez wpływu na zgodność z prawem przetwarzania dokonanym przed cofnięciem zgody. Cofnięcie zgody uniemożliwi wykonywanie Usługi w całości lub w części, w zależności od wpływu na możliwość dalszego wykonywania Usługi;

 <strong>w celu dochodzenia lub obrony przed ewentualnymi roszczeniami</strong> na podstawie prawnie uzasadnionego interesu Administratora w odniesieniu do danych zwykłych podanych przez Użytkownika (art. 6 ust. 1 lit f RODO).

Podanie powyższych danych jest dobrowolne jednak brak podania tych danych uniemożliwi świadczenie Usług w Serwisie. Administrator dochowuje wszelkich starań, aby dokonać Użytkownikom doboru opartego na kryteriach pozwalających na dopasowanie Użytkowników w kluczowych obszarach życiowych. 

Powyższe zasady dotyczą również danych podanych przez Użytkownika po wypełnieniu Formularza zgłoszeniowego, w tym poprzez zmianę odpowiedzi lub zmianę informacji zawartych w Profilu Singla.

 <strong>Dane zawarte w Formularzu zamówienia:</strong> Usługodawca przetwarza dane podane przez Użytkownika w celu dokonania zamówienia Usługi w wybranym Pakiecie w następujących celach:

 <strong>w celu rozliczenia zawartej z Użytkownikiem umowy świadczenia usług i wypełnienia obowiązków</strong> wynikających z powszechnie obowiązujących przepisów prawa na podstawie ciążącego na Administratorze obowiązku prawnego (art. 6 ust. 1 lit. c RODO),
 <strong>w celu dochodzenia lub obrony przed ewentualnymi roszczeniami</strong> na podstawie prawnie uzasadnionego interesu Administratora w odniesieniu do danych zwykłych podanych przez Użytkownika (art. 6 ust. 1 lit f RODO).

Podanie powyższych danych jest dobrowolne jednak brak podania tych danych uniemożliwi obsługę zamówienia, płatności i w rezultacie świadczenie Usług w Serwisie. 

 <strong>Pozostałe informacje dotyczące przetwarzania danych w związku ze świadczonymi Usługami:</strong>

W przypadku rozpoczęcia przez Użytkownika, w ramach wykonywanej przez Usługodawcę Usługi, komunikacji w Serwisie za pośrednictwem czatu  <strong>Usługodawca przechowuje informacje i dane zawarte w korespondencji prowadzonej za pośrednictwem Serwisu wyłącznie dokonując szyfrowania oraz pseudonimizacji</strong> treści komunikacji, co oznacza, że Usługodawca nie ma dostępu do treści tej komunikacji.

Usługodawca może zapoznać się z treścią komunikacji na wyraźne żądanie jednej ze Stron komunikacji i wówczas przetwarza te dane w celu obsługi żądania na podstawie niezbędności do wykonania umowy (art. 6 ust. 1 lit. b RODO) lub w celu dochodzenia lub obrony przed ewentualnymi roszczeniami na podstawie prawnie uzasadnionego interesu Administratora w odniesieniu do danych zwykłych podanych przez Użytkownika (art. 6 ust. 1 lit f RODO). Zawierając umowę świadczenia usług w ramach Pakietu Basic i akceptując Regulamin Użytkownik wyraża zgodę na otrzymywanie wiadomości od innych Użytkowników w ramach zaakceptowanego przez obu Użytkowników Dopasowania. Każdy Użytkownik jest uprawniony do wysłania wiadomości do innego Użytkownika w ramach zaakceptowanego przez obu Użytkowników Dopasowania. Wiadomości nie mogą naruszać zasad określonych w Regulaminie, powszechnie obowiązujących przepisów prawa, zasad współżycia społecznego i dobrych obyczajów. W każdej chwili Użytkownik jest uprawniony do usunięcia korespondencji prowadzonej z innym Użytkownikiem poprzez włączenie funkcji dostępnej z poziomu czatu „Zakończ rozmowę”.

Usunięcie historii korespondencji następuje z chwilą usunięcia Konta przez Użytkownika. Usługodawca nie świadczy usługi przechowywania korespondencji. Usługodawca może przechowywać w formie zaszyfrowanego zapisu historię korespondencji dla celu obrony przed lub dochodzenia ewentualnych roszczeń, przez okres ich przedawnienia plus 6 miesięcy.

 <strong>[Okres przechowywania danych]</strong> Administrator będzie przechowywać wskazane wyżej w pkt 9 dane osobowe przez okres świadczenia na rzecz Użytkownika Usług, w po tym czasie może je przechowywać przez okresy wymagana przepisami prawa oraz przez okres przedawnienia ewentualnych roszczeń plus 6 miesięcy.

 <strong>[Odbiorcy danych]</strong> Podane dane Administrator może przekazywać jedynie podmiotom świadczącym dla niego usługi związane z działaniem Serwisu w zakresie świadczenia Usług przez Biuro Randkowe.

 <strong>[Marketing usług własnych]</strong> Dane podane przez Użytkownika przy założeniu konta w
Serwisie w celu zapisu na listę oczekujących (pierwotnie lub później zaktualizowane) Administrator może także przetwarzać w celu prowadzenia marketingu usług własnych na podstawie prawnie uzasadnionego interesu Administratora (art. 6 ust. 1 lit. f RODO) lub – jeżeli dotyczy - udzielonej zgody w zakresie przesyłania informacji handlowych. Zgoda może być cofnięta w każdym czasie, co nie niweczy legalności przesyłania informacji handlowych sprzed cofnięcia zgody. W każdej chwili Użytkownik może zgłosić sprzeciw wobec przetwarzania opartego na prawnie uzasadnionym interesie Administratora lub cofnąć zgodę na otrzymywanie informacji handlowych przesyłając wiadomość mailową na adres: rodo@biurorandkowe.pl.    

 <strong>[Okres przechowywania danych]</strong> Administrator będzie przechowywać te dane osobowe do czasu złożenia skutecznego sprzeciwu albo odpowiednio cofnięcia zgody na wysyłanie informacji handlowych, a jeśli to nie nastąpi, Administrator będzie przechowywał te dane przez okres utrzymywania w Serwisie Konta Użytkownika. 

 <strong>[Odbiorcy danych]</strong> Podane dane Administrator może przekazywać jedynie podmiotom świadczącym dla niego usługi związane z prowadzeniem marketingu usług własnych.

 <strong> [Newsletter]</strong> Dane podane przez Użytkownika przy zapisie na newsletter Administrator przetwarza w celu wysyłania Użytkownikowi newslettera na podstawie udzielonej zgody (art. 6 ust. 1 lit. a RODO). Zgoda może być cofnięta w każdym czasie, co nie niweczy legalności przetwarzania sprzed chwili cofnięcia zgody. W każdej chwili Użytkownik może cofnąć zgodę przesyłając wiadomość mailową na adres: rodo@biurorandkowe.pl.    

 <strong>[Okres przechowywania danych]</strong> Administrator będzie przechowywać te dane osobowe do czasu cofnięcia zgody, a jeśli zgoda nie zostanie cofnięta, Administrator będzie przechowywał te dane przez okres przesyłania newslettera. 

 <strong>[Odbiorcy danych]</strong> Podane dane Administrator może przekazywać jedynie podmiotom świadczącym dla niego usługi związane z przygotowywaniem i wysyłaniem newslettera. 

  <strong>[Komunikacja z Użytkownikami]</strong>

 <strong>[Cel i podstawa przetwarzania]</strong> Dane przekazane w ramach komunikacji elektronicznej lub tradycyjnej Administrator przetwarza w celu prowadzenia komunikacji na podstawie prawnie uzasadnionego interesu Administratora w postaci komunikacji (art. 6 ust. 1 lit. f RODO). Podanie danych w tym celu jest dobrowolne, ale niezbędne do udzielenia odpowiedzi na przesłaną wiadomość lub prowadzenia komunikacji.

 <strong>[Okres przechowywania danych]</strong> Administrator przechowuje te dane przez okres trwania komunikacji, a po tym czasie może je również przechowywać przez okres przedawnienia ewentualnych roszczeń.

 <strong>[Odbiorcy danych]</strong> Dane te Administrator może przekazywać jedynie podmiotom świadczącym dla niego usługi niezbędne do prowadzenia komunikacji, w tym usługi informatyczne i marketingowe i tylko w tych celach.

<h3>Informacja o plikach cookies i tworzeniu profilu użytkownika</h3>

 <strong>[Cookies]</strong> Serwis korzysta z plików cookies. Pliki cookies nie służą do identyfikacji użytkowników. Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.

 <strong>[Cele wykorzystywania cookies]</strong> Pliki cookies wykorzystywane są w następujących celach:
 tworzenia statystyk i prowadzenia działań analitycznych dla określenia w jaki sposób Użytkownicy korzystają z Serwisu i poprawy funkcjonalności Serwisu, 
utrzymanie sesji Użytkownika Serwisu, 
określania profilu Użytkownika w celu wyświetlania mu dopasowanych materiałów w sieciach reklamowych, w szczególności sieci Google.

  <strong>Administrator korzysta w Serwisie z następujących plików cookies:</strong>



 <strong>[Wyłączenie i zarządzanie plikami cookies]</strong> W Serwisie w wydzielonej części dotyczącej zarządzania plikami cookies Użytkownik może dokonać personalizacji ustawień dotyczących korzystania z plików cookies w Serwisie. Użytkownik może także z poziomu przeglądarki internetowej zmienić ustawienia cookies lub zablokować korzystanie z cookies. Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.

 <strong>[Statystyki]</strong> Gromadzimy anonimowe dane dotyczące odwiedzin na naszych stronach i analizujemy je korzystając z Google Analytics. Narzędzie to nie udostępnia nam danych umożliwiających identyfikację konkretnej osoby. 

 <strong>[Zarządzanie reklamami]</strong> W zakresie informacji o preferencjach użytkownika gromadzonych przez sieć reklamową Google użytkownik może przeglądać i edytować informacje wynikające z plików cookies przy pomocy narzędzia: https://www.google.com/ads/preferences/

 <strong>[Blokowanie]</strong> W celu zablokowania wykorzystywania swoich danych w Google Analytics możesz zainstalować dodatek blokujący Google Analytics w swojej przeglądarce, dostępny tu: https://tools.google.com/dlpage/gaoptout/?hl=pl

 <strong>[Profilowanie]</strong> W Serwisie dokonywane jest profilowanie w zakresie wyświetlania użytkownikowi dopasowanych do niego treści, które jednak nie prowadzi do podejmowania decyzji ze skutkiem prawnym dla Użytkownika. Profilowanie nie dotyczy doboru użytkowników w ramach dopasowania dokonywanego w związku ze świadczeniem przez Administratora Usług Biura Randkowego z uwagi na to, że analiza Profili Singla i proponowanie Dopasowań nie są dokonywane w sposób zautomatyzowany.

<h3>Twoje Prawa</h3>

 <strong>[Zabezpieczenia]</strong> Wszelkie dane osobowe są przetwarzane zgodnie z powszechnie obowiązującymi przepisami prawa. Administrator stosuje odpowiednie środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych adekwatne do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności zabezpiecza dane osobowe przed udostępnieniem ich osobom nieupoważnionym, utratą, czy uszkodzeniem.

 <strong>[Transfer danych poza EOG]</strong> W zakresie przetwarzania danych z wykorzystaniem usług Google dane te mogą w tym zakresie być przekazywane poza Europejski Obszar Gospodarczy, w odniesieniu do przekazania danych do Wielkiej Brytanii, Kanady, Izraela, Japonii i USA uznaje się, na podstawie decyzji Komisji Europejskiej, że stopień ochrony danych osobowych w tych państwach jest odpowiedni, a w odniesieniu do pozostałych państw, w których w ramach usług Google może dochodzić do przetwarzania danych, stosowane są klauzule umowne zapewniające odpowiedni poziom ochrony. 

 <strong>[Prawa jednostki]</strong> W stosunku do wszystkich podanych danych osobowych, na zasadach określonych w RODO, masz prawo do:
<ul>
<li>uzyskania informacji dotyczącej przetwarzania Twoich danych osobowych oraz przysługujących ci praw, udzielonej w zwięzłej, przejrzystej, zrozumiałej i łatwo dostępnej formie, jasnym i prostym językiem,</li>
<li>niepodlegania decyzji, która opiera się wyłącznie na zautomatyzowanym przetwarzaniu, w tym profilowaniu, i wywołuje wobec Ciebie skutki prawne lub w podobny sposób istotnie na Ciebie wpływa, chyba, że została wyrażona wyraźna zgoda lub pozwalają na to przepisy praw lub jest to niezbędne do zawarcia lub wykonania umowy,</li>
<li>cofnięcia zgody, jeżeli przetwarzanie odbywało się na tej podstawie,</li>
<li>wniesienia sprzeciwu wobec przetwarzania danych, z przyczyn związanych z Twoją szczególną sytuacją, jeżeli przetwarzanie odbywa się na podstawie art. 6 ust. 1 lit. e lub f RODO,</li>
<li>dostępu do swoich danych osobowych, w tym do uzyskania informacji o przetwarzaniu Twoich danych, w szczególności o celach, kategoriach przetwarzania, odbiorcach i okresie przechowywania danych,</li>
<li>sprostowania danych osobowych, które są nieprawidłowe, jak również z uwzględnieniem celów przetwarzania, masz prawo żądania uzupełnienia niekompletnych danych osobowych, w tym poprzez przedstawienie dodatkowego oświadczenia,</li>
<li>usunięcia danych osobowych (chyba że zachodzą przesłanki z art. 17 RODO umożliwiające dalsze przetwarzanie danych, np. do ustalenia, dochodzenia lub obrony roszczeń),</li>
<li>ograniczenia przetwarzania danych osobowych w przypadkach określonych w art. 18 RODO,</li>
<li>przenoszenia danych tj. do otrzymania w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego Twoich danych osobowych jej dotyczące, które zostały nam przez Ciebie dostarczone, jeżeli przetwarzanie odbywa się na podstawie zgody lub umowy oraz gdy przetwarzanie odbywa się w sposób zautomatyzowany,</li>
<li>poznania źródła danych, gdy dane nie zostały zebrane od Ciebie,</li>
<li>wniesienia skargi Prezesa Urzędu Ochrony Danych, jeżeli sądzisz, że przetwarzanie Twoich danych osobowych narusza przepisy o ochronie danych osobowych, w szczególności ogólne rozporządzenie o ochronie danych.</li>
</ul>
 <strong>[Zmiana Polityki prywatności]</strong> Administrator może dokonywać zmian lub aktualizacji w Polityce prywatności, jeśli będzie to wynikało z przepisów prawa, wytycznych dotyczących ochrony danych osobowych lub zmian funkcjonalności i usług świadczonych w Serwisie. 
 <strong>[Kontakt]</strong> W zakresie ochrony danych osobowych i realizacji swoich praw możesz skontaktować się z Administratorem pod adresem e-mail: rodo@biurorandkowe.pl 


            </div>
        </div>
        <Footer/>
    </section>
</template>
<script>
import OnlyText from '@/components/landing-03/atoms/OnlyText.vue';
import HeaderComponent from '@/components/landing-03/HeaderComponent.vue';
import Footer from "@/components/landing-03/footer.vue";


export default {
    data() {
        return {
        }
    },
    components: {
        OnlyText,
        HeaderComponent,
        Footer
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/Nunito.css";
@import "@/assets/scss/global.scss";
    .privacy {
        margin-top: 220px;
        @include breakpoint-max("mobile") {
            margin-top: 100px;
        }
        &__wrapper {
            margin-bottom: 200px;
        }
        table {
            margin: 20px 0 40px;
            margin-left: 30px;
            @include breakpoint-max('mobile') {
            margin-left: 0px;
            }
        }
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
        }
        th, td {
            padding: 15px;
            @include breakpoint-max('xs-tablet') {
                padding: 10px 5px;
            }
            @include breakpoint-max('mobile') {
                padding: 10px 5px;
                font-size: 10px;
            }
            @include breakpoint-max('s-mobile') {
                font-size: 7px;
            }
        }
        p, li {
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin-bottom: 30px;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
            strong {
                font-size: 16px;
            }
            @include breakpoint-max('mobile') {
                font-size: 14px;
                line-height: 20px;

            }
        }
        p {
            margin-left: 30px;
            margin-bottom: 15px;
        }
        h1, h2, h3, h4, h5, h6 {
            font-family: 'Oswald', sans-serif;
            font-weight: 800;
            margin-bottom: 30px;
            margin-top: 50px;
        }
        h1 {
            font-size: 6rem;
            line-height: 6.7rem;
        }
        h2 {
            font-size: 4rem;
            line-height: 4.7rem;
        }
        h3 {
            font-size: 2.4rem;
            line-height: 3rem;
        }
        h4 {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
        ul, ol {
            li {
                margin-bottom: 15px;
                margin-left: 30px;
            }
        }
        ul {
            // list-style: none;
        }
    }
</style>