<template>
    <div class="selected-cities">
        <header><strong>Wybrane miasta:</strong></header>
        <!-- <div class="selected-cities__list">
            <div v-for="city in cities">{{ city }}</div>
        </div> -->
        <div class="city-date">
            <Multiselect
                class="city-date__multiselect"
                :native="false"
                v-model="cities"
                :options="options"
                mode="tags"
                :close-on-select="false"
                :searchable="true"
                :create-option="true"
            />
        </div>
        <p class="selected-cities__response" v-if="res">{{ res }}</p>
        <button
            :disabled="!cities.length"
            class="my-profile__button"
            @click="changeCityPossible"
        >
            Zapisz miasta
        </button>
    </div>
</template>

<script setup>
    import Multiselect from '@vueform/multiselect'
    import axios from 'axios'
    import { computed, ref, reactive } from 'vue'
    import { useUserStore } from '@/stores/user.js'

    const API_URL = process.env.VUE_APP_API
    const userStore = useUserStore()
    const res = ref('')

    const options = ref([
        'Warszawa',
        'Poznań',
        'Wrocław',
        'Kraków',
        'Łódź',
        'Rzeszów',
        'Gdańsk/Gdynia/Sopot',
        'Katowice',
        'Szczecin',
        'Bydgoszcz',
        'Lublin'
    ])

    const getCookie = name => {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
    }

    const changeCityPossible = async () => {
        const authToken = getCookie('authToken')
        const config = {
            headers: { Authorization: `Bearer ${authToken}` }
        }

        if (!cities.value.length) {
            res.value = 'Wybierz przynajmniej jedno miasto.'
            return
        }

        try {
            const response = await axios.post(
                `${API_URL}api/questionnaire/changecitypossible`,
                {
                    userId: localStorage.getItem('userId'),
                    cities: cities.value
                },
                config
            )

            res.value = response.data.message
            userStore.updateQuestionnaire({ cityPossible: cities.value })
        } catch (error) {
            res.value = error.response.data.message
        }
    }

    const cities = computed({
        get() {
            return userStore.questionnaire?.cityPossible || []
        },
        set(value) {
            userStore.questionnaire.cityPossible = value
        }
    })
</script>

<style lang="scss">
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    button:disabled {
        filter: grayscale(1);
        cursor: not-allowed;
    }

    .selected-cities {
        width: 100%;

        header {
            margin-bottom: 10px;
            font-size: 16px;
        }

        &__response {
            margin-top: 20px;
            margin-bottom: 10px;
        }

        &__list {
            border: 1px dashed;
            padding-bottom: 20px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            div {
                display: inline-flex;
                background-color: $pink;
                padding: 5px 10px;
                border-radius: 5px;
                font-size: 12px;
                line-height: 14px;
                text-transform: uppercase;
                color: #fff;
                font-weight: 600;
            }
        }
    }
    .city-date {
        min-width: 100%;
        &__multiselect {
            width: 100%;
        }
        column-gap: 27px;
        .save-profile__title {
            padding: 27px 0;
            @include breakpoint-max('xs-tablet') {
                text-wrap: wrap;
            }
            @include breakpoint-max('mobile') {
                padding-bottom: 10px;
                padding-top: 0;
            }
        }
        .multiselect {
            cursor: pointer;
            background-color: transparent !important;
            border: none;
            outline: none;
            .multiselect-wrapper {
                @include breakpoint-max('mobile') {
                    width: 93%;
                }
            }
            .multiselect-tag {
                padding: 5px 10px;
            }
            .multiselect-tag-wrapper {
                font-size: 12px;
                line-height: 14px;
                text-transform: uppercase;
            }
            .multiselect-tag-remove-icon {
                width: 10px;
                height: 10px;
            }
            @include breakpoint-max('mobile') {
                margin-bottom: 15px;
            }
        }
        .multiselect-options {
            li {
                span {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
        .multiselect-clear-icon {
            background-color: $black;
            width: 15px;
            height: 15px;
            display: none;
        }
        .multiselect-caret {
            background-color: $black;
            width: 20px;
            height: 20px;
        }
        input {
            padding: 10px 0 !important;
            height: 26px !important;
            width: 100% !important;
            font-size: 14px !important;
            line-height: 30px !important;
        }

        .multiselect-tag {
            background-color: $pink;
        }
        .multiselect-tags-search {
            padding: 0 !important;
            background-color: transparent !important;
        }
    }
</style>
