<template>
    <div class="save-profile">
        <HeaderComponent />
        <div class="container" v-if="verified">
            <form @submit.prevent="saveProfile">
                <h1>Zapisz się na listę oczekujących</h1>
                <h2
                    class="save-profile__title-heading save-profile__title-heading--smaller"
                >
                    REJESTRACJA NA LIŚCIE OCZEKUJĄCYCH JEST BEZPŁATNA.
                </h2>

                <!-- <div class="save-profile__section">
                    <label for="name">Imię</label>
                    <input type="text" id="name" v-model="name">
                </div>

                <div class="save-profile__section">
                    <label for="surname">Nazwisko</label>
                    <input type="text" id="surname" v-model="surname">
                </div> -->

                <div class="save-profile__section">
                    <label for="name" class="save-profile__title">Imię:</label>
                    <input type="text" id="name" v-model="name" />
                </div>

                <div class="save-profile__section">
                    <label for="surname" class="save-profile__title">
                        Nazwisko:
                    </label>
                    <input type="text" id="surname" v-model="surname" />
                </div>

                <div class="save-profile__section">
                    <label for="phone" class="save-profile__title">
                        Telefon:
                    </label>
                    <input
                        type="text"
                        id="phone"
                        v-model="phone"
                        minlength="9"
                        maxlength="9"
                    />
                </div>

                <div class="save-profile__section">
                    <label for="date" class="save-profile__title">
                        Data urodzenia:
                    </label>
                    <input id="date" type="date" v-model="date" />
                </div>

                <div class="save-profile__section">
                    <div class="save-profile__flex">
                        <div class="save-profile__three">
                            <p
                                class="save-profile__title save-profile__title--right"
                            >
                                Jestem
                            </p>
                            <div class="save-profile__flex">
                                <div class="save-profile__half">
                                    <input
                                        class="profile__radio"
                                        type="radio"
                                        id="one"
                                        value="0"
                                        v-model="gender"
                                    />
                                    <label for="one">
                                        <svg
                                            id="Group_23"
                                            data-name="Group 23"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="40"
                                            height="61.5"
                                            viewBox="0 0 40 61.5"
                                        >
                                            <g
                                                id="Ellipse_5"
                                                data-name="Ellipse 5"
                                                fill="none"
                                                stroke="#d13724"
                                                stroke-width="4"
                                            >
                                                <circle
                                                    cx="20"
                                                    cy="20"
                                                    r="20"
                                                    stroke="none"
                                                />
                                                <circle
                                                    cx="20"
                                                    cy="20"
                                                    r="18"
                                                    fill="none"
                                                />
                                            </g>
                                            <line
                                                id="Line_23"
                                                data-name="Line 23"
                                                y1="21"
                                                transform="translate(20.5 38.5)"
                                                fill="none"
                                                stroke="#d13724"
                                                stroke-linecap="round"
                                                stroke-width="4"
                                            />
                                            <line
                                                id="Line_24"
                                                data-name="Line 24"
                                                x2="15"
                                                transform="translate(12.5 49.5)"
                                                fill="none"
                                                stroke="#d13724"
                                                stroke-linecap="round"
                                                stroke-width="4"
                                            />
                                        </svg>
                                        Kobietą
                                    </label>
                                </div>
                                <div class="save-profile__half">
                                    <input
                                        class="profile__radio"
                                        type="radio"
                                        id="two"
                                        value="1"
                                        v-model="gender"
                                    />
                                    <label for="two">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="52.081"
                                            height="50.761"
                                            viewBox="0 0 52.081 50.761"
                                        >
                                            <g
                                                id="Group_25"
                                                data-name="Group 25"
                                                transform="translate(0 2)"
                                            >
                                                <g
                                                    id="Ellipse_5"
                                                    data-name="Ellipse 5"
                                                    transform="translate(0 8.761)"
                                                    fill="none"
                                                    stroke="#d13724"
                                                    stroke-width="4"
                                                >
                                                    <circle
                                                        cx="20"
                                                        cy="20"
                                                        r="20"
                                                        stroke="none"
                                                    />
                                                    <circle
                                                        cx="20"
                                                        cy="20"
                                                        r="18"
                                                        fill="none"
                                                    />
                                                </g>
                                                <line
                                                    id="Line_23"
                                                    data-name="Line 23"
                                                    x1="15"
                                                    y2="14.999"
                                                    transform="translate(33.5 1.137)"
                                                    fill="none"
                                                    stroke="#d13724"
                                                    stroke-linecap="round"
                                                    stroke-width="4"
                                                />
                                                <path
                                                    id="Path_50"
                                                    data-name="Path 50"
                                                    d="M822.272,6398.363h12.809v11.483"
                                                    transform="translate(-785 -6398.363)"
                                                    fill="none"
                                                    stroke="#d13724"
                                                    stroke-linecap="round"
                                                    stroke-width="4"
                                                />
                                            </g>
                                        </svg>
                                        Mężczyzną
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="save-profile__three">
                            <p
                                class="save-profile__title save-profile__title--right"
                            >
                                Szukam
                            </p>
                            <div class="save-profile__flex">
                                <div class="save-profile__half">
                                    <input
                                        class="profile__radio"
                                        type="radio"
                                        id="three"
                                        value="0"
                                        v-model="search"
                                    />
                                    <label for="three">
                                        <svg
                                            id="Group_23"
                                            data-name="Group 23"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="40"
                                            height="61.5"
                                            viewBox="0 0 40 61.5"
                                        >
                                            <g
                                                id="Ellipse_5"
                                                data-name="Ellipse 5"
                                                fill="none"
                                                stroke="#d13724"
                                                stroke-width="4"
                                            >
                                                <circle
                                                    cx="20"
                                                    cy="20"
                                                    r="20"
                                                    stroke="none"
                                                />
                                                <circle
                                                    cx="20"
                                                    cy="20"
                                                    r="18"
                                                    fill="none"
                                                />
                                            </g>
                                            <line
                                                id="Line_23"
                                                data-name="Line 23"
                                                y1="21"
                                                transform="translate(20.5 38.5)"
                                                fill="none"
                                                stroke="#d13724"
                                                stroke-linecap="round"
                                                stroke-width="4"
                                            />
                                            <line
                                                id="Line_24"
                                                data-name="Line 24"
                                                x2="15"
                                                transform="translate(12.5 49.5)"
                                                fill="none"
                                                stroke="#d13724"
                                                stroke-linecap="round"
                                                stroke-width="4"
                                            />
                                        </svg>
                                        Kobiety
                                    </label>
                                </div>
                                <div class="save-profile__half">
                                    <input
                                        class="profile__radio"
                                        type="radio"
                                        id="four"
                                        value="1"
                                        v-model="search"
                                    />
                                    <label for="four">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="52.081"
                                            height="50.761"
                                            viewBox="0 0 52.081 50.761"
                                        >
                                            <g
                                                id="Group_25"
                                                data-name="Group 25"
                                                transform="translate(0 2)"
                                            >
                                                <g
                                                    id="Ellipse_5"
                                                    data-name="Ellipse 5"
                                                    transform="translate(0 8.761)"
                                                    fill="none"
                                                    stroke="#d13724"
                                                    stroke-width="4"
                                                >
                                                    <circle
                                                        cx="20"
                                                        cy="20"
                                                        r="20"
                                                        stroke="none"
                                                    />
                                                    <circle
                                                        cx="20"
                                                        cy="20"
                                                        r="18"
                                                        fill="none"
                                                    />
                                                </g>
                                                <line
                                                    id="Line_23"
                                                    data-name="Line 23"
                                                    x1="15"
                                                    y2="14.999"
                                                    transform="translate(33.5 1.137)"
                                                    fill="none"
                                                    stroke="#d13724"
                                                    stroke-linecap="round"
                                                    stroke-width="4"
                                                />
                                                <path
                                                    id="Path_50"
                                                    data-name="Path 50"
                                                    d="M822.272,6398.363h12.809v11.483"
                                                    transform="translate(-785 -6398.363)"
                                                    fill="none"
                                                    stroke="#d13724"
                                                    stroke-linecap="round"
                                                    stroke-width="4"
                                                />
                                            </g>
                                        </svg>
                                        Mężczyzny
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="save-profile__section">
                    <label for="city" class="save-profile__title">
                        Miejsce zamieszkania:
                    </label>
                    <input type="text" id="city" v-model="city" />
                </div>

                <div class="save-profile__section city-date">
                    <label for="citydate" class="save-profile__title">
                        W jakim mieście chcesz się umawiać na randki?
                    </label>

                    <Multiselect
                        v-model="cityDate"
                        :options="options"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="false"
                        :create-option="true"
                    />
                </div>

                <div class="save-profile__section-all">
                    <div class="save-profile__photo-section">
                        <div class="save-profile__photos">
                            <p class="save-profile__heading">Dołącz zdjęcie</p>
                            <p class="save-profile__subtitle">
                                Zdjęcie portretowe i całej sylwetki (bez czapek,
                                okularów przeciwsłonecznych i innych osób).
                            </p>
                        </div>
                        <div class="save-profile__all-photos">
                            <div class="save-profile__photos-flex">
                                <div>
                                    <img
                                        :src="imageSrc1"
                                        @click="triggerFileInput1"
                                        class="profile__img"
                                    />
                                    <svg
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 50 50"
                                        xml:space="preserve"
                                    >
                                        <circle
                                            style="fill: #ffffff"
                                            cx="25"
                                            cy="25"
                                            r="25"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="25"
                                            y1="13"
                                            x2="25"
                                            y2="38"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="37.5"
                                            y1="25"
                                            x2="12.5"
                                            y2="25"
                                        />
                                    </svg>
                                </div>
                                <div class="images-box">
                                    <img
                                        :src="imageSrc2"
                                        @click="triggerFileInput2"
                                        class="profile__img"
                                    />
                                    <p
                                        class="image-delete"
                                        @click="singleImageDelete(2)"
                                    >
                                        Usuń
                                    </p>
                                    <svg
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 50 50"
                                        xml:space="preserve"
                                    >
                                        <circle
                                            style="fill: #ffffff"
                                            cx="25"
                                            cy="25"
                                            r="25"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="25"
                                            y1="13"
                                            x2="25"
                                            y2="38"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="37.5"
                                            y1="25"
                                            x2="12.5"
                                            y2="25"
                                        />
                                    </svg>
                                </div>
                                <div class="images-box">
                                    <img
                                        :src="imageSrc3"
                                        @click="triggerFileInput3"
                                        class="profile__img"
                                    />
                                    <p
                                        class="image-delete"
                                        @click="singleImageDelete(3)"
                                    >
                                        Usuń
                                    </p>
                                    <svg
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 50 50"
                                        xml:space="preserve"
                                    >
                                        <circle
                                            style="fill: #ffffff"
                                            cx="25"
                                            cy="25"
                                            r="25"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="25"
                                            y1="13"
                                            x2="25"
                                            y2="38"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="37.5"
                                            y1="25"
                                            x2="12.5"
                                            y2="25"
                                        />
                                    </svg>
                                </div>
                                <div class="images-box">
                                    <img
                                        :src="imageSrc4"
                                        @click="triggerFileInput4"
                                        class="profile__img"
                                    />
                                    <p
                                        class="image-delete"
                                        @click="singleImageDelete(4)"
                                    >
                                        Usuń
                                    </p>
                                    <svg
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 50 50"
                                        xml:space="preserve"
                                    >
                                        <circle
                                            style="fill: #ffffff"
                                            cx="25"
                                            cy="25"
                                            r="25"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="25"
                                            y1="13"
                                            x2="25"
                                            y2="38"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="37.5"
                                            y1="25"
                                            x2="12.5"
                                            y2="25"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <input
                                class="profile__file"
                                type="file"
                                ref="fileInput1"
                                @change="onFileChange1"
                            />
                            <input
                                class="profile__file"
                                type="file"
                                ref="fileInput2"
                                @change="onFileChange2"
                            />
                            <input
                                class="profile__file"
                                type="file"
                                ref="fileInput3"
                                @change="onFileChange3"
                            />
                            <input
                                class="profile__file"
                                type="file"
                                ref="fileInput4"
                                @change="onFileChange4"
                            />
                        </div>
                        <div class="save-profile__checkbox checkBoxes">
                            <label class="profile_label-text" for="chConfirm">
                                <span>
                                    Administratorem danych osobowych jest B87
                                    sp. z o.o. Pełną informację o przetwarzaniu
                                    danych osobowych znajdziesz w
                                    <router-link
                                        target="_blank"
                                        to="/polityka_prywatnosci.pdf"
                                    >
                                        Polityce prywatności.
                                    </router-link>
                                </span>
                                .
                            </label>
                        </div>
                        <div class="save-profile__checkbox checkBoxes">
                            <label class="checkBox" for="chConfirm">
                                <input
                                    id="chConfirm"
                                    type="checkbox"
                                    v-model="checkboxConfirm"
                                    value="text"
                                    name="subscribe"
                                />
                                <div class="transition"></div>
                            </label>
                            <label class="profile_label-text" for="chConfirm">
                                Wyrażam zgodę na wysyłanie powiadomień o
                                usługach Biura Randkowego na podany numer
                                telefonu.
                            </label>
                        </div>
                        <div class="save-profile__checkbox checkBoxes">
                            <label class="checkBox" for="chConfirm2">
                                <input
                                    id="chConfirm2"
                                    type="checkbox"
                                    v-model="checkboxConfirm2"
                                    value="text"
                                    name="subscribe2"
                                />
                                <div class="transition"></div>
                            </label>
                            <label class="profile_label-text" for="chConfirm2">
                                Wyrażam zgodę na przetwarzanie przez B87 sp. z
                                o.o. podanych danych osobowych w celu zapisu na
                                listę oczekujących na usługi Biura Randkowego.
                            </label>
                        </div>
                    </div>
                </div>
                <div class="save-profile__errors">
                    <p class="save-profile__errors-title">
                        {{ emptyFields.title }}
                    </p>
                    <p class="save-profile__errors-subtitle">
                        {{ emptyFields.subtitle }}
                    </p>
                    <ul ref="errorList">
                        <li>{{ emptyFields.name }}</li>
                        <li>{{ emptyFields.surname }}</li>
                        <li>{{ emptyFields.phone }}</li>
                        <li>{{ emptyFields.date }}</li>
                        <li>{{ emptyFields.gender }}</li>
                        <li>{{ emptyFields.search }}</li>
                        <li>{{ emptyFields.city }}</li>
                        <li>{{ emptyFields.cityDate }}</li>
                        <li>{{ emptyFields.image1 }}</li>
                        <li>{{ emptyFields.image2 }}</li>
                        <li>{{ emptyFields.checkbox }}</li>
                        <li>{{ emptyFields.checkbox2 }}</li>
                        <li>{{ photosFormatError }}</li>
                    </ul>
                </div>
                <button v-if="!loaderAnimation" class="save-profile__btn">
                    Zapisz się
                </button>
                <button disabled v-else class="save-profile__loader loader">
                    <svg viewBox="25 25 50 50">
                        <circle r="20" cy="50" cx="50"></circle>
                    </svg>
                </button>
            </form>
        </div>
        <div class="container noemail" v-else>
            <h1>
                Potwierdź swój
                <br />
                e-mail.
            </h1>
            <p v-if="notsent">
                Nie otrzymałeś e-maila? Sprawdź folder SPAM lub
                <a @click.prevent="sendLink">wyślij ponownie</a>
                .
            </p>
            <p v-else>{{ response }}</p>
        </div>
        <Footer />
    </div>
</template>

<script>
    import axios from 'axios'
    import Multiselect from '@vueform/multiselect'
    import HeaderComponent from '@/components/landing-03/HeaderComponent.vue'
    import Footer from '@/components/landing-03/footer.vue'

    export default {
        data() {
            return {
                selectedFile1: '',
                selectedFile2: '',
                selectedFile3: '',
                selectedFile4: '',
                imageSrc1: require('@/assets/images/ludzik1.png'),
                defaultImageSrc1: require('@/assets/images/ludzik1.png'),
                imageSrc1Filled: null,
                imageSrc2: require('@/assets/images/ludzik2.png'),
                imageSrc2Filled: null,
                imageSrc3: require('@/assets/images/blank.png'),
                imageSrc4: require('@/assets/images/blank.png'),
                search: null,
                gender: null,
                name: null,
                surname: null,
                phone: null,
                date: null,
                city: null,
                cityDate: null,
                email: null,
                value: null,
                loaderAnimation: false,
                checkboxConfirm: false,
                checkboxConfirm2: false,
                verified: false,
                notsent: true,
                emptyFields: {
                    title: '',
                    subtitle: '',
                    name: '',
                    surname: '',
                    phone: '',
                    date: '',
                    gender: '',
                    search: '',
                    city: '',
                    cityDate: '',
                    email: '',
                    image1: '',
                    image2: '',
                    checkbox: '',
                    checkbox2: ''
                },
                photosFormatError: '',
                options: [
                    'Warszawa',
                    'Poznań',
                    'Wrocław',
                    'Kraków',
                    'Łódź',
                    'Rzeszów',
                    'Gdańsk/Gdynia/Sopot',
                    'Katowice',
                    'Szczecin',
                    'Bydgoszcz',
                    'Lublin'
                ],
                response: ''
            }
        },
        components: {
            Multiselect,
            Footer,
            HeaderComponent
        },
        methods: {
            singleImageDelete(photoNum) {
                switch (photoNum) {
                    case 2:
                        this.imageSrc2 = require('@/assets/images/ludzik2.png')
                        this.selectedFile2 = ''
                        break
                    case 3:
                        this.imageSrc3 = require('@/assets/images/blank.png')
                        this.selectedFile3 = ''
                        break
                    case 4:
                        this.imageSrc4 = require('@/assets/images/blank.png')
                        this.selectedFile4 = ''
                        break
                }
            },
            triggerFileInput1() {
                this.$refs.fileInput1.click()
            },
            triggerFileInput2() {
                this.$refs.fileInput2.click()
            },
            triggerFileInput3() {
                this.$refs.fileInput3.click()
            },
            triggerFileInput4() {
                this.$refs.fileInput4.click()
            },
            checkImagesFormat(imageType) {
                this.photosFormatError = ''
                if (imageType == 'image/png' || imageType == 'image/jpeg') {
                    return true
                } else {
                    this.photosFormatError =
                        'Poprawny format zdjęcia to .jpg lub .png'
                    return false
                }
            },
            onFileChange1(event) {
                if (!this.checkImagesFormat(event.target.files[0].type)) {
                    return
                }
                // console.log('File 1 changed:', event.target.files[0]);
                this.selectedFile1 = event.target.files[0]
                if (this.selectedFile1) {
                    this.imageSrc1 = URL.createObjectURL(this.selectedFile1)
                }
            },
            onFileChange2(event) {
                // console.log('File 2 changed:', event.target.files[0]);
                this.selectedFile2 = event.target.files[0]
                if (this.selectedFile2) {
                    this.imageSrc2 = URL.createObjectURL(this.selectedFile2)
                }
            },
            onFileChange3(event) {
                // console.log('File 3 changed:', event.target.files[0]);
                this.selectedFile3 = event.target.files[0]
                if (this.selectedFile3) {
                    this.imageSrc3 = URL.createObjectURL(this.selectedFile3)
                }
            },
            onFileChange4(event) {
                // console.log('File 4 changed:', event.target.files[0]);
                this.selectedFile4 = event.target.files[0]
                if (this.selectedFile4) {
                    this.imageSrc4 = URL.createObjectURL(this.selectedFile4)
                }
            },
            filesChange() {
                if (!fileList.length) return

                for (var i = 0; i < fileList.length; i++) {
                    let file = fileList[i]
                    formData.append('files[' + i + ']', file)
                }
            },
            saveProfile() {
                // Bearer token included ✅
                const authToken = this.getCookie('authToken')

                this.loaderAnimation = true
                if (!this.formValidation()) {
                    this.loaderAnimation = false
                    return
                }

                const formData = new FormData()

                formData.append('age', this.date)
                formData.append('gender', this.gender)
                formData.append('search', this.search)
                formData.append('city', this.city)
                formData.append('cityDate', this.cityDate)
                formData.append('name', this.name)
                formData.append('surname', this.surname)
                formData.append('phoneaccept', this.checkboxConfirm)
                formData.append('phone', this.phone)
                formData.append('consultant', this.cityDate[0])

                const userId = localStorage.getItem('userId')

                if (userId) {
                    formData.append('userid', userId)
                }

                if (this.selectedFile1) {
                    formData.append('file1', this.selectedFile1)
                }
                if (this.selectedFile2) {
                    formData.append('file2', this.selectedFile2)
                }
                if (this.selectedFile3) {
                    formData.append('file3', this.selectedFile3)
                }
                if (this.selectedFile4) {
                    formData.append('file4', this.selectedFile4)
                }

                axios
                    .post(
                        `${this.url}api/edit-user/`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                Authorization: `Bearer ${authToken}`
                            }
                        },
                        config
                    )
                    .then(response => {
                        this.loaderAnimation = false
                        this.$router.push('/dziekujemy')
                        // console.log(response)
                    })
                    .catch(error => {
                        this.loaderAnimation = false
                        console.log(error)
                    })
            },
            formValidation() {
                ;(this.emptyFields.checkbox2 = ''),
                    (this.emptyFields.checkbox = ''),
                    (this.emptyFields.title = ''),
                    (this.emptyFields.subtitle = ''),
                    (this.emptyFields.name = ''),
                    (this.emptyFields.surname = ''),
                    (this.emptyFields.phone = ''),
                    (this.emptyFields.date = ''),
                    (this.emptyFields.gender = ''),
                    (this.emptyFields.search = ''),
                    (this.emptyFields.city = ''),
                    (this.emptyFields.cityDate = ''),
                    (this.emptyFields.email = ''),
                    (this.emptyFields.image1 = ''),
                    (this.emptyFields.image2 = '')

                if (this.checkboxConfirm2 == false) {
                    this.emptyFields.checkbox2 =
                        'Zaznacz zgodę na przetwarzanie danych osobowych.'
                    return
                } else {
                    this.emptyFields.checkbox2 = ''
                }

                this.name
                    ? this.name.length < 2 &&
                      (this.emptyFields.name = 'imię minimum dwa znaki,')
                    : (this.emptyFields.name = 'imię,')
                this.surname
                    ? this.surname.length < 2 &&
                      (this.emptyFields.surname = 'nazwisko minimum dwa znaki,')
                    : (this.emptyFields.surname = 'nazwisko,')
                this.phone
                    ? this.phone.length < 6 &&
                      (this.emptyFields.phone = 'telefon minimum sześć znaków,')
                    : (this.emptyFields.phone = 'telefon,')
                !this.date && (this.emptyFields.date = 'datę urodzenia,')
                !this.gender && (this.emptyFields.gender = 'swoją płeć,')
                !this.search &&
                    (this.emptyFields.search = 'płeć osoby której szukasz,')
                this.city
                    ? this.city.length < 2 &&
                      (this.emptyFields.city =
                          'miejsce zamieszkania minimum dwa znaki,')
                    : (this.emptyFields.city = 'miejsce zamieszkania,')
                !this.cityDate &&
                    (this.emptyFields.cityDate =
                        'miasto w którym chcesz umawiać się na randki,')

                if (this.imageSrc1 !== this.defaultImageSrc1) {
                    this.emptyFields.image1 = '' // lub jakakolwiek inna akcja, jeśli obrazek został zmieniony
                } else {
                    this.emptyFields.image1 = 'pierwsze zdjęcie,' // jeśli obrazek nie został zmieniony
                }

                if (this.imageSrc2 !== this.defaultImageSrc2) {
                    this.emptyFields.image2 = '' // lub jakakolwiek inna akcja, jeśli obrazek został zmieniony
                } else {
                    this.emptyFields.image2 = 'drugie zdjęcie,' // jeśli obrazek nie został zmieniony
                }

                if (
                    !this.checkboxConfirm2 ||
                    !this.name ||
                    !this.surname ||
                    !this.phone ||
                    !this.date ||
                    !this.gender ||
                    !this.search ||
                    !this.city ||
                    !this.cityDate ||
                    !this.imageSrc1 ||
                    !this.imageSrc2
                ) {
                    this.emptyFields.title = 'Wykryliśmy błędy w formularzu.'
                    this.emptyFields.subtitle =
                        'Uzupełnij prawidłowo następujące pola: '
                    return false
                } else if (
                    this.name.length < 2 ||
                    this.surname.length < 2 ||
                    this.phone.length < 6 ||
                    this.city.length < 2
                ) {
                    this.emptyFields.title = 'Wykryliśmy błędy w formularzu.'
                    this.emptyFields.subtitle =
                        'Uzupełnij prawidłowo następujące pola: '
                    return false
                } else {
                    return true
                }
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            checkQuestionnaire() {
                let id = window.localStorage.getItem('userId')
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .get(`${this.url}api/users/singleuser/${id}`, config)
                    .then(resp => {
                        this.questionnaire = resp.data
                        // console.log(this.questionnaire);

                        if (this.questionnaire.name) {
                            this.$router.push('/panel')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getUser() {
                if (!localStorage.getItem('userId')) {
                    location.replace('/login')
                }

                // own ID

                axios
                    .get(
                        `${this.url}api/verify/user/${localStorage.getItem(
                            'userId'
                        )}`
                    )
                    .then(resp => {
                        this.verified = resp.data.user.isVerified
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            sendLink() {
                // console.log("trying")

                this.notsent = false

                const formData = new FormData()
                const userId = localStorage.getItem('userId')

                if (userId) {
                    formData.append('id', userId)
                }

                axios
                    .post(
                        `${this.url}api/register/resend-verification`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data' // Określenie typu zawartości dla plików
                            }
                        }
                    )
                    .then(response => {
                        // Tutaj możesz wyświetlić użytkownikowi komunikat o sukcesie
                        // console.log(response)
                        this.response = response.data.message
                    })
                    .catch(error => {
                        // Tutaj możesz wyświetlić użytkownikowi komunikat o błędzie
                        console.log(error)
                        this.response = 'Błąd wysyłania linku aktywacyjnego'
                    })
            }
        },
        mounted() {
            this.$gtm.trackEvent({
                event: 'form_sent_2'
            })
        },
        created() {
            this.getUser()
            this.checkQuestionnaire()

            // if (!localStorage.getItem('userId')) {
            //     location.replace("/login");
            // }
        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/Nunito.css';
    @import '@vueform/multiselect/themes/default.css';
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .save-profile {
        background-color: #f4f2ee;
        padding-top: 150px;
        .container {
            margin-bottom: 195px;
        }
        &__title-heading {
            width: 100%;
            font-family: 'Oswald', sans-serif;
            font-size: 55px;
            line-height: 6rem;
            font-weight: 700;
            margin-bottom: 30px;
            color: $black;
            text-align: center;
            &--smaller {
                font-size: 36px;
                margin-bottom: 77px;
                @include breakpoint-max('xs-tablet') {
                    font-size: 18px !important;
                    line-height: 4rem !important;
                }
            }
        }
        h1 {
            margin: 0 auto;
            margin-bottom: 74px;
            font-family: 'Oswald', sans-serif;
            font-weight: 900;
            text-transform: uppercase;
            color: $black;
            font-size: 55px;
            text-align: center;
            margin-bottom: 52px;
            padding-top: 78px;
            max-width: 458px;
            @include breakpoint-max('mobile') {
                padding-top: 0;
            }
        }
        &__all-photos {
            margin: 30px 26px;
        }
        &__section {
            position: relative;
            border: 1px solid black;
            border-bottom: none;
            background-color: transparent;
            display: flex;
            align-items: center;
            label {
                font-size: 16px;
                line-height: 30px;
                font-weight: 300;
            }
            input[type='text'],
            input[type='date'] {
                font-size: 16px;
                line-height: 30px;
                padding: 27px 22px;
                outline: none;
                font-weight: 300;
                border: 0;
                width: 100%;
                background-color: transparent;
                @include breakpoint-max('mobile') {
                    padding-top: 11px;
                    padding-bottom: 16px;
                }
            }
            input[type='radio'] {
                accent-color: black;
            }
            input[type='date'] {
                font-size: 14px;
            }
            @include breakpoint-max('mobile') {
                flex-direction: column;
                align-items: flex-start;
            }
        }
        &__section-all {
            border: 1px solid black;
            border-bottom: none;
            background-color: transparent;
            input[type='text'],
            input[type='date'] {
                font-size: 16px;
                padding: 27px 22px;
                outline: none;
                border: 0;
                width: 100%;
                background-color: transparent;
            }
            input[type='radio'] {
                accent-color: black;
            }
            input[type='date'] {
                font-size: 14px;
            }
        }
        &__half {
            display: flex;
            label {
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                text-transform: uppercase;
                svg {
                    margin-bottom: 11px;
                }
            }
            @include breakpoint-max('xs-tablet') {
                justify-content: center;
            }
        }
        &__photos-flex {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 21px;
            div {
                position: relative;
                &:hover {
                    svg {
                        opacity: 1;
                        pointer-events: none;
                    }
                }
                svg {
                    position: absolute;
                    transition: opacity 0.3s;
                    width: 80px;
                    height: 80px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                }
            }
            .images-box {
                position: relative;
                .trash {
                    color: $pink;
                    font-weight: 600;
                    display: block !important;
                    position: absolute !important;
                    top: 8px;
                    left: auto;
                    right: 15px !important;
                    width: 30px;
                    height: 30px;
                    opacity: 1 !important;
                    transform: translate(0) !important;
                    z-index: 999999 !important;
                    cursor: pointer;
                    svg {
                        opacity: 1;
                        width: 30px;
                        height: 30px;
                        @include breakpoint-max('mobile') {
                            width: 25px;
                            height: 25px;
                        }
                        @include breakpoint-max('s-mobile') {
                            width: 21px;
                            height: 21px;
                        }
                    }
                    @include breakpoint-max('mobile') {
                        top: 8px;
                        right: 8px !important;
                    }
                }
            }
            @include breakpoint-max('mobile') {
                grid-template-columns: repeat(2, 1fr);
            }
            .images-box {
                position: relative;
                .image-delete {
                    padding: 10px;
                    position: absolute;
                    color: #d13724;
                    font-weight: 600;
                    top: 8px;
                    right: 15px;
                    z-index: 99;
                    cursor: pointer;
                }
            }
        }
        &__three {
            display: flex;
            position: relative;
            &:nth-child(1) {
                @include breakpoint-max('mobile') {
                    border-bottom: 1px solid $black;
                    padding-bottom: 22px;
                }
            }
            .save-profile__title {
                @include breakpoint-max('s-tablet') {
                    text-align: left;
                    margin-bottom: 15px;
                }
                @include breakpoint-max('mobile') {
                    margin-bottom: 27px;
                }
            }
            @include breakpoint-max('s-tablet') {
                flex-direction: column;
            }
        }
        &__title {
            max-width: 100%;
            font-size: 16px;
            line-height: 30px;
            margin-left: 25px;
            text-transform: uppercase;
            text-wrap: nowrap;
            font-weight: 700 !important;
            font-family: 'Open Sans', sans-serif;
            &--right {
                margin-right: 30px;
            }
            @include breakpoint-max('xs-tablet') {
                text-align: center;
            }
            @include breakpoint-max('mobile') {
                text-align: left;
                margin-top: 20px;
                line-height: 15px !important;
                text-wrap: wrap;
                margin-right: 25px;
            }
        }
        &__errors {
            border-left: 1px solid black;
            border-right: 1px solid black;
            padding: 20px 26px;
            ul {
                list-style: none;
                display: flex;
                flex-direction: column;
                li {
                    display: inline-block;
                    //
                    font-size: 16px;
                    line-height: 25px;
                    font-weight: 400;
                    font-family: 'Open Sans', sans-serif;
                    color: $pink;
                    padding-left: 20px;
                }
            }
        }
        &__errors-title {
            font-size: 16px;
            line-height: 30px;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
            color: $pink;
            margin-bottom: 10px;
        }
        &__errors-subtitle {
            display: inline-block;
            margin-right: 8px;
            font-size: 16px;
            line-height: 30px;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
            color: $pink;
        }
        &__btn {
            width: 100%;
            appearance: none;
            border: 1px solid black;
            color: $black;
            cursor: pointer;
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 400;
            padding: 21px 0;
            background-color: #e1dbd5;
        }
        &__loader {
            width: 100%;
            height: 71px;
            padding: 21px 0;
            background-color: #e1dbd5;
            border: 1px solid black;
            display: grid;
            place-items: center;
            svg {
                width: 50px;
            }
        }
        &__checkbox {
            display: flex;
            align-items: center;
            column-gap: 15px;
            margin-left: 26px;
            font-size: 16px;
            line-height: 30px;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
        }
        &__flex {
            display: flex;
            width: 100%;
            padding: 22px 0;
            div {
                flex: 50%;
            }
            .save-profile__flex {
                padding: 0;
                @include breakpoint-max('mobile') {
                    flex-direction: row;
                }
            }
            @include breakpoint-max('mobile') {
                flex-direction: column;
                padding-top: 0;
            }
        }
        &__heading {
            font-size: 24px !important;
            line-height: 30px;
            margin-bottom: 15px;
            font-weight: 700;
            font-family: 'Open Sans', sans-serif;
            text-transform: uppercase;
            margin-top: 49px;
            font-size: 24px;
        }
        &__subtitle {
            font-size: 16px;
            line-height: 30px;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
        }
        &__photos {
            margin-left: 25px;
            p {
            }
        }
        &__photo-section {
            display: flex;
            flex-direction: column;
            position: relative;
            .save-profile__input-error {
                top: auto;
                bottom: -150px;
                right: auto;
                left: 50%;
                transform: translate(-50%);
            }
        }
    }
    .profile {
        &__file {
            visibility: hidden;
            display: none;
        }
        &__radio {
            background-color: green;
            margin-right: 31px;
        }
        &__img {
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
            background-color: $bege3;
            cursor: pointer;
            border: 1px solid black;
            position: relative;
        }
    }

    .city-date {
        column-gap: 27px;
        .save-profile__title {
            padding: 27px 0;
            @include breakpoint-max('xs-tablet') {
                text-wrap: wrap;
            }
            @include breakpoint-max('mobile') {
                padding-bottom: 10px;
                padding-top: 0;
            }
        }
        .multiselect {
            cursor: pointer;
            background-color: transparent;
            border: none;
            .multiselect-wrapper {
                @include breakpoint-max('mobile') {
                    width: 93%;
                }
            }
            .multiselect-tag {
                padding: 5px 10px;
            }
            .multiselect-tag-wrapper {
                font-size: 12px;
                line-height: 14px;
                text-transform: uppercase;
            }
            .multiselect-tag-remove-icon {
                width: 10px;
                height: 10px;
            }
            @include breakpoint-max('mobile') {
                margin-bottom: 15px;
            }
        }
        .multiselect-options {
            li {
                span {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
        .multiselect-clear-icon {
            background-color: $black;
            width: 15px;
            height: 15px;
        }
        .multiselect-caret {
            background-color: $black;
            width: 20px;
            height: 20px;
        }
        input {
            padding: 10px 0 !important;
            height: 26px !important;
            width: 100% !important;
            font-size: 14px !important;
            line-height: 30px !important;
        }
        .multiselect-tags {
            height: 26px !important;
            gap: 8px;
            height: auto !important;
            align-items: flex-start !important;
        }
        .multiselect-tag {
            background-color: $pink;
        }
        .multiselect-tags-search {
            padding: 0 !important;
        }
    }
    .checkBox {
        display: block;
        cursor: pointer;
        width: 15px;
        height: 15px;
        min-width: 15px;
        min-height: 15px;
        border: 2px solid rgba(255, 255, 255, 0);
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        box-shadow: 0px 0px 0px 1px $black;
    }
    .checkBox div {
        width: 60px;
        height: 60px;
        background-color: $black;
        top: -52px;
        left: -52px;
        position: absolute;
        transform: rotateZ(45deg);
        z-index: 100;
    }
    .checkBox input[type='checkbox']:checked + div {
        left: -10px;
        top: -10px;
    }
    .checkBox input[type='checkbox'] {
        position: absolute;
        left: 50px;
        visibility: hidden;
    }
    .transition {
        transition: 300ms ease;
    }
    .loader {
        svg {
            width: 40px;
            transform-origin: center;
            animation: rotate4 2s linear infinite;
        }
        circle {
            fill: none;
            stroke: $white;
            stroke-width: 2;
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
            stroke-linecap: round;
            animation: dash4 1.5s ease-in-out infinite;
        }

        @keyframes rotate4 {
            100% {
                transform: rotate(360deg);
            }
        }

        @keyframes dash4 {
            0% {
                stroke-dasharray: 1, 200;
                stroke-dashoffset: 0;
            }

            50% {
                stroke-dasharray: 90, 200;
                stroke-dashoffset: -35px;
            }

            100% {
                stroke-dashoffset: -125px;
            }
        }
    }
    .dNone {
        display: none !important;
    }

    .noemail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            font-size: 18px;
            margin-top: 15px;
            a {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
</style>
