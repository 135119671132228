<template>
    <section class="privacy">
        <HeaderComponent/>
        <div class="container">
        <div class="privacy__content">
    
<div>
    <h1>Regulamin Platformy „Biuro Randkowe”</h1>
<p>
Niniejszy
regulamin (<i>dalej
jako: „Regulamin”</i>)
określa warunki i zasady świadczenia usług na
Platformie pod adresem <u><a href="http://www.biurorandkowe.pl/">www.biurorandkowe.pl</a></u>

</p>
<p>
<br/>
</p>
<p>

<h3>§1. POSTANOWIENIA OGÓLNE</h3></p>
<p>
<br/>
</p>
<ol>
<li><p>
Platforma
	dostępna pod adresem internetowym www.biurorandkowe.pl (dalej
	jako: „Platforma”, „Biuro”,
	„Biuro randkowe”) prowadzona jest przez Usługodawcę
	- B87 spółkę z ograniczoną odpowiedzialnością
	z siedzibą w Kaliszu (adres: ul. Baśniowa 25, 62-800
	Kalisz), wpisaną do rejestru przedsiębiorców
	Krajowego Rejestru Sądowego prowadzonego przez Sąd
	Rejonowy Poznań – Nowe Miasto i Wilda w Poznaniu, IX
	Wydział Gospodarczy Krajowego Rejestru Sądowego pod
	numerem KRS: 0001061711, posiadająca numer NIP: 6182199989 oraz
	numer REGON: 526571132. 
</p>
<li><p>
Niniejszy
	Regulamin określa warunki i zasady świadczenia usług
	na Platformie i stosuje się do
	umów
	zawieranych poprzez stronę internetową pod adresem
	<u><a href="http://www.biurorandkowe.pl/">www.biurorandkowe.pl</a></u>.</p>
<li><p>
Adres
	email kontakt@biurorandkowe.pl
	stanowi elektroniczny punkt kontaktowy umożliwiający
	bezpośrednią komunikację drogą elektroniczną,
	w języku polskim, z organami państw członkowskich,
	Komisją i
	Radą
	Usług Cyfrowych i jednocześnie jest adresem, pod którym
	użytkownicy mogą bezpośrednio i szybko komunikować
	się z Usługodawcą. 
</p>
<li><p>
Przetwarzanie
	danych osobowych przez Usługodawcę określone jest w
	Polityce Prywatności dostępnej pod adresem:
	<u><a href="http://www.biurorandkowe.pl/politykaprywatnosci">www.biurorandkowe.pl/politykaprywatnosci</a></u>.</p>
<li><p>
Zamieszczone
	na Platformie informacje o świadczonych przez Usługodawcę
	za jej pośrednictwem usługach, w tym opisy oraz ceny
	stanowią zaproszenie do zawarcia umowy w rozumieniu art. 71
	Ustawy z dnia 23 kwietnia 1964 r. Kodeks cywilny (t.j. Dz. U. z 2023
	r. poz. 1610 z późn. zm.).</p>
<li><p>
Użyte
	w niniejszym Regulaminie pojęcia oznaczone wielką literą
	należy
	rozumieć w sposób
	zdefiniowany następująco:</p>
</li></li></li></li></li></li></ol>
<p>
<br/>
</p>
<ol>
<ol>
<li><p>
<b>Regulamin</b>
		– niniejszy regulamin Platformy, o którym
		mowa w art. 8 ustawy z dnia 18 lipca 2002 roku o świadczeniu
		usług drogą elektroniczną (Dz. U. 2002 Nr 144, poz.
		1204 ze zm.).</p>
<li><p>
<b>Usługodawca
		</b>–
		spółka świadcząca usługi dostępne
		na Platformie, tj. B87 spółka z ograniczoną
		odpowiedzialnością z siedzibą w Kaliszu (adres: ul.
		Baśniowa 25, 62-800 Kalisz), wpisana do rejestru
		przedsiębiorców
		Krajowego Rejestru Sądowego prowadzonego przez Sąd
		Rejonowy Poznań – Nowe Miasto i Wilda w Poznaniu, IX
		Wydział Gospodarczy Krajowego Rejestru Sądowego pod
		numerem KRS: 0001061711, posiadająca numer NIP: 6182199989
		oraz numer REGON: 526571132.</p>
<li><p>
<b>Platforma
		</b>- platforma
		internetowa dostępna dla Użytkowników
		pod adresem internetowym www.biurorandkowe.pl pod nazwą „Biuro
		Randkowe” za pośrednictwem której
		Usługodawca świadczy na rzecz Użytkowników
		usługi drogą elektroniczną; dostępna również
		z poziomu urządzeń mobilnych jako aplikacja mobilna, w
		tym aplikacja typu PWA, na tych samych zasadach określonych w
		Regulaminie.</p>
<li><p>
<b>Użytkownik
		</b>-
		każda
		pełnoletnia
		osoba fizyczna korzystająca z Usług dostępnych na
		Platformie po założeniu Konta na Platformie.</p>
<li><p>
<b>Usługa
		</b>–
		usługa oferowana na Platformie; na Platformie oferowane są
		Usługi opisane w niniejszym Regulaminie.</p>
<li><p>
<b>Cennik</b>
		– opis cen obowiązujących za poszczególne
		Usługi, dostępny na Platformie w zakładce oznaczonej
		jako „Cennik”.</p>
<li><p>
<b>Formularz
		rejestracji</b>
		– dostępny na Platformie formularz umożliwiający
		Użytkownikowi założenie konta na Platformie i
		następnie dokonanie wyboru Usług dostępnych na
		Platformie. 
</p>
<li><p>
<b>Formularz
		zgłoszeniowy </b>–
		dostępny na Platformie formularz umożliwiający
		Użytkownikowi podanie odpowiedzi na temat Użytkownika
		oraz jego preferencji dotyczących potencjalnego partnera lub
		partnerki w celu stworzenia Profilu Singla. 
</p>
<li><p>
<b>Formularz
		zam</b><b>ó</b><b>wienia
		</b>–
		dostępny na Platformie formularz umożliwiający
		Użytkownikowi dokonanie zamówienia
		wybranej Usługi. 
</p>
<li><p>
<b>Konto,
		Konto Użytkownika </b>–
		przestrzeń na Platformie dostępna dla Użytkownika po
		zarejestrowaniu się za pośrednictwem Formularza
		rejestracji za pomocą którego
		Użytkownik może wybrać dostępne na Platformie
		Usługi oraz przeglądać zawartość Platformy
		z poziomu zalogowanego Użytkownika.</p>
</li></li></li></li></li></li></li></li></li></li></ol>
</ol>
<ol>
<ol>
<li><p>
<b>Email
		U</b><b>żytkownika
		</b>-
		email wskazany przez Użytkownika w celu założenia
		konta na Platformie lub następnie zaktualizowany z poziomu
		panelu obsługi Konta.</p>
</li></ol>
</ol>
<ol>
<ol start="11">
<li><p>
<b>Profil
		Singla, </b><b>Profil
		</b>–
		zestawienie informacji o Użytkowniku oraz jego preferencji
		dotyczących potencjalnego partnera lub partnerki przekazanych
		dobrowolnie przez Użytkownika poprzez wypełnienie
		Formularza zgłoszeniowego i wyświetlanych Użytkownikom
		na skutek przeprowadzenia Dopasowania. Profil Singla jest stworzony
		na potrzeby przeprowadzenia doboru i widoczny tylko dla
		Usługodawcy. Profil Singla jest dostępny w całości
		do przeglądania przez Usługodawcę w celu
		przeprowadzenia Dopasowania, a w ograniczonym zakresie jako Profil
		publiczny.</p>
<li><p>
<b>Profil
		publiczny </b>–
		zestawienie niektórych
		informacji o Użytkowniku oraz jego preferencji dotyczących
		potencjalnego partnera lub partnerki zawartych w Profilu, które
		zostały oznaczone w Formularzu zgłoszeniowym jako
		informacja widoczna w profilu,
		co oznacza, że te informacje będą widoczne dla
		innych Użytkowników.
Użytkownik,
		który
		nie zgadza się na przekazanie tych informacji lub danych
		powinien zrezygnować z dalszego wypełniania i przesyłania
		Formularza zgłoszeniowego.</p>
<li><p>
<b>Dopasowanie
		</b>–
		propozycja doboru do siebie 2 (dwóch)
		Użytkowników
		jako potencjalnych partnerów
		lub partnerek wedle informacji podanych dobrowolnie przez
		Użytkownika i zawartych w Profilu Singla. Dopasowanie
		realizowane jest w Pakiecie Basic, Pakiecie Plus i Pakiecie Premium
		na zasadach określonych w Regulaminie, a jego zakres
		uzależniony jest od wybranego przez Użytkownika Pakietu. 
</p>
</li></li></li></ol>
</ol>
<ol>
<ol start="2">
<li><p>
<b>Op</b><b>łata
		członkowska </b>–
		opłata pobierana od Użytkownika po wypełnieniu
		Formularza zgłoszeniowego i zatwierdzeniu go oraz wysłaniu
		Usługodawcy za pośrednictwem Platformy przez Użytkownika
		w celu dołączenia do Biura Randkowego jako aktywny
		Użytkownik,
		dokonywana w Pakiecie Basic. Opłata
		członkowska stanowi opłatę dokonywaną za
		dołączenie
		do Biura Randkowego jako aktywny Użytkownik wpisany do bazy
		Użytkowników
		w Pakiecie Basic.</p>
</li></ol>
</ol>
<ol>
<ol start="14">
<li><p>
<b>Pakiet
		Basic </b>–
		Usługa świadczona przez Usługodawcę na rzecz
		Użytkownika na Platformie będąca podstawowym
		wariantem, w ramach której
		możliwe jest przedstawienie Dopasowania, na zasadach
		określonych w Regulaminie. Zawiera funkcjonalności
		opisane na Platformie wraz z podaniem ceny. Użytkownik może
		wybrać i opłacić
		Pakiet Basic po zalogowaniu się do Konta i wypełnieniu
		Formularza zgłoszeniowego; oznaczony na Platformie jako
		„Pakiet
		Basic”.</p>
<li><p>
<b>Pakiet
		Plus </b>–
		Usługa świadczona przez Usługodawcę na rzecz
		Użytkownika na Platformie w rozbudowanym w stosunku do Pakietu
		Plus wariancie, w ramach której
		możliwe jest przedstawienie Dopasowania, na zasadach
		określonych w Regulaminie. Zawiera funkcjonalności
		opisane na Platformie wraz z podaniem ceny. Pakiet Plus jest
		dostępny do wyboru wyłącznie dla Użytkowników
		korzystających z Pakietu Basic. Użytkownik może
		wybrać i opłacić
		Pakiet Plus po zalogowaniu się do Konta jako podwyższenie
		z Pakietu Basic; oznaczony na Platformie jako „Pakiet Plus”.</p>
<li><p>
<b>Pakiet
		Premium </b>–
		Usługa świadczona przez Usługodawcę na rzecz
		Użytkownika jako wariant rozszerzony w stosunku do Pakietu
		Basic i Pakietu Plus. Dostępność Pakietu Premium
		jest ograniczona, a skorzystanie z niego wymaga skontaktowania się
		z Usługodawcą na adres email:kontakt@biurorandkowe.pl.
		Zakup Pakietu Premium nie odbywa się bezpośrednio przez
		mechanizmy płatności dostępne na Platformie a z
		użyciem danych do płatności przesłanych przez
		Usługodawcę w bezpośredniej komunikacji z
		Użytkownikiem w postaci indywidualnego linku do płatności;
		oznaczony na Platformie jako „Pakiet Premium”. 
</p>
<li><p>
<b>Polityka
		prywatnoś</b><b>ci
		</b>–
		dokument opublikowany na Platformie zawierający informację
		o przetwarzaniu danych osobowych na Platformie.</p>
</li></li></li></li></ol>
</ol>
<p>
<br/>
</p>
<ol>
<li><p>
Usługi
	dostępne na Platformie są odpłatne według
	Cennika udostępnionego na Platformie i przy opisie każdej
	z dostępnych Usług
	i są
	świadczone na wyraźne żądanie Użytkownika
	wyrażone na Platformie w oznaczonych w tym celu miejscach, w
	sposób
	i w terminach określonych w Regulaminie.</p>
</li></ol>
<p>
<br/>
</p>
    <h3>§2. PODSTAWOWE ZASADY KORZYSTANIA Z PLATFORMY I MODERACJA TREŚCI</h3>
<p>
<br/>
</p>
<ol>
<li><p>
Z
	Platformy mogą korzystać wyłącznie pełnoletnie
	osoby fizyczne. 
</p>
<li><p>
Można
	posiadać tylko jedno Konto Użytkownika na Platformie.
	Usługodawca usuwa każde kolejne Konto Użytkownika
	założone przez tą samą osobę w okresie
	utrzymywania
	pierwszego zarejestrowanego Konta tego Użytkownika na
	Platformie.</p>
<li><p>
Usługi
	płatne świadczone za pośrednictwem Platformy mają
	na celu umożliwienie Użytkownikom nawiązania
	znajomości z innymi Użytkownikami na skutek doboru
	przeprowadzonego przez Usługodawcę w ramach zakupionej
	przez Użytkownika Usługi, wedle informacji podanych przez
	Użytkowników,
	jeżeli informacje te oraz wybrany przez Użytkownika Pakiet
	pozwalają na przedstawienie Dopasowania.</p>
<li><p>
Użytkownik
	Platformy zobowiązany jest do zapoznania się z niniejszym
	Regulaminem oraz z Polityką Prywatności oraz do
	przestrzegania postanowień Regulaminu, powszechnie
	obowiązujących przepisów
	prawa, zasad współżycia społecznego oraz
	dobrych obyczajów
	i netykiety. 
</p>
<li><p>
Użytkownik
	Platformy zobowiązuje się do
	używania
	bezpiecznego hasła do swojego konta założonego na
	Platformie oraz do jego bezpiecznego przechowywania,
	nieprzekazywania go osobom trzecim oraz do nieudostępniania
	Konta Użytkownika utrzymywanego na Platformie osobom trzecim. 
</p>
<li><p>
W
	celu wzięcia przez Użytkownika udziału w procesie
	zmierzającym do nawiązania znajomości z innymi
	Użytkownikami konieczne jest przejście następujących
	etapów,
	na zasadach określonych w Regulaminie w odniesieniu do
	poszczególnych
	Usług:</p>
</li></li></li></li></li></li></ol>
<p>
<br/>
</p>
<ol>
<ol>
<li><p>
rejestracja
		Konta Użytkownika,</p>
<li><p>
uzupełnienie
		Formularza zgłoszeniowego w celu stworzenia Profilu Singla,</p>
<li><p>
weryfikacja
		przez Usługodawcę wypełnionego przez Użytkownika
		Formularza zgłoszeniowego i stworzenie Profilu Singla (po
		dokonaniu płatności za odpowiedni Pakiet),</p>
<li><p>
rozpoczęcie
		przez Usługodawcę procesu poszukiwań w celu
		przedstawienia propozycji Dopasowania (po dokonaniu płatności
		za odpowiedni Pakiet. 
</p>
</li></li></li></li></ol>
</ol>
<p>
<br/>
</p>
<ol start="7">
<li><p>
Usługodawca
	nie zapewnia i nie gwarantuje, że świadczenie Usług
	doprowadzi do nawiązania relacji o jakimkolwiek charakterze z
	innym Użytkownikiem, w szczególności
	umówienia
	Użytkowników
	na randkę, w okresie świadczenia usług lub później,
	ani że znajomość ta
	spełni
	oczekiwania lub potrzeby Użytkownika. Za zakres, charakter i
	przebieg nawiązanej na Platformie znajomości jest
	odpowiedzialny wyłącznie Użytkownik.</p>
</li></ol>
<ol>
<li><p>
Użytkownik
	zobowiązuje się do podania w Formularzu rejestracji,
	Formularzu zgłoszeniowym i
	Formularzu zamówienia
	prawdziwych
	i aktualnych informacji, zgodnych z wymogami określonymi w
	Regulaminie, w szczególności
	niestanowiących informacji zabronionych na mocy Regulaminu.</p>
<li><p>
W
	przypadku uzasadnionego podejrzenia, że wypełniony
	Formularz zgłoszeniowy Użytkownika zawiera nieprawdziwe
	informacje lub w przypadku innego naruszenia przez Użytkownika
	Regulaminu, powszechnie obowiązujących przepisów
	prawa, zasad współżycia społecznego, dobrych
	obyczajów
	lub netykiety Usługodawca jest uprawniony do usunięcia
	Konta Użytkownika. W przypadku usunięcia Konta Użytkownika
	przez Usługodawcę Użytkownikowi nie przysługuje
	roszczenie o odszkodowanie lub inne ekwiwalentne, w tym roszczenie
	tytułem utraconych korzyści. W przypadku usunięcia
	Konta Użytkownika Użytkownikowi nie przysługuje zwrot
	dokonanych za Usługę opłat, w tym Opłaty
	członkowskiej.</p>
<li><p>
Usługodawca
	nie dokonuje weryfikacji prawdziwości ani aktualności
	informacji podanych przez Użytkownika w Formularzu
	zgłoszeniowym, Profilu Singla lub w toku komunikacji z innymi
	Użytkownikami za pośrednictwem Platformy lub poza
	Platformą i nie ponosi za to odpowiedzialności. Każdy
	Użytkownik może zgłosić Usługodawcy, że
	informacje opublikowane przez Użytkownika na Profilu publicznym
	lub podane w komunikacji za pośrednictwem Platformy lub poza
	nią są nieprawdziwe lub nieaktualne. 
</p>
<li><p>
Usługodawca
	nie ponosi odpowiedzialności za treści publikowane przez
	Użytkowników
	na Platformie, w tym odpowiedzialności odszkodowawczej, przez
	co rozumieć należy
	także roszczenia z tytułu utraconych korzyści.
	Usługodawca nie ma wglądu do wiadomości wymienianych
	przez Użytkowników
	za pośrednictwem Platformy, a ich treść jest
	szyfrowana w sposób
	uniemożliwiający zapoznanie się z nią przez inne
	osoby niż uczestnicy konwersacji. 
</p>
<li><p>
Usługodawca
	nie ponosi odpowiedzialności za utratę lub zniszczenie
	danych z przyczyn występujących po stronie Użytkownika
	lub z przyczyn, za które
	Usługodawca
	nie ponosi winy.</p>
<li><p>
Zakazane
	jest pobieranie lub utrwalanie w jakikolwiek sposób
	treści dostępnych na Platformie, w tym udostępnionych
	Użytkownikowi w ramach Usługi Dopasowania przez
	Usługodawcę albo bezpośrednio przez innego
	Użytkownika, jak również
	innych materiałów umieszczonych na Platformie.</p>
<li><p>
Usługodawca
	nie ponosi odpowiedzialności za zachowania Użytkowników,
	na które
	nie ma wpływu, w szczególności
	za nieodpowiednie lub niegrzeczne albo obraźliwe komentarze lub
	ujawnienie osobom trzecim lub publicznie w jakikolwiek sposób
	albo w jakiejkolwiek formie informacji na temat Użytkownika,
	jego Profilu publicznego, treści korespondencji lub informacji
	przekazanych poza Platformą. Użytkownik ponosi pełną
	i wyłączną odpowiedzialność za swoje
	zachowanie, tj. działanie lub zaniechanie wobec Usługodawcy
	lub innych Użytkowników,
	zarówno
	na Platformie, jak i poza nią. 
</p>
<li><p>
Zakazane
	jest prezentowanie przez Użytkownika na Platformie, w
	jakikolwiek sposób
	i w jakiejkolwiek formie, w tym w Profilu lub w wiadomościach
	bezprawnych lub krzywdzących albo niezgodnych z celem Platformy
	treści, przez które
	należy rozumieć także zdjęcia lub materiały
	wideo, w szczególności:</p>
</li></li></li></li></li></li></li></li></ol>
<p>
<br/>
</p>
<ol>
<ol>
<li><p>
nieodpowiednich,
		niegrzecznych lub obraźliwych, 
</p>
<li><p>
dyskryminujących,
		
</p>
<li><p>
naruszających
		dobra osobiste, dobre obyczaje lub zasady współżycia
		społecznego albo netykietę, 
</p>
<li><p>
o
		charakterze erotycznym,</p>
<li><p>
nawołujące
		do nienawiści lub cyberprzemocy albo popierające takie
		postawy,</p>
<li><p>
zawierających
		informacje marketingowe lub promocyjne,</p>
<li><p>
zawierających
		informacje zachęcające do zakupu towarów
		lub usług, w tym oferty handlowe,</p>
<li><p>
zawierające
		linki kierujące do stron prezentujących działalność
		innych podmiotów,
		
</p>
<li><p>
promujące
		działalność konkurencyjną w stosunku do
		Usługodawcy,</p>
<li><p>
zawierających
		wirusy lub inne niebezpieczne kody, narażające
		Użytkowników
		lub Usługodawcę na szkodę (w tym spam lub scam),</p>
<li><p>
o
		charakterze terrorystycznym lub nawołującym do przemocy,</p>
<li><p>
będące
		elementem przestępstwa, w tym oszustwa.</p>
</li></li></li></li></li></li></li></li></li></li></li></li></ol>
</ol>
<p>
<br/>
</p>
<ol start="9">
<li><p>
Usługodawca
	moderuje treści prezentowane przez Użytkowników
	na Platformie w szczególności
	w celu wykrywania, identyfikowania i zwalczania nielegalnych treści
	lub informacji, o których
	mowa w ust. 15 powyżej poprzez:</p>
</li></ol>
<p>
<br/>
</p>
<ol>
<ol>
<li><p>
niezwłoczne
		usunięcie treści wprowadzonej przez Użytkownika w
		Profilu, o czym informuje Użytkownika przesyłając
		powiadomienie w tym przedmiocie na Email Użytkownika, 
</p>
<li><p>
niezwłoczne
		usunięcie zdjęcia wprowadzonego przez Użytkownika w
		Profilu, o czym informuje Użytkownika przesyłając
		powiadomienie w tym przedmiocie na Email Użytkownika.</p>
</li></li></ol>
</ol>
<p>
<br/>
</p>
<ol start="10">
<li><p>
W
	przypadku naruszania przez Użytkownika postanowień
	Regulaminu Usługodawca może zablokować lub usunąć
	Konto
	Użytkownika,
	po uprzednim wezwaniu go na Email Użytkownika do zaprzestania
	naruszania Regulaminu. W przypadku niedostosowania się do
	wezwania lub ponownego naruszenia przez Użytkownika Regulaminu,
	Usługodawca dokonuje blokady Konta na wybrany okres lub usuwa
	Konto, wedle decyzji Usługodawcy. Zablokowanie lub usunięcie
	Konta nie skutkuje zwrotem dokonanych przez Użytkownika opłat
	za wybrany Pakiet lub inne Usługi.</p>
<li><p>
Usługodawca
	nie ma wglądu w treść prowadzonej przez Użytkowników
	korespondencji i w tym zakresie dokonuje moderacji treści, o
	których
	mowa w ust. 15 powyżej niezwłocznie, na żądanie
	Użytkownika poprzez zawieszenie lub usunięcie konta
	Użytkownika, w zależności od sposobu i zakresu
	naruszenia przez niego zasad dotyczących prezentowania treści
	i wymiany korespondencji, o których
	mowa w niniejszym Regulaminie. 
</p>
<li><p>
Każdy
	Użytkownik może zgłosić Usługodawcy
	nieodpowiednią treść prezentowaną przez innego
	Użytkownika poprzez wysłanie zgłoszenia na adres
	email Usługodawcy: kontakt@biurorandkowe.pl. Zgłoszenie
	powinno obejmować co najmniej:</p>
</li></li></li></ol>
<p>
<br/>
</p>
<ol>
<ol>
<li><p>
opis
		nieodpowiedniej treści wraz z wyjaśnieniem powodów
		zgłoszenia,</p>
<li><p>
imię,
		nazwisko oraz adres email osoby zgłaszającej,</p>
<li><p>
oświadczenie
		potwierdzające powzięte w dobrej wierze przekonanie osoby
		dokonującej zgłoszenia, że informacje i zarzuty w
		nim zawarte są prawidłowe i kompletne.</p>
</li></li></li></ol>
</ol>
<p>
<br/>
</p>
<ol start="13">
<li><p>
Usługodawca
	informuje osobę zgłaszającą o otrzymaniu
	zgłoszenia, a następnie także o podjętej
	decyzji, bez zbędnej zwłoki. W przypadku wielu zgłoszeń,
	czas oczekiwania na powiadomienie może się wydłużyć.
	
</p>
<li><p>
Niezależnie
	od moderacji treści na żądanie Użytkownika,
	Użytkownik ma prawo do zablokowania otrzymywania wiadomości
	od innego Użytkownika z poziomu widoku okna korespondencji
	poprzez usunięcie z dopasowanej pary. 
</p>
<li><p>
Moderacja
	treści nie jest prowadzona w sposób
	zautomatyzowany.</p>
<li><p>
O
	usunięciu treści lub zawieszeniu albo usunięciu konta
	Usługodawca informuje Użytkownika, którego
	ta decyzja dotyczy, w dniu dokonania takiej czynności moderacji
	na adres email, z podaniem uzasadnienia. Usługodawca podejmuje
	decyzję w tym przedmiocie niezwłocznie, w sposób
	niearbitralny i obiektywny oraz z zachowaniem należytej
	staranności. 
</p>
<li><p>
Jeżeli
	decyzja dotycząca
	treści
	została podjęta na skutek zgłoszenia innego
	Użytkownika, Usługodawca informuje osobę, której
	dotyczy decyzja o tożsamości osoby zgłaszającej
	wyłącznie wtedy, gdy zgłoszenie dotyczyło
	prowadzonej ze zgłaszającym korespondencji na Platformie.</p>
<li><p>
Użytkownik,
	którego
	treść została usunięta lub którego
	konto zostało zawieszone albo usunięte ma prawo do
	odwołania się od tej decyzji Usługodawcy w terminie
	14 dni od dnia dokonania czynności, o której
	mowa w ust. 23 powyżej, przesyłając odwołanie
	na adres email Usługodawcy:
	kontakt@biurorandkowe.pl. Usługodawca rozpatruje odwołanie
	w terminie 14 dni od jego otrzymania i niezwłocznie informuje
	Użytkownika odwołującego się o sposobie
	rozpoznania odwołania. Od tak podjętej decyzji odwołanie
	nie przysługuje.</p>
</li></li></li></li></li></li></ol>
<p>
<br/>
</p>
<h3>§3. WYMAGANIA TECHNICZNE PLATFORMY</h3>
<p>
<br/>
</p>
<ol>
<li><p>
Do
	korzystania z Platformy wymagana jest przeglądarka internetowa
	w wersji co najmniej Internet Explorer 9 lub Chrome 30 lub FireFox
	30 lub Opera 10 lub Safari 5 lub nowszych, z włączoną
	obsługą języka Javascript oraz łącze
	internetowe o przepustowości co najmniej 256 kbit/s. Strona
	internetowa Platformy jest responsywna i dynamicznie dopasowuje się
	do każdej rozdzielczości ekranu.</p>
<li><p>
W
	celu rejestracji konta na Platformie Użytkownik musi posiadać
	aktywny adres poczty elektronicznej.</p>
<li><p>
Ograniczenia
	stosowania plików
	cookies mogą
	wpłynąć na niektóre
	funkcjonalności dostępne na stronach internetowych
	Platformy. Więcej informacji na temat zarządzania plikami
	cookies znajdują się w Polityce prywatności.</p>
<li><p>
Usługodawca
	nie ponosi odpowiedzialności za nadmiernie przeciążone
	łącze
	internetowe Użytkownika, a także inne łącza
	międzyoperatorskie, jak również
	za inne okoliczności niezależne od Usługodawcy, w
	szczególności
	wynikające z działania siły wyższej lub
	działania osób
	trzecich, za które
	Usługodawca
	nie ponosi odpowiedzialności.</p>
<li><p>
W
	przypadku konieczności dokonania zmian i ulepszeń w
	Platformie czy przeprowadzenia prac konserwacyjnych lub awarii
	sprzętu Usługodawca zastrzega sobie prawo do czasowej
	przerwy w funkcjonowaniu Platformy. Powiadomienie w tym przedmiocie
	tj. o przerwach technicznych i czasie ich trwania kierowane do
	Użytkowników
	Platformy zostanie opublikowane na stronie internetowej Platformy.</p>
<li><p>
Niniejszy
	Regulamin nie ma na celu ograniczenia lub wyłączenia
	jakichkolwiek praw Użytkownika będącego jednocześnie
	konsumentem, przysługujących mu na mocy bezwzględnie
	obowiązujących przepisów
	prawa. W przypadku niezgodności postanowień niniejszego
	Regulaminu z powyższymi przepisami, pierwszeństwo mają
	powszechnie obowiązujące przepisy prawa.</p>
</li></li></li></li></li></li></ol>
<p>
<br/>
</p>
<p>
    <h3>§4. USŁUGI ŚWIADCZONE NA PLATFORMIE</h3>
<br/>
</p>
<ol>
<li><p>
Akceptacja
	Regulaminu oznacza, że Użytkownik wyraźnie akceptuje
	warunki świadczenia Usług. 
</p>
<li><p>
Za
	pośrednictwem Platformy Usługodawca świadczy Usługi
	w postaci:</p>
<ol>
<li><p>
Usługi
		Rejestracji Konta Użytkownika,</p>
<li><p>
Usługi
		„Pakiet
		Basic”,</p>
<li><p>
Usługi
		„Pakiet Plus”,</p>
<li><p>
Usługi
		„Pakiet Premium”,</p>
<li><p>
Usługi
		Newslettera. 
</p>
</li></li></li></li></li></ol>
<li><p>
Warunki
	świadczenia poszczególnych
	Usług opisane są w dalszej części Regulaminu.</p>
</li></li></li></ol>
<p>
<br/>
</p>
<p>
<h3>§5. USŁUGA REJESTRACJI KONTA UŻYTKOWNIKA</h3></p>
<p>
<br/>
</p>
<ol>
<li><p>
Użytkownik
	może założyć Konto na Platformie na zasadach
	określonych w Regulaminie w celu korzystania z dostępnych
	Usług. 
</p>
<li><p>
Użytkownik
	dobrowolnie i nieodpłatnie może wprowadzić za
	pośrednictwem Platformy w Formularzu rejestracji dane niezbędne
	do założenia Konta Użytkownika, w szczególności
	poprzez zapisanie się na listę oczekujących.
	Usługodawca po uruchomieniu usług na Platformie powiadamia
	Użytkownika o możliwości dostępu Użytkownika
	do Konta Użytkownika i możliwości korzystania z
	dalszych funkcjonalności Platformy.</p>
</li></li></ol>
<ol>
<li><p>
W
	celu rejestracji Konta Użytkownik wypełnia Formularz
	rejestracji podając następujące
	dane: email, hasło.
	Aktywacja Konta następuje poprzez potwierdzenie założenia
	Konta za pomocą linka przesłanego przez Usługodawcę
	w wiadomości email na podany przez Użytkownika adres
	email.</p>
</li></ol>
<ol start="3">
<li><p>
W
	celu skorzystania z innych Usług dostępnych na Platformie
	niezbędne jest posiadanie Konta Użytkownika. 
</p>
<li><p>
Przed
	zatwierdzeniem wypełnionego Formularza rejestracji Konta,
	Użytkownik zobowiązany
	jest do zapoznania się z treścią Regulaminu i
	w przypadku jego akceptacji o zaznaczenie
	rubryki potwierdzającej akceptację Regulaminu.</p>
<li><p>
Zaznaczenie
	rubryki z oznaczeniem akceptacji Regulaminu i potwierdzenie
	rejestracji konta pod Formularzem rejestracji oznaczonego jako
	rejestracja lub zapis na listę oczekujących stanowi
	zawarcie umowy świadczenia usługi Rejestracji Konta na
	Platformie. W
	ramach tej Usługi Usługodawca nieodpłatnie rejestruje
	Konto Użytkownika na Platformie, utrzymuje je na Platformie,
	umożliwia dostęp do treści publikowanych na
	Platformie oraz umożliwia wypełnienie Formularza
	zgłoszeniowego, którego
	przesłanie do weryfikacji możliwe jest po zakupie Pakietu
	Basic.</p>
<li><p>
Brak
	akceptacji Regulaminu uniemożliwia zawarcie umowy świadczenia
	usług na Platformie. 
</p>
</li></li></li></li></ol>
<ol start="2">
<li><p>
Po
	zalogowaniu się do Konta Użytkownik może wypełnić
	Formularz zgłoszeniowy. Umożliwienie wypełnienia
	Formularza zgłoszeniowego jest udostępniane nieodpłatnie
	wszystkim Użytkownikom posiadającym Konto Użytkownika.
	Zatwierdzenie przez Użytkownika i przesłanie Formularza
	zgłoszeniowego do weryfikacji przez Usługodawcę jest
	możliwe w Pakiecie Basic i jest odpłatne na zasadach
	określonych w Regulaminie i w Cenniku.</p>
<li><p>
Dostęp
	do wypełnienia Formularza zgłoszeniowego ma każdy
	Użytkownik, który
	założył Konto. Dostęp ten jest udzielany przez
	Usługodawcę poprzez przesłanie powiadomienia na Email
	Użytkownika z informacją, że w panelu Użytkownika
	udostępniony został do wypełnienia Formularz
	zgłoszeniowy.</p>
<li><p>
Użytkownik,
	który
	wypełnił Formularz zgłoszeniowy może przekazać
	go Usługodawcy do weryfikacji według zasad określonych
	w §6 Regulaminu w ramach Pakietu Basic.</p>
<li><p>
Do
	czasu zatwierdzenia przez Użytkownika formularza Profilu
	Użytkownika Usługodawca nie zapisuje i nie przetwarza
	wprowadzonych przez Użytkownika danych i informacji w innym
	celu niż dla przechowywania ich na potrzeby uzupełnienia
	Formularza przez Użytkownika. 
</p>
</li></li></li></li></ol>
<ol start="7">
<li><p>
Rozwiązanie
	umowy świadczenia Usługi Rejestracji Konta Użytkownika
	następuje poprzez usunięcie przez Użytkownika Konta
	na Platformie lub usunięcie konta przez Usługodawcę w
	przypadku naruszenia przez Użytkownika zasad określonych w
	Regulaminie. 
</p>
<li><p>
Użytkownik
	w każdej chwili jest uprawniony do wypowiedzenia umowy
	świadczenia
	usługi Rejestracji Konta na Platformie poprzez usunięcie
	Konta w
	drodze wybrania w panelu
	Konta odpowiedniej funkcji. Usunięcie Konta skutkuje usunięciem
	wszystkich danych i informacji zapisanych na Koncie, w tym w Profilu
	Singla i Profilu publicznym oraz treści korespondencji z
	Użytkownikami, w sposób
	trwały i nieodwracalny. 
</p>
<li><p>
Usunięcie
	Konta nie skutkuje zwrotem dokonanych przez Użytkownika opłat
	za Usługi świadczone na Platformie.</p>
</li></li></li></ol>
<p>
<br/>
</p>
<p>
<h3>§6. USŁUGA „PAKIET BASIC”</h3></p>
<p>
<br/>
</p>
<ol>
<li><p>
W
	ramach Usługi „Pakiet
	Basic”
	Usługodawca zapewnia:</p>
</li></ol>
<p>
<br/>
</p>
<ol>
<ol>
<li><p>
członkostwo
		w Biurze Randkowym przez 12 miesięcy, oznaczające
		możliwość
		korzystania z pozostałych usług opisanych w pkt. 2-7
		poniżej,</p>
<li><p>
weryfikację
		przez Usługodawcę Formularza zgłoszeniowego, w tym
		sprawdzenie zgodności i spójności
		przesłanych informacji,</p>
<li><p>
stworzenie
		przez Usługodawcę Profilu Singla na podstawie Formularza
		zgłoszeniowego,</p>
<li><p>
publikację
		przez Usługodawcę Profilu Singla w bazie Biura
		Randkowego, który
		w wersji Profilu publicznego prezentowany jest dopasowanej osobie,</p>
<li><p>
nielimitowaną
		prezentację przez Usługodawcę Profilu publicznego
		osobom korzystającym z Pakietu Plus lub Pakietu Premium w celu
		przeprowadzenia doboru, 
</p>
<li><p>
możliwość
		korzystania z funkcji czatu i jego dodatkowych funkcjonalności
		w przypadku zaakceptowania przez obie strony Dopasowania,</p>
<li><p>
możliwość
		wprowadzania zmian w Profilu Singla w oznaczonych elementach. 
</p>
</li></li></li></li></li></li></li></ol>
</ol>
<p>
<br/>
</p>
<ol>
<li><p>
Usługi
	płatne świadczone za pośrednictwem Platformy mają
	na celu umożliwienie Użytkownikom nawiązania
	znajomości z innymi Użytkownikami na skutek doboru
	przeprowadzonego przez Usługodawcę w ramach zakupionej
	przez Użytkownika Usługi, wedle informacji podanych przez
	Użytkowników,
	jeżeli informacje te oraz wybrany przez Użytkownika Pakiet
	pozwalają na przedstawienie Dopasowania.</p>
</li></ol>
<ol start="2">
<li><p>
Poszczególne
	usługi w Pakiecie Basic nie są świadczone w sposób
	zautomatyzowany, co powoduje, że ich wykonanie wymaga
	odpowiedniego nakładu czasu i pracy przez Usługodawcę,
	celem wykonania usług z należytą starannością.
	Usługodawca nie jest zobowiązany do przedstawienia
	określonej liczby Dopasowań w określonym czasie.</p>
<li><p>
Usługodawca
	informuje, że podstawową cechą Pakietu Basic w
	odniesieniu do Dopasowań jest możliwość
	prezentowania Profilu publicznego Użytkownikom korzystającym
	z Pakietu Plus i Pakietu Premium i przedstawianie Użytkownikowi
	Pakietu Basic Dopasowania jedynie wtedy, gdy takie zostanie
	przygotowane na podstawie dostępnych informacji o Użytkownikach
	i na zasadach określonych w Regulaminie oraz wtedy, gdy
	Dopasowanie zostanie zaakceptowane przez drugiego z Użytkowników
	korzystającego z Pakietu Plus lub Pakietu Premium. Oznacza to,
	że Użytkownik w Pakiecie Basic może nie otrzymać
	propozycji Dopasowania jeśli inni Użytkownicy nie
	zaakceptują jego Profilu publicznego w propozycji Dopasowania. 
</p>
<li><p>
W
	celu skorzystania z Pakietu Basic Użytkownik po zalogowaniu się
	na Konto:</p>
</li></li></li></ol>
<p>
<br/>
</p>
<ol>
<ol>
<li><p>
wybiera
		Formularz zgłoszeniowy, 
</p>
<li><p>
wypełnia
		Formularz zgłoszeniowy i dołącza zdjęcia
		zgodnie z ust. 9 poniżej,</p>
<li><p>
zatwierdza
		Formularz zgłoszeniowy poprzez naciśnięcie przycisku
		„Zatwierdzam odpowiedzi”,</p>
<li><p>
akceptuje
		Regulamin i politykę prywatności poprzez zaznaczenie
		odpowiedniego okna pod
		Formularzem zamówienia
		Usługi Pakiet Basic,</p>
<li><p>
w
		celu potwierdzenia dokonania zamówienia
		Usługi Pakiet Basic naciska przycisk „Zamawiam i płacę”,</p>
<li><p>
dokonuje
		płatności za Pakiet Basic z wykorzystaniem dostępnych
		na Platformie metod płatności.</p>
</li></li></li></li></li></li></ol>
</ol>
<p>
<br/>
</p>
<ol start="2">
<li><p>
Użytkownik,
	który
	nie zgadza się na przekazanie informacji lub danych określonych
	w Formularzu zgłoszeniowym powinien zrezygnować z dalszego
	wypełniania i przesyłania Formularza zgłoszeniowego.</p>
<li><p>
Umowa
	o świadczenie Usługi „Pakiet
	Basic”
	zostaje zawarta z
	chwilą potwierdzenia przez Użytkownika dokonania
	zamówienia
	Usługi Pakiet Basic poprzez naciśnięcie przycisku
	„Zamawiam i płacę”, o
	czym Usługodawca informuje Użytkownika przesyłając
	wiadomość na
	Email Użytkownika.
	
</p>
<li><p>
Po
	zakupie Usługi „Pakiet
	Basic”
	Użytkownik jest uprawniony do korzystania z funkcjonalności
	Platformy dostępnych w tym Pakiecie przez okres 12 miesięcy
	od dnia zawarcia umowy określonego w ust. 7 powyżej.
Po
	upływie 12 miesięcy od dnia zawarcia umowy i wygaśnięcia
	dostępu do funkcjonalności w ramach Pakietu Basic
	Usługodawca
	przechowuje Konto i zapisane na nim dane wyłącznie dla
	celów
	archiwizacyjnych, nie prezentując Profilu publicznego
	Użytkownika innym Użytkownikom, na
	zasadach określonych w Polityce prywatności.</p>
<li><p>
Formularz
	zgłoszeniowy może zostać wysłany przez
	Użytkownika Usługodawcy dopiero po prawidłowym
	wypełnieniu, które
	oznacza:</p>
</li></li></li></li></ol>
<p>
<br/>
</p>
<ol>
<ol>
<li><p>
udzielenie
		odpowiedzi na pytania oznaczone jako wymagane,</p>
<li><p>
wybranie
		maksymalnie trzech lokalizacji, z których
		Użytkownik deklaruje chęć poznania innych
		Użytkowników,</p>
<li><p>
dołączenie
		do Formularza zdjęcia portretowego i zdjęcia
		całej
		sylwetki według wymogów
		określonych w ust. 11 poniżej. 
</p>
</li></li></li></ol>
</ol>
<p>
<br/>
</p>
<ol start="6">
<li><p>
Zdjęcia
	dołączone
	do Formularza zgłoszeniowego służą weryfikacji
	prawdziwości
	tożsamości
	Użytkownika,
	a dodatkowo zdjęcie portretowe w przypadku poprawnego
	wypełnienia Formularza zgłoszeniowego zostaje umieszczone
	przez Usługodawcę w Profilu Singla. Zdjęcie sylwetki
	nie jest przez Usługodawcę prezentowane innym
	Użytkownikom. 
</p>
<li><p>
Po
	stworzeniu Profilu Singla Użytkownik może samodzielnie
	dokonywać zmiany zdjęcia wyświetlanego w Profilu. 
</p>
<li><p>
Zdjęcia
	załączone do Formularza zgłoszeniowego lub następnie
	wprowadzone przez Użytkownika w Profilu muszą spełniać
	następujące warunki:</p>
</li></li></li></ol>
<p>
<br/>
</p>
<ol>
<ol>
<li><p>
być
		aktualne,</p>
<li><p>
przedstawiać
		Użytkownika bez obecności osób
		trzecich,</p>
<li><p>
nie
		zawierać żadnych filtrów
		ani nakładek,</p>
<li><p>
być
		wyraźne,</p>
<li><p>
być
		zapisane w pliku .jpg lub .png o wielkości do 1 MB,</p>
<li><p>
nie
		prezentować nagości ani wulgarnych gestów
		lub wyrazów
		twarzy,</p>
<li><p>
nie
		prezentować treści niedozwolonych, o których
		mowa w §2 Regulaminu.</p>
</li></li></li></li></li></li></li></ol>
</ol>
<p>
<br/>
</p>
<ol start="9">
<li><p>
Weryfikacja
	Formularza zgłoszeniowego Użytkownika polega na
	wprowadzeniu zapisanego i zatwierdzonego przez Użytkownika
	Formularza zgłoszeniowego do bazy Użytkowników
	i przeprowadzenia oceny Formularza zgłoszeniowego pod kątem
	zgodności z Regulaminem. Usługodawca jest uprawniony do
	zwrócenia
	się do
	Użytkownika
	z uwagami do wprowadzonych do Formularza zgłoszeniowego treści.
	W przypadku braku uzupełnienia przez Użytkownika
	Formularza zgłoszeniowego zgodnie z uwagami Usługodawcy,
	Usługodawca może wstrzymać się ze świadczeniem
	dalszych usług w Pakiecie Basic do czasu podania przez
	Użytkownika prawidłowych i zgodnych z Regulaminem
	informacji. 
</p>
<li><p>
Skorzystanie
	przez Użytkownika z możliwości przesłania
	Usługodawcy wypełnionego Formularza zgłoszeniowego
	możliwe jest dopiero w przypadku prawidłowo wypełnionego
	Formularza zgłoszeniowego poprzez udzielenie odpowiedzi na
	wymagane pytania oraz załączenia zdjęć, zgodnie
	z wymogami Regulaminu. 
</p>
<li><p>
Usługodawca
	jest zobowiązany do stworzenia Profilu Singla wyłącznie
	w przypadku prawidłowego wypełnienia go przez Użytkownika
	poprzez udzielenie odpowiedzi na wymagane pytania oraz załączenie
	zdjęć, zgodnie z wymogami Regulaminu. Wysłanie
	Formularza zgłoszeniowego i dokonanie Opłaty członkowskiej
	nie skutkuje obowiązkiem stworzenia Profilu Singla w sytuacji,
	w której
	Formularz zgłoszeniowy został wypełniony przez
	Użytkownika niezgodnie z wymogami Regulaminu, w szczególności
	zawiera treści niedopuszczalne. Usługodawca
	nie zwraca Opłaty członkowskiej, jeżeli brak
	stworzenia Profilu Singla wynika z działania lub zaniechania
	Użytkownika w zakresie nieprawidłowości dotyczących
	wypełnienia Formularza zgłoszeniowego, gdy Usługodawca
	dokonał weryfikacji Formularza zgłoszeniowego i stwierdził
	niezgodność podanych informacji z wymogami określonymi
	w Regulaminie.</p>
<li><p>
Usługę
	„Pakiet
	Basic”
	uważa się za dostarczoną w chwili, gdy Usługodawca
	stworzył i umieścił w bazie Użytkowników
	Profil Singla danego Użytkownika. O stworzeniu Profilu Singla
	Usługodawca informuje niezwłocznie Użytkownika
	umieszczając powiadomienie w panelu Konta i wysyłając
	wiadomość na
	Email Użytkownika
	wraz z zaprezentowaniem Profilu publicznego. Po
	stworzeniu Profilu Singla Użytkownik może zwrócić
	się do
	Usługodawcy
	o wprowadzenie zmian do Profilu lub Profilu publicznego, który
	wprowadza zmiany na życzenie Użytkownika o ile spełniają
	one wymogi takie, jak dla prawidłowo wypełnionego
	Formularza zgłoszeniowego.</p>
<li><p>
Usługodawca
	powiadamia Użytkownika Pakietu Basic poprzez wysłanie
	powiadomienia w Serwisie i przez wybrany przez Użytkownika
	kanał komunikacji przy założeniu Konta o
	przedstawieniu Użytkownika w ramach propozycji Dopasowania
	innemu Użytkownikowi korzystającemu z Pakietu Plus lub
	Pakietu Premium.</p>
<li><p>
Zawierając
	umowę świadczenia usług w ramach Pakietu Basic i
	akceptując Regulamin Użytkownik wyraża zgodę na
	otrzymywanie wiadomości od innych Użytkowników
	w ramach zaakceptowanego przez obu Użytkowników
	Dopasowania. Każdy Użytkownik jest uprawniony do wysłania
	wiadomości do innego Użytkownika w ramach zaakceptowanego
	przez obu Użytkowników
	Dopasowania. Wiadomości nie mogą naruszać zasad
	określonych w Regulaminie, powszechnie obowiązujących
	przepisów
	prawa, zasad współżycia społecznego i dobrych
	obyczajów.
	W każdej chwili Użytkownik jest uprawniony do usunięcia
	korespondencji prowadzonej z innym Użytkownikiem poprzez
	włączenie funkcji dostępnej z poziomu czatu „Zakończ
	rozmowę”. 
</p>
<li><p>
Usunięcie
	historii korespondencji następuje z chwilą usunięcia
	Konta przez Użytkownika. Usługodawca nie świadczy
	usługi przechowywania korespondencji. Usługodawca może
	przechowywać w formie zaszyfrowanego zapisu historię
	korespondencji dla celu obrony przed lub dochodzenia ewentualnych
	roszczeń, przez okres ich przedawnienia plus 6 miesięcy.</p>
</li></li></li></li></li></li></li></ol>
<ol start="5">
<li><p>
Dopasowania
	dla Użytkowników
	korzystających z Pakietu Basic polegają na:</p>
</li></ol>
<p>
<br/>
</p>
<ol>
<ol>
<li><p>
Prezentowaniu
		Profilu publicznego innym Użytkownikom korzystającym z
		Pakietu Plus lub Pakietu Premium w celu przeprowadzenia doboru,</p>
<li><p>
prezentowaniu
		Użytkownikowi Dopasowania w sytuacji, gdy zostało ono
		wcześniej zaakceptowane przez dobranego w ramach Dopasowania
		Użytkownika korzystającego z Pakietu Plus lub Pakietu
		Premium; Po zaprezentowaniu Dopasowania Użytkownik może
		je zaakceptować lub odrzucić. W przypadku zaakceptowania
		Dopasowania dopasowani Użytkownicy mogą prowadzić
		komunikację za pośrednictwem dostępnego na
		Platformie czatu.</p>
</li></li></ol>
</ol>
<p>
<br/>
</p>
<ol start="16">
<li><p>
Użytkownik
	korzystający z Pakietu Basic może podwyższyć go
	do Pakietu Plus lub Premium. W takim przypadku należy wybrać
	na Platformie Pakiet Plus lub Pakiet Premium i przejść
	cały proces zakupu usługi w ramach wybranego Pakietu. W
	przypadku zmiany Pakietu na wyższy w trakcie trwania okresu
	realizacji Usługi opłata dokonana za Pakiet Basic nie
	podlega zwrotowi. W przypadku zakupu Pakietu Plus lub Pakietu
	Premium w chwili, w której
	czas trwania Pakietu Plus lub Premium przekroczy czas trwania już
	zakupionego Pakietu Basic, przedłużeniu ulega Pakiet Basic
	o czas trwania Pakietu Plus lub Pakietu Premium, bez dodatkowych
	opłat.</p>
<li><p>
Usługodawca
	jest uprawniony do dokonania zmiany Usługi, która
	nie jest niezbędna do zachowania jej zgodności z umową
	w sytuacji, gdy może to usprawnić świadczenie tej
	Usługi. Zmiana nie wiąże się z żadnymi
	kosztami po stronie Użytkownika. O wprowadzeniu ewentualnej
	zmiany decyduje Usługodawca, o czym informuje w sposób
	jasny i zrozumiały przesyłając wiadomość na
	Email Użytkownika.</p>
<li><p>
W
	Pakiecie Basic Użytkownik nie może zawiesić Konta. 
</p>
<li><p>
Użytkownik
	jest uprawniony do wypowiedzenia umowy w Pakiecie Basic przed
	okresem, na jaki umowa została zawarta, ze skutkiem na ostatni
	dzień obowiązywania zakupionego Pakietu Basic.
	Wypowiedzenie umowy zostaje dokonane poprzez usunięcie
	Konta.</p>
</li></li></li></li></ol>
<p>
<br/>
</p>
<p>
<h3>§7. USŁUGA „PAKIET PLUS”</h3></p>
<p>
<br/>
</p>
<ol>
<li><p>
W
	ramach Usługi „Pakiet Plus” Usługodawca
	zapewnia wszystkie elementy zawarte w Pakiecie Basic i opisane w §
	6 Regulaminu oraz dodatkowo:</p>
<ol>
<li><p>
udział
		Użytkownika w zaawansowanym, spersonalizowanym procesie
		poszukiwania w celu przedstawienia propozycji Dopasowania,</p>
<li><p>
przeprowadzenie
		przez Usługodawcę procesu poszukiwania i doboru
		odpowiadającego informacjom zawartym w Profilu Singla w celu
		przedstawienia propozycji Dopasowania,</p>
<li><p>
nielimitowana
		prezentacja Dopasowań wybranych przez Usługodawcę
		innym Użytkownikom korzystającym z Pakietu Basic, Pakietu
		Plus i Pakietu Premium.</p>
</li></li></li></ol>
</li></ol>
<p>
<br/>
</p>
<ol start="2">
<li><p>
Poszczególne
	usługi w Pakiecie Plus nie są świadczone w sposób
	zautomatyzowany, co powoduje, że ich wykonanie wymaga
	odpowiedniego nakładu czasu i pracy przez Usługodawcę,
	celem wykonania usług z należytą starannością.
	
</p>
<li><p>
W
	celu skorzystania z Pakietu Plus Użytkownik po zalogowaniu się
	na Konto z aktywnym Pakietem Basic:</p>
</li></li></ol>
<p>
<br/>
</p>
<ol>
<ol>
<li><p>
wybiera
		Pakiet Plus,</p>
<li><p>
akceptuje
		Regulamin i politykę prywatności poprzez zaznaczenie
		odpowiedniego okna pod Formularzem zamówienia
		Usługi Pakiet Plus,</p>
<li><p>
w
		celu potwierdzenia dokonania zamówienia
		Usługi Pakiet Plus naciska przycisk „Zamawiam i płacę”,</p>
<li><p>
dokonuje
		płatności za Pakiet Plus z wykorzystaniem dostępnych
		na Platformie metod płatności.</p>
</li></li></li></li></ol>
</ol>
<p>
<br/>
</p>
<ol start="4">
<li><p>
Użytkownik,
	który
	nie zgadza się na przekazanie informacji lub danych określonych
	w Formularzu zgłoszeniowym powinien zrezygnować z dalszego
	wypełniania i przesyłania Formularza zgłoszeniowego.</p>
<li><p>
Przed
	zamówieniem
	Usługi Pakiet Plus Użytkownik zobowiązany
	jest do zapoznania się
	z treścią Regulaminu i w
	przypadku jego akceptacji musi potwierdzić pod Formularzem
	zamówienia
	akceptację Regulaminu. Zaznaczenie
	rubryki z oznaczeniem akceptacji Regulaminu i potwierdzenie
	zamówienia
	Usługi Pakiet Plus poprzez
	naciśnięcie przycisku „Zamawiam i płacę”
	stanowi
	zawarcie umowy świadczenia Usługi Pakiet Plus na
	Platformie. Brak akceptacji Regulaminu uniemożliwia zawarcie
	Umowy świadczenia usług na Platformie.</p>
<li><p>
Dopasowania
	dla Użytkowników
	korzystających z Pakietu Plus polegają na:</p>
</li></li></li></ol>
<p>
<br/>
</p>
<ol>
<ol>
<li><p>
Prezentowaniu
		Profilu publicznego innym Użytkownikom korzystającym z
		Pakietu Plus lub Pakietu Premium w celu przeprowadzenia doboru,</p>
<li><p>
prezentowaniu
		Użytkownikowi Dopasowania do akceptacji; po zaprezentowaniu
		Dopasowania Użytkownik może je zaakceptować lub
		odrzucić. W sytuacji, gdy Użytkownik korzystający z
		Pakietu Plus zaakceptuje Dopasowanie propozycja Dopasowania zostaje
		zaprezentowana innemu Użytkownikowi; pierwszeństwo
		akceptacji Dopasowania ma Użytkownik korzystający z
		Pakietu Plus lub Pakietu Premium wobec Użytkownika
		korzystającego odpowiednio z niższego Pakietu: Plus lub
		Basic; W przypadku gdy obaj Użytkownicy korzystają z
		Pakietu Plus widzą swoje Dopasowanie jednocześnie; W
		przypadku gdy obaj Użytkownicy korzystają z Pakietu
		Premium widzą swoje Dopasowanie jednocześnie; W przypadku
		zaakceptowania Dopasowania dopasowani Użytkownicy mogą
		prowadzić komunikację za pośrednictwem dostępnego
		na Platformie czatu. 
</p>
</li></li></ol>
</ol>
<p>
<br/>
</p>
<ol start="7">
<li><p>
Usługodawca
	zastrzega, że w ramach Usługi Pakiet Plus Usługodawca
	nie zobowiązuje się do zaproponowania określonej
	liczby Dopasowań w określonym terminie, gdyż
	specyfika tej Usługi w tym zakresie polega na umożliwieniu
	Użytkownikowi pozostawania w bazie Użytkowników
	Biura Randkowego w celu przeprowadzenia Dopasowania. 
</p>
<li><p>
Usługodawca
	jest uprawniony do dokonania zmiany Usługi, która
	nie jest niezbędna do zachowania jej zgodności z umową
	w sytuacji, gdy może to usprawnić świadczenie tej
	Usługi. Zmiana nie wiąże się z żadnymi
	kosztami po stronie Użytkownika. O wprowadzeniu ewentualnej
	zmiany decyduje Usługodawca, o czym informuje w sposób
	jasny i zrozumiały przesyłając wiadomość na
	Email Użytkownika.</p>
<li><p>
Umowa
	o świadczenie Usługi „Pakiet Plus” zostaje
	zawarta z chwilą potwierdzenia
	przez Użytkownika dokonania zamówienia
	Usługi Pakiet Plus poprzez naciśnięcie przycisku
	„Zamawiam i płacę”, o
	czym Usługodawca informuje Użytkownika przesyłając
	wiadomość na
	Email Użytkownika.
	
</p>
<li><p>
Po
	zakupie Usługi „Pakiet Plus” Użytkownik jest
	uprawniony do korzystania z funkcjonalności Platformy
	dostępnych w tym Pakiecie przez okres 6 (sześciu) miesięcy
	od dnia zawarcia umowy określonego w ust. 9 powyżej. 
</p>
<li><p>
Usługę
	„Pakiet Plus” uważa się za dostarczoną w
	chwili, gdy Użytkownik uzyskał dostęp do Platformy po
	potwierdzeniu zamówienia
	Pakietu Plus. O rozpoczęciu świadczenia Usług w
	ramach Pakietu Plus Usługodawca informuje Użytkownika
	potwierdzając dokonanie zamówienia
	Pakietu Plus wysyłając wiadomość na
	Email Użytkownika.
	
</p>
<li><p>
W
	przypadku gdy w okresie trwania dostępu
	do funkcjonalności
	wybranego
	Pakietu Plus zakończy się okres dostępu
	do funkcjonalności
	Pakietu
	Basic dla danego Użytkownika Użytkownik może nadal, w
	terminie do ostatniego dnia dostępu
	do funkcjonalności
	Pakietu
	Plus przedłużyć okres jego trwania dokonując
	zakupu tej Usługi za pośrednictwem Platformy.</p>
<li><p>
Użytkownik
	korzystający z Pakietu Plus może podwyższyć go
	do Pakietu Premium na zasadach określonych w §8. W takim
	przypadku należy wybrać na Platformie Pakiet Premium i
	przejść cały proces zakupu usługi w ramach
	Pakietu Premium. W przypadku zmiany Pakietu na wyższy w trakcie
	trwania okresu realizacji Usługi opłata dokonana za Pakiet
	Plus nie podlega zwrotowi. W przypadku zakupu Pakietu Premium w
	chwili, w której
	czas trwania Pakietu Plus przekroczy czas trwania już
	zakupionego Pakietu Plus, przedłużeniu ulega Pakiet Plus o
	czas trwania Pakietu Premium, bez dodatkowych opłat. W
	zależności od zaawansowania wykonania Umowy w Pakiecie
	Plus, cena za podwyższenie Pakietu Premium zostanie
	proporcjonalnie zmniejszona.</p>
<li><p>
W
	Pakiecie Plus Użytkownik nie może zawiesić Konta.</p>
<li><p>
Użytkownik
	jest uprawniony do wypowiedzenia umowy w Pakiecie Plus przed
	okresem, na jaki umowa została zawarta, ze skutkiem na ostatni
	dzień obowiązywania zakupionego Pakietu Plus.
	Wypowiedzenie umowy zostaje dokonane poprzez usunięcie
	Konta.</p>
</li></li></li></li></li></li></li></li></li></ol>
<p>
<br/>
</p>
<p>
<h3>§8. USŁUGA „PAKIET PREMIUM”</h3></p>
<p>
<br/>
</p>
<ol>
<li><p>
Usługa
	„Pakiet Premium” realizowana jest przez Usługodawcę
	na wyraźne żądanie Użytkownika wyrażone w
	bezpośredniej komunikacji z Usługodawcą za
	pośrednictwem adresu email określonego w §1 ust.3
	Regulaminu i tylko w przypadku potwierdzenia przez Usługodawcę
	możliwości
	świadczenia
	Usługi w Pakiecie Premium. Liczba osób,
	dla których
	Usługodawca może świadczyć Usługę
	Dopasowania w Pakiecie Premium jest ograniczona i uzależniona
	od możliwości
	Usługodawcy.
	
</p>
<li><p>
W
	ramach Usługi „Pakiet Premium” Usługodawca
	zapewnia wszystkie elementy zawarte w Pakiecie Plus i opisane w §7
	Regulaminu oraz dodatkowo:</p>
</li></li></ol>
<p>
<br/>
</p>
<ol>
<ol>
<li><p>
indywidualną
		opiekę i współpracę z Barbarą Strójwąs
		w zakresie poszukiwania Dopasowania,</p>
<li><p>
możliwość
		wzięcia udziału w 3 (trzech) trwających po 45
		(czterdzieści
		pięć)
		minut spotkań online rozpoczynających współpracę
		indywidualną z Barbarą Strójwąs,</p>
<li><p>
możliwość
		wzięcia udziału w 2 (dwóch)
		trwających po 45 (czterdzieści
		pięć)
		minut spotkań online w trakcie współpracy, tj. po
		stworzeniu Profilu Singla i Profilu publicznego,</p>
<li><p>
nielimitowana
		prezentacja Dopasowań wybranych przez Usługodawcę
		innym Użytkownikom korzystającym z Pakietu Basic, Pakietu
		Plus i Pakietu Premium,</p>
<li><p>
pierwszeństwo
		w akceptacji przedstawianych Dopasowań,</p>
<li><p>
udzielanie
		Usługodawcy informacji zwrotnej za pomocą przeznaczonego
		w tym celu formularza, po każdej prezentacji Profilu
		publicznego dopasowanej osoby,</p>
<li><p>
możliwość
		zawieszenia aktywności
		łącznie
		na 3 (trzy) miesiące w okresie trwania zakupionego Pakietu
		Premium; zawieszenie może trwać co najmniej 1 miesiąc.
		
</p>
</li></li></li></li></li></li></li></ol>
</ol>
<p>
<br/>
</p>
<ol start="3">
<li><p>
Dostępność
	Pakietu Premium jest ograniczona, a skorzystanie z niego wymaga
	skontaktowania się z Usługodawcą na adres
	email:kontakt@biurorandkowe.pl. Zakup Pakietu Premium nie odbywa się
	bezpośrednio przez mechanizmy płatności dostępne
	na Platformie a z użyciem danych do płatności
	przesłanych przez Usługodawcę w bezpośredniej
	komunikacji z Użytkownikiem; oznaczony na Platformie jako
	„Pakiet Premium”. Szczegółowe warunki
	świadczenia Usługi Pakiet Premium zostaną określone
	indywidualnie przez Usługodawcę i Użytkownika w
	drodze uzgodnień.</p>
<li><p>
Dopasowania
	dla Użytkowników
	korzystających z Pakietu Premium polegają na:</p>
</li></li></ol>
<p>
<br/>
</p>
<ol>
<ol>
<li><p>
publikowaniu
		Profilu publicznego innym Użytkownikom korzystającym z
		Pakietu Plus lub Pakietu Premium w celu przeprowadzenia doboru,</p>
<li><p>
prezentowaniu
		Użytkownikowi Dopasowania do akceptacji; po zaprezentowaniu
		Dopasowania Użytkownik może je zaakceptować lub
		odrzucić. W sytuacji, gdy Użytkownik korzystający z
		Pakietu Premium zaakceptuje Dopasowanie propozycja Dopasowania
		zostaje zaprezentowana innemu Użytkownikowi; pierwszeństwo
		akceptacji Dopasowania ma Użytkownik korzystający z
		Pakietu Premium; W przypadku zaakceptowania Dopasowania dopasowani
		Użytkownicy mogą prowadzić komunikację za
		pośrednictwem dostępnego na Platformie czatu.</p>
</li></li></ol>
</ol>
<p>
<br/>
</p>
<p>
<h3>§9. USŁUGA NEWSLETTERA</h3></p>
<p>
<br/>
</p>
<ol>
<li><p>
Za
	pośrednictwem Platformy Usługodawca świadczy usługę
	Newslettera, polegającą na wysyłaniu Użytkownikom,
	którzy
	zapisali się do Newslettera informacji na temat usług
	świadczonych
	na Platformie i nowości na Platformie.</p>
<li><p>
Usługa
	Newslettera jest nieodpłatna i dostępna dla wszystkich
	Użytkowników.
	
</p>
<li><p>
Wypowiedzenie
	Umowy Newslettera następuje poprzez wypisanie się przez
	Użytkownika z Newslettera wysyłając wiadomość
	email na adres Usługodawcy lub poprzez kliknięcie
	odpowiedniego przycisku w Newsletterze.</p>
</li></li></li></ol>
<p>
<br/>
</p>
<p>
<h3>§10. PŁATNOŚCI</h3>

<br/>
</p>
<ol>
<li><p>
Ceny
	Usług prezentowane są na Platformie w opisie każdej
	Usługi w Cenniku. 
</p>
<li><p>
Usługodawca
	jest uprawniony do zmiany ceny Usługi. W takim przypadku zmiana
	ceny w wybranym przez Użytkownika Pakiecie nie ulega zmianie w
	okresie rozliczeniowym, za który
	Użytkownik
	uiścił zapłatę. 
</p>
<li><p>
Płatności
	za Usługi są dokonywane za pośrednictwem Platformy
	przez systemy obsługi płatności, za pomocą metod
	dostępnych na Platformie w chwili dokonywania płatności.
	
</p>
<li><p>
Po
	otrzymaniu płatności
	Usługodawca
	wystawia fakturę i przesyła ją na podany przez
	Użytkownika adres e-mail. 
</p>
<li><p>
Usługodawca
	jest uprawniony do stosowania promocji, w tym obniżenia ceny
	lub wydłużenia okresu świadczenia Usługi.</p>
</li></li></li></li></li></ol>
<p>
<br/>
</p>
<p>
<h3>§11. ODSTĄPIENIE OD UMOWY </h3>
</p>
<p>
<br/>
</p>
<ol>
<li><p>
Użytkownikowi
	przysługuje prawo odstąpienia od umowy bez podania
	przyczyny, w terminie 14 dni od dnia jej zawarcia. W tym celu
	przesyła Usługodawcy wiadomość zawierającą
	oświadczenie o odstąpieniu od umowy na adres email
	określony w §1 ust. 3 Regulaminu. Usługodawca
	potwierdza otrzymanie oświadczenia o odstąpieniu od umowy,
	niezwłocznie po jej otrzymaniu. Do
	zachowania terminu na odstąpienie od umowy wystarczające
	jest wysłanie oświadczenia o odstąpieniu przed
	upływem terminu.</p>
<li><p>
Prawo
	odstąpienia od umowy nie przysługuje Użytkownikowi w
	odniesieniu do Usługi, która
	została w pełni wykonana w przypadku, w którym
	Użytkownik uprzednio
	wyraził
	zgodę na rozpoczęcie świadczenia usługi przed
	upływem terminu do odstąpienia od umowy. W
	takiej sytuacji w szczególności
	Użytkownik
	nie jest uprawniony do odstąpienia od umowy w Pakiecie Basic po
	zatwierdzeniu Profilu Singla przez Usługodawcę.</p>
<li><p>
W
	przypadku odstąpienia od Umowy w trybie określonym w
	ust. 2 (po uprzednim wyrażeniu zgody na rozpoczęcie
	spełnienia świadczenia przed upływem terminu na
	odstąpienie od umowy) Usługodawca
	zwróci
	dokonane płatności na rzecz Użytkownika w terminie 14
	dni od otrzymania oświadczenia o odstąpieniu od umowy, w
	wysokości pomniejszonej proporcjonalnie o wartość
	spełnionego - do dnia odstąpienia - świadczenia w
	wybranym przez Użytkownika Pakiecie. 
</p>
<li><p>
Zwrot
	płatności zostanie dokonany tym samym środkiem,
	jakiego Użytkownik użył do
	opłacenia
	Usługi. 
</p>
<li><p>
W
	przypadku odstąpienia od umowy o dostarczanie Usługi,
	Użytkownik
	jest zobowiązany zaprzestać korzystania z tej Usługi
	i udostępniania jakichkolwiek jej elementów
	osobom trzecim.</p>
<li><p>
Jeżeli
	Usługodawca
	nie dostarczył Usługi,
	Użytkownik
	ma prawo do wezwania go do jej wykonania. Usługodawca jest
	zobowiązany do wykonania Usługi niezwłocznie lub w
	terminie uzgodnionym przez strony, za wyjątkiem zaproponowania
	Dopasowania. Wezwanie do wykonania Usługi poprzez
	zaproponowanie Dopasowania nie wywołuje skutku w postaci
	przyspieszenia zaproponowania Dopasowania, chyba że z
	okoliczności wynika, że brak zaproponowania Dopasowania
	wynika z zaniedbania Usługodawcy.</p>
<li><p>
Użytkownik
	w każdej chwili jest uprawniony do usunięcia Konta poprzez
	wybranie w panelu Konta odpowiedniej funkcji. Usunięcie Konta
	skutkuje usunięciem wszystkich danych i informacji zapisanych
	na Koncie, w tym w Profilu Singla i Profilu publicznym oraz treści
	korespondencji z Użytkownikami, w sposób
	trwały i nieodwracalny i nie powoduje zwrotu dokonanych opłat.</p>
</li></li></li></li></li></li></li></ol>
<p>
<br/>
</p>
<p>
<h3>§12. ODPOWIEDZIALNOŚĆ USŁUGODAWCY</h3></p>
<p>
<br/>
</p>
<ol>
<li><p>
Usługodawca
	zobowiązuje się dochować należytej
	staranności przy świadczeniu usług za pośrednictwem
	Platformy i jest odpowiedzialny za niewykonanie lub nienależyte
	wykonanie zobowiązania w zakresie świadczonych usług.
	
</p>
<li><p>
Usługodawca
	nie gwarantuje minimalnej liczby proponowanych Dopasowań.
	Liczba Dopasowań zależy od czynników
	niezależnych od Usługodawcy, w szczególności
	informacji i zdjęć podanych przez Użytkowników
	w Formularzach
	zgłoszeniowych lub Profilach,
	preferencji
	Użytkownika,
	preferencji innych Użytkowników
	w danej lokalizacji oraz liczby Użytkowników
	korzystających z Platformy w obrębie danej lokalizacji.</p>
<li><p>
Usługodawca
	nie ponosi odpowiedzialności za czynniki od niego niezależne
	i wynikające z relacji międzyludzkich zachodzących
	pomiędzy Użytkownikami ani za brak satysfakcji Użytkownika
	z liczby Dopasowań lub cech fizycznych, psychicznych i innych
	Użytkownika, który
	został przedstawiony w ramach Dopasowania. Usługodawca
	zastrzega, że proponowane Dopasowania dokonywane są na
	podstawie dostępnej wiedzy psychologicznej i doświadczenia
	zawodowego po analizie dostarczonych przez Użytkownika
	informacji w Profilu lub w inny sposób.
	Usługodawca nie ponosi w szczególności
	odpowiedzialności za zakres, liczbę, charakter lub
	prawdziwość informacji dostarczonych przez Użytkownika
	w celu przeprowadzenia Dopasowania lub
	skorzystania z jakiejkolwiek funkcjonalności
	dostępnej
	na Platformie.</p>
<li><p>
Usługodawca
	nie ponosi odpowiedzialności za skutki nawiązania
	znajomości lub relacji za pośrednictwem Platformy. 
</p>
<li><p>
Usługodawca
	ponosi odpowiedzialność za brak zgodności z umową
	Usługi dostarczanej jednorazowo lub w częściach,
	który
	istniał w chwili jej dostarczenia i ujawnił się w
	ciągu dwóch
	lat od tej chwili. Usługodawca ponosi odpowiedzialność
	za brak zgodności z umową Usługi dostarczanej w
	sposób
	ciągły, który
	wystąpił lub ujawnił się w czasie, w którym
	zgodnie z umową miały być dostarczane. 
</p>
</li></li></li></li></li></ol>
<p>
<br/>
</p>
<p>
<h3>§13. REKLAMACJE DOTYCZĄCE ŚWIADCZENIA USŁUG</h3>

<br/>
</p>
<ol>
<li><p>
W
	przypadku, gdy Usługa jest niezgodna z umową Użytkownik
	może złożyć reklamację drogą
	elektroniczną na adres email wskazany w §1 ust. 3
	Regulaminu, żądając doprowadzenia do jej zgodności
	z umową.</p>
<li><p>
Usługodawca
	zastrzega, że ze względu na specyfikę usług
	świadczonych
	na Platformie reklamacja nie może dotyczyć w szczególności
	niezadowolenia Użytkownika z zaproponowanego Dopasowania lub
	liczby Dopasowań albo innych kwestii związanych z
	nawiązaniem kontaktu z innym Użytkownikiem w ramach
	Dopasowania, gdyż w ramach Usług
	świadczonych
	przez Usługodawcę na Platformie Usługodawca nie
	zobowiązuje się do przedstawienia określonej liczby i
	charakteru Dopasowań lub do doprowadzenia do
	satysfakcjonujących relacji międzyludzkich.</p>
<li><p>
Usługodawca
	może odmówić
	doprowadzenia Usługi do zgodności z umową, jeżeli
	doprowadzenie to jest niemożliwe albo wymagałoby
	nadmiernych kosztów
	dla Usługodawcy. Przy ocenie nadmierności kosztów
	Usługodawca uwzględnia wszelkie okoliczności sprawy,
	w szczególności
	znaczenie braku zgodności usługi z umową oraz wartość
	usługi zgodnej z umową.</p>
<li><p>
Usługodawca
	doprowadza Usługę do zgodności z umową w
	rozsądnym czasie od chwili, w której
	Usługodawca został poinformowany przez Użytkownika o
	braku zgodności z umową i bez nadmiernych niedogodności
	dla Użytkownika, uwzględniając ich charakter oraz
	cel, w jakim są wykorzystywane. Koszty doprowadzenia usługi
	do zgodności z umową ponosi Usługodawca.</p>
<li><p>
W
	przypadku, gdy Usługa jest niezgodna z umową, Użytkownik
	może złożyć oświadczenie o obniżeniu
	ceny albo odstąpieniu od umowy, gdy:</p>
</li></li></li></li></li></ol>
<p>
<br/>
</p>
<p>
1.
doprowadzenie do zgodności
Usługi
z umową jest niemożliwe albo wymaga nadmiernych kosztów,</p>
<p>
2.
Usługodawca nie doprowadził Usługi do zgodności z
umową, o którym
mowa w ust. 3-4 powyżej,</p>
<p>
3.
brak zgodności
Usługi
z umową występuje nadal, mimo że Usługodawca
próbował
doprowadzić Usługę do zgodności z umową,</p>
<p>
4.
brak zgodności
Usługi
z umową jest na tyle istotny, że uzasadnia obniżenie
ceny albo odstąpienie od umowy bez uprzedniego skorzystania z
żądania doprowadzenia Usługi do zgodności z
umową,</p>
<p>
5.
z oświadczenia Usługodawcy lub okoliczności wyraźnie
wynika, że nie doprowadzi on Usługi do zgodności z
umową w rozsądnym czasie lub bez nadmiernych niedogodności
dla Użytkownika.</p>
<p>
<br/>
</p>
<ol start="6">
<li><p>
Użytkownik
	nie może odstąpić od umowy, jeżeli
	Usługa
	jest dostarczana w zamian za zapłatę ceny, a brak
	zgodności
	Usługi
	z umową jest nieistotny. 
</p>
<li><p>
W
	przypadku zasadności
	żądania,
	o którym
	mowa w ust. 5 powyżej Usługodawca dokonuje zwrotu
	płatności za usługę proporcjonalnie do jej
	niezgodności z Umową, uwzględniając niewykonanie
	lub nieprawidłowe wykonanie Usługi. Usługodawca nie
	ponosi odpowiedzialności za okoliczności, które
	ze swej natury nie wiążą się z jego działaniem
	lub zaniechaniem oraz za okoliczności określone wyraźnie
	w Regulaminie jako te, za które
	Usługodawca
	nie ponosi odpowiedzialności.   
</p>
<li><p>
Reklamacja
	powinna zawierać opis przedmiotu reklamacji ze wskazaniem
	zaistniałych wad Usługi oraz dane Użytkownika
	umożliwiające jego zidentyfikowanie i udzielenie
	odpowiedzi na reklamację. 
</p>
<li><p>
Usługodawca
	rozpatruje reklamację w terminie 14 dni od jej otrzymania,
	przesyłając informację o sposobie rozpoznania
	reklamacji na email podany przez Użytkownika w reklamacji, a
	jeśli nie został podany, to na Email Użytkownika.</p>
<li><p>
Użytkownik
	zobowiązany jest do współpracy z Usługodawcą,
	w rozsądnym zakresie i przy zastosowaniu najmniej uciążliwych
	dla siebie środków
	technicznych, w celu ustalenia, czy brak zgodności
	Usługi
	z umową w odpowiednim czasie wynika z cech środowiska
	cyfrowego Użytkownika.</p>
</li></li></li></li></li></ol>
<p>
<br/>
</p>
<p>
<h3>§14. PRAWA WŁASNOŚCI INTELEKTUALNEJ</h3></p>

<br/>

<ol>
<li><p>
Prawa
	do wszystkich materiałów i elementów
	Platformy (tekstowych, graficznych, audiowizualnych, kodów
	źródłowych
	i innych) udostępnianych przez Usługodawcę za
	pośrednictwem Platformy należą do
	Usługodawcy
	i są przedmiotem ochrony prawnej, w szczególności
	przepisów
	ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach
	pokrewnych (t.j. Dz. U. z 2022 r. poz. 2509), Ustawa z dnia 30
	czerwca 2000 r. Prawo własności przemysłowej (t.j.
	Dz. U. z 2023 r. poz. 1170). 
</p>
<li><p>
Użytkownik,
	z chwilą przesłania Usługodawcy zdjęcia w
	Formularzu zgłoszeniowym lub na późniejszym etapie
	w Profilu albo w inny sposób
	udziela Usługodawcy niewyłącznej, nieodpłatnej,
	nieograniczonej czasowo i terytorialnie licencji do korzystania ze
	zdjęcia wyłącznie w celu świadczenia Usługi
	wybranej przez Użytkownika i prezentowania wybranego przez
	Użytkownika zdjęcia na Profilu publicznym. Licencja jest
	nieograniczona czasowo i terytorialnie, a Użytkownik w każdej
	chwili może odwołać licencję, co uniemożliwi
	świadczenie Usługi „Pakiet
	Basic”,
	„Pakiet Plus” oraz „Pakiet Premium”, a także
	prezentowania zdjęcia w Profilu publicznym, w zależności
	od tego, którego
	zdjęcia dotyczy. 
</p>
<li><p>
Użytkownik
	zobowiązuje się do udostępnienia Usługodawcy do
	publikacji na Platformie zdjęć, do których
	dysponuje prawami autorskimi na odpowiedniej podstawie prawnej. 
</p>
<li><p>
Użytkownik
	nie jest uprawniony do wykorzystywania materiałów
	znajdujących się na Platformie w innym celu niż
	wynika to z ich przeznaczenia i charakteru. W szczególności
	Użytkownik
	nie jest uprawniony do swobodnego używania zdjęć,
	opisów,
	znaków
	towarowych lub innych oznaczeń własności
	intelektualnej osób
	trzecich. 
</p>
</li></li></li></li></ol>
<p>
<br/>
</p>
<p>
<h3>§15. PRZETWARZANIE DANYCH OSOBOWYCH</h3></p>
<p>
<br/>
</p>
<ol>
<li><p>
Usługodawca
	jest Administratorem Danych Osobowych przetwarzanych na Platformie.</p>
<li><p>
informacje
	dotyczące przetwarzania danych osobowych znajdują się
	w Polityce prywatności pod adresem:
	www.biurorandkowe.pl/politykaprywatnosci.</p>
</li></li></ol>
<p>
<br/>
</p>
<p>
<h3>§16. POSTANOWIENIA KOŃCOWE</h3></p>
<ol>
<li><p>
Usługodawca
	zastrzega sobie prawo do zmiany niniejszego Regulaminu z
	jednej lub więcej z
	niżej wskazanych ważnych przyczyn:</p>
</li></ol>
<p>
<br/>
</p>
<p>

a)
jeżeli zmiana Regulaminu jest konieczna ze względu na
istniejący w tym zakresie obowiązek prawny, w tym w
zakresie zmiany przepisów
powszechnie obowiązującego prawa,</p>
<p>
b)
w celu wprowadzenia zmian wynikających ze względów
bezpieczeństwa, 
</p>
<p>
c)
wprowadzenia istotnych zmian technicznych lub technologicznych w
funkcjonowaniu Platformy, 
</p>
<p>
d)
wprowadzenia nowych funkcjonalności i usług
świadczonych
na Platformie.</p>
<p>
<br/>
</p>
<ol start="2">
<li><p>
Usługodawca
	może również
	wprowadzać drobne zmiany niewpływające na świadczenie
	usług za pośrednictwem Platformy.</p>
</li></ol>
<ol>
<li><p>
Usługodawca
	może również
	wprowadzać inne zmiany w Regulaminie - nie modyfikują one
	zawartych już umów,
	a obowiązują Użytkowników,
	którzy
	zawierają umowy po wejściu w życie zmian w
	Regulaminie. 
</p>
<li><p>
Użytkownika
	obowiązują postanowienia Regulaminu obowiązujące
	w chwili zawarcia Umowy o świadczenie Usługi. 
</p>
<li><p>
W
	przypadku, gdy Użytkownik nie akceptuje zmian Regulaminu, o
	których
	mowa w ust. 1 powyżej, jeśli
	wpływałyby one na świadczenie Usług na podstawie
	już zawartej z Użytkownikiem umowy, uprawniony
	jest do wypowiedzenia zawartej umowy ze skutkiem natychmiastowym,
	przesyłając w tym celu oświadczenie na adres email
	Usługodawcy, o którym
	mowa w §1 ust. 3 Regulaminu. Jeżeli wypowiedzenie dokonane
	w tym trybie dotyczy usługi, za którą
	Użytkownik dokonał opłaty, jest uprawniony do żądania
	zwrotu wniesionej opłaty, proporcjonalnie do niewykorzystanej
	usługi z uwzględnieniem czasu pozostałego do
	zakończenia świadczenia zakupionej usługi. 
</p>
<li><p>
Niniejszy
	Regulamin jest dostępny na stronach internetowych Platformy
	oraz w siedzibie Usługodawcy. 
</p>
<li><p>
W
	przypadku zaistnienia sporu, po wyczerpaniu procedury reklamacji, o
	której
	mowa w § 13 Regulaminu – jeśli znajduje zastosowanie
	- w związku z zawartą na
	Platformie Umową
	Użytkownik jest uprawniony do polubownego rozwiązania
	zaistniałego sporu w szczególności
	poprzez zwrócenie
	się o pomoc:</p>
</li></li></li></li></li></ol>
<ol>
<ol>
<li><p>
do
		stałego
		polubownego sądu konsumenckiego, o którym
		mowa wUstawie z dnia 15 grudnia 2000 r. o Inspekcji Handlowej (t.j.
		Dz. U. z 2020 r. poz. 1706 z późn. zm.),</p>
</li></ol>
</ol>
<p>

2.
do Inspekcji Handlowej z wnioskiem o przeprowadzenie mediacji.</p>
<ol start="6">
<li><p>

Użytkownik
	jest również
	uprawniony do polubownego rozwiązania zaistniałego sporu,
	po wyczerpaniu procedury reklamacji, o której
	mowa w §13 Regulaminu poprzez skorzystanie w tym celu z
	platformy ODR znajdującej się pod adresem:
	<u><a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a></u>.</p>
<li><p>
W
	sprawach nieuregulowanych niniejszym Regulaminem, zastosowanie mają
	odpowiednie przepisy powszechnie obowiązującego prawa.</p>
</li></li></ol>
</div>
</div>
</div>
        <!-- <OnlyText
            :content="content"
        /> -->
    </section>
    <Footer/>
    
</template>
<script>
import OnlyText from '@/components/landing-03/atoms/OnlyText.vue';
import HeaderComponent from '@/components/landing-03/HeaderComponent.vue';
import Footer from "@/components/landing-03/footer.vue";


export default {
    data() {
        return {
            content: '<h1>Regulamin</h1><h2>Privacy Policy</h2><h3>Privacy Policy</h3>                    <p class="image-and-text__text">                        <strong>BIURO RANDKOWE TO MIEJSCE, KTÓRE STWORZYŁAM Z MYŚLĄ O WSPÓŁCZESNYCH SINGLACH.</strong>                    </p>                    <p class="image-and-text__text">                        Wszystkich, którzy chcieliby poznawać nowe osoby i stworzyć udany związek. Dla osób zmęczonych aplikacjami randkowymi i złotymi radami, takimi jak „Wyjdź do ludzi” lub „obniż wymagania”. Wiem, że to nie rozwiązuje problemu, który ma swoje źródło najczęściej w obecnym stylu życia.                    </p>                    <p class="image-and-text__text">                        Znam mnóstwo mężczyzn i kobiet, którzy mają wiele do zaoferowania, ale zwyczajnie… nie wiedzą o swoim istnieniu. Zmieniam to i poznaję ze sobą osoby, które wiele łączy i warto, żeby się spotkały. Od nich będzie zależeć, co wydarzy się dalej.                    </p>                    <p class="image-and-text__text">                        Moim celem jest to, żeby każdy był tu na chwilę, ale stworzył relację na lata.                    </p> <ul>  <li>Coffee</li>  <li>Tea</li>  <li>Milk</li></ul>',
        }
    },
    components: {
        OnlyText,
        HeaderComponent,
        Footer
    }
}
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";
    @import "@/assets/scss/breakpoints.scss";
    @import "@/assets/scss/containers.scss";
    @import "@/assets/scss/Nunito.css";
    @import "@/assets/scss/global.scss";


    .privacy {
        padding-top: 200px;
        margin-bottom: 130px;
        @include breakpoint-max("mobile") {
            margin-top: 100px;
        }
        :deep &__content {
            p, li {
                font-size: 1.6rem;
                line-height: 2.4rem;
                margin-bottom: 30px;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                strong {
                    font-size: 16px;
                }
                @include breakpoint-max('mobile') {
                    font-size: 14px;
                    line-height: 20px;
    
                }
            }
            h1, h2, h3, h4, h5, h6 {
                font-family: 'Oswald', sans-serif;
                font-weight: 800;
                margin-bottom: 30px;
            }
            h1 {
                font-size: 6rem;
                line-height: 6.7rem;
            }
            h2 {
                font-size: 4rem;
                line-height: 4.7rem;
            }
            h3 {
                font-size: 2.4rem;
                line-height: 3rem;
            }
            h4 {
                font-size: 1.8rem;
                line-height: 2.3rem;
            }
            ul, ol {
            margin-left: 20px;
                li {
                    margin-bottom: 10px;
                }
            }
        }
    }

</style>