export default {
  beforeMount(el) {
    el.addEventListener('input', () => {
      const value = el.value
      // Regex to match emoji characters
      const emojiRegex =
        /([\u2700-\u27BF]|[\u1F600-\u1F64F]|[\u1F680-\u1F6FF]|[\u1F700-\u1F77F]|[\u1F780-\u1F7FF]|[\u1F800-\u1F8FF]|[\u1F900-\u1F9FF]|[\u1FA00-\u1FA6F]|[\u1FA70-\u1FAFF]|[\u2600-\u26FF]|[\u2700-\u27BF]|[\u2B50]|[\u2B55]|[\u23E9-\u23EF]|[\u23F0-\u23F3]|[\u1F004]|[\u1F0CF]|[\u1F9C0])/g
      el.value = value.replace(emojiRegex, '')
    })
  }
}
