<template>
    <div class="check users-verify">
        <div class="check__questionnaire-filter">
            <div class="check__questionnaire-filter-item">
                <input type="radio" id="assigned" name="access" value=1 v-model="access">
                <label :class="{ selected : access == 1 }" for="assigned">Do sprawdzenia</label>
            </div>
            <div class="check__questionnaire-filter-item">
                <input type="radio" id="awaits" name="access" value=0 v-model="access">
                <label :class="{ selected : access == 0 }" for="awaits">Oczekujemy na poprawe</label>
            </div>
        </div>
        <div class="check__filter-sex">
            <div class="check__filter-sex-item">
                <input type="radio" id="women" name="sex" value=0 v-model="check">
                <label :class="{ selected : check == 0 }" for="women">Kobiety</label>
            </div>
            <div class="check__filter-sex-item">
                <input type="radio" id="men" name="sex" value=1 v-model="check">
                <label :class="{ selected : check == 1 }" for="men">Mężczyźni</label>
            </div>
            <div class="check__filter-sex-item">
                <input type="radio" id="unbinary" name="sex" value=2 v-model="check">
                <label :class="{ selected : check == 2 }" for="unbinary">Niebinarni</label>
            </div>
            <div class="check__filter-sex-item">
                <input type="radio" id="all" name="sex" value="all" v-model="check">
                <label :class="{ selected : check == 'all' }" for="all">Wszyscy</label>
            </div>
        </div>
        <div class="loader" v-if="getDone"></div> 
        <AdminUsersView :users="users"/> 

        <router-link v-if="usersCount == 'loading'" class="check__btn-next" :to="`/admin/verify/${Number($route.params.page) + 1}`">
            Następna strona
        </router-link>
        <div v-if="usersCount == 'loading'" class="check__loader pagination-loader">
            <p>Ładowanie paginacji ...</p><div class="loader"></div>
        </div>
        <div v-if="usersCount > numberOfPeopleDisplayed && $route.params.page <= roundUsersPage(usersCount / numberOfPeopleDisplayed)" class="check__buttons">
            <div class="check__buttons-box">
                <router-link v-if="$route.params.page != 1" class="check__btn check-view__btn--red" :to="`/admin/verify/${Number($route.params.page) - 1}`">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" color="#000000"><path d="M15 6L9 12L15 18" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </router-link>
            </div>
            <router-link v-if="$route.params.page != 1" :to="`/admin/verify/1`">1</router-link>
            <p v-if="$route.params.page != 1">...</p>
            <router-link v-if="($route.params.page - 2) > 1" :to="`/admin/verify/${Number($route.params.page) - 2}`">{{ $route.params.page - 2 }}</router-link>
            <router-link v-if="($route.params.page - 1) > 1" :to="`/admin/verify/${Number($route.params.page) - 1}`">{{ $route.params.page - 1 }}</router-link>
            <p class="current-page">{{ $route.params.page }}</p>
            <router-link v-if="(parseInt($route.params.page) + 1) < roundUsersPage(usersCount / numberOfPeopleDisplayed)" :to="`/admin/verify/${Number($route.params.page) + 1}`">{{ parseInt($route.params.page) + 1 }}</router-link>
            <router-link v-if="(parseInt($route.params.page) + 2) < roundUsersPage(usersCount / numberOfPeopleDisplayed)" :to="`/admin/verify/${Number($route.params.page) + 2}`">{{ parseInt($route.params.page) + 2 }}</router-link>
            <p v-if="$route.params.page != roundUsersPage(usersCount / numberOfPeopleDisplayed)">...</p>
            <router-link v-if="$route.params.page != roundUsersPage(usersCount / numberOfPeopleDisplayed)" :to="`/admin/verify/${roundUsersPage(usersCount / numberOfPeopleDisplayed)}`">{{ roundUsersPage(usersCount / numberOfPeopleDisplayed) }}</router-link>
            <div class="check__buttons-box">
                <router-link v-if="$route.params.page < roundUsersPage(usersCount / numberOfPeopleDisplayed)" class="check__btn" :to="`/admin/verify/${Number($route.params.page) + 1}`">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" color="#000000"><path d="M9 6L15 12L9 18" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </router-link>
            </div>
        </div>
        <div class="check__page-input">
            <input placeholder="Numer strony" type="text" v-model="pageInput">
            <button @click="setPage()">Przejdź do strony</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import AdminUsersView from '@/components/AdminUsersView.vue';
import { createRouter, createWebHistory } from 'vue-router';

export default {
    name: 'admin check',
    data(){
        return{
            users: [],
            getDone: false,
            usersCount: 'loading',
            numberOfPeopleDisplayed: 16,
            access: 1,
            check: 'all',
            pageInput: ''
        }
    },
    components: {
        AdminUsersView,
    },
    methods: {
        setPage() {
            if (this.pageInput != '' && parseInt(this.pageInput)) {
                this.$router.push(`/admin/verify/${this.pageInput}`)
                this.pageInput = ''
            }
        },
        roundUsersPage(num) {
            return Math.ceil(num);
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) {
                return parts.pop().split(';').shift();
            }
        },
        getUsers(page){
            this.users = '';
            this.getDone = true

            const authToken = this.getCookie('authToken');
            const config = {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            };
            axios
                .get(`${this.url}api/verify?page=${page}&access=${this.access}&check=${this.check}`, config)
                .then((resp) => {
                    this.users = resp.data;
                    this.getDone = false
                })
                .catch((err) => {
                    console.log(err);
                    this.getDone = false
                });
        },
        async getUsersCount() {
            this.usersCount = 'loading'
            const authToken = this.getCookie('authToken');
            const config = { headers: {'Authorization': `Bearer ${authToken}`} }

            await axios.get(`${this.url}api/verify/count/verify?access=${this.access}&check=${this.check}`, config)
                .then((resp) => {
                    this.usersCount = resp.data.totalUsers
                })
                .catch((err) => {
                    console.log(err)
                });
        },
        setFirstPage() {
            this.$router.push('/admin/verify/1')
        }
    },
    created(){
        this.getUsers(this.$route.params.page)
        this.getUsersCount()
    },
    watch: {
        '$route.params.page': function() {
            this.getUsers(this.$route.params.page)
        },
        'access': function() {
            this.getUsers(this.$route.params.page)
            this.getUsersCount()
            this.setFirstPage()
        },
        'check': function() {
            this.getUsers(this.$route.params.page)
            this.getUsersCount()
            this.setFirstPage()
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/global.scss";
/* HTML: <div class="loader"></div> */

    .pagination-loader {
        display: flex;
        align-items: center;
        column-gap: 30px;
    }
    .check {
        padding-right: 120px;
        padding-bottom: 200px;
        &__questionnaire-filter {
            margin-bottom: 25px;
            display: flex;
            align-items: center;
            gap: 35px;
            flex-wrap: wrap;
            @include breakpoint-max('xs-tablet') {
                gap: 20px;
            }
        }
        &__btn-next {
            display: block;
            width: fit-content;
            margin: 0 auto;
            color: $black;
            font-size: 1.6rem;
            line-height: 2.2rem;
            font-weight: 700;
            padding: 10px;
            text-decoration: none;
            background-color: $bege3;
        }
        &__filter-sex {
            margin-bottom: 25px;
            display: flex;
            align-items: center;
            gap: 35px;
            flex-wrap: wrap;
            @include breakpoint-max('xs-tablet') {
                gap: 20px;
            }
        }
        &__filter-sex-item {
            input {
                display: none;
            }
            label {
                cursor: pointer;
                color: $black;
                font-size: 1.6rem;
                line-height: 2.2rem;
                text-transform: uppercase;
                padding: 4px 6px;
                transform: .3s;
            }
            .selected {
                background-color: $pink;
                color: $white;
            }
        }
        &__page-input {
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 20px;
            input {
                background-color: transparent;
                outline: none;
                border: 1px solid $black;
                padding: 8px 0;
                color: $black;
                font-size: 2rem;
                line-height: 2.2rem;
                font-weight: 700;
                width: 160px;
                text-align: center;
            }
            button {
                padding: 10px;
                background-color: $bege3;
                color: $black;
                font-size: 1.4rem;
                line-height: 2.2rem;
                font-weight: 700;
                cursor: pointer;
            }
        }
        &__questionnaire-filter-item {
            input {
                display: none;
            }
            label {
                cursor: pointer;
                color: $black;
                font-size: 1.6rem;
                line-height: 2.2rem;
                text-transform: uppercase;
                padding: 4px 6px;
                transform: .3s;
            }
            .selected {
                background-color: $pink;
                color: $white;
            }
    }
        @include breakpoint-max("v-large") {
            padding-right: 30px;
        }
        @include breakpoint-max("xs-tablet") {
            padding-left: 30px;
        }
        &__buttons {
            margin-bottom: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 10px;
            p, a {
                color: $black;
                font-size: 2rem;
                line-height: 2.2rem;
                font-weight: 700;
                padding: 10px;
            }
            a {
                text-decoration: none;
                transition: .3s;
                &:hover {
                    color: $pink;
                }
            }
            .current-page {
                color: $pink;
            }
            @include breakpoint-max("mobile") {
                column-gap: 15px;
            }
        }
    &__btn {
        display: block;
        padding: 10px !important;
        background-color: #E1DBD5;
        color: $black;
        font-size: 1.4rem;
        line-height: 2.2rem;
        text-transform: uppercase;
        font-weight: 700;
        text-decoration: none;
        transition: .3s;
        svg {
            display: block;
            path {
                transition: .3s;
            }
        }
        &:hover {
            background-color: $pink; 
            color: $white;
            svg {
                path {
                    stroke: $white;
                }
            }
        }
        @include breakpoint-max("mobile") {
            font-size: 1rem;
            line-height: 1.5rem;
            padding: 6px 8px !important;
        }
    }
    &__buttons-box{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        a {
            text-align: center;
        }
    }
    .pagination-loader {
        display: flex;
        align-items: center;
        column-gap: 30px;
    }
    &__loader {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            color: $black;
            font-size: 1.4rem;
            line-height: 2.2rem;
            text-transform: uppercase;
            font-weight: 700;
            padding: 50px 0;
        }
    }
    }
.loader {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #F2545B;
    --_m: 
        conic-gradient(#0000 10%,#000),
        linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}
</style>