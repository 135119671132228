<template>
    <button :class="buttonClass">{{ text }}</button>
</template>
  
  <script>
export default {
    data() {
        return {
        };
    },
    props: {
        text: {
            type: String,
            required: true,
        },
        isPink: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        buttonClass() {
            return this.isPink ? 'pinkButton' : 'grayButton';
        }
    }
};
</script>

  <style lang="scss" scoped>
  @import "@/assets/scss/variables.scss";
  @import "@/assets/scss/breakpoints.scss";
  @import "@/assets/scss/containers.scss";
  @import "@/assets/scss/Nunito.css";
  @import "@/assets/scss/global.scss";

    button {
        border: none;
        outline: none;
        width: 100%;
        padding: 19px 0;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        border-radius: 36px;
        background-color: $gray;
        font-family: 'NunitoBold';
        transition: .3s;
        &:active {
            transform: scale(.9);
        }
    }
    .pinkButton {
        background-color: $pink;
        color: $white;
    }
    .grayButton {
        background-color: $gray;
        color: $black;
    }

  </style>
  