<template>
    <div class="send-message">
        <div v-if="isPopupOpen" class="popup">
            <div class="popup-content">
                <div class="popup-header">
                    <h2>Otwarte karty</h2>
                    <button @click="closePopup" class="close-button">
                        ZAMKNIJ
                    </button>
                </div>
                <div class="lottery-machine">
                    <div class="lottery-card" :class="{ active: isAnimating }">
                        {{ currentCard }}
                    </div>
                </div>
                <div class="popup-buttons">
                    <button @click="drawCard" :disabled="isAnimating">
                        Losuj kartę
                    </button>
                    <button @click="sendMessage(true)">Wyślij</button>
                </div>
            </div>
        </div>

        <button class="send-message__cards" @click="openPopup">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.906"
                height="25.204"
                viewBox="0 0 25.906 25.204"
            >
                <g
                    id="Group_319"
                    data-name="Group 319"
                    transform="translate(-106.695 -530.634)"
                >
                    <g
                        id="Rectangle_152"
                        data-name="Rectangle 152"
                        transform="translate(106.695 537.003) rotate(-16)"
                        fill="#d13724"
                        stroke="#fff"
                        stroke-width="1"
                    >
                        <rect
                            width="13.063"
                            height="19.594"
                            rx="3"
                            stroke="none"
                        />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="12.063"
                            height="18.594"
                            rx="2.5"
                            fill="none"
                        />
                    </g>
                    <g
                        id="Rectangle_153"
                        data-name="Rectangle 153"
                        transform="translate(120.49 530.634) rotate(22)"
                        fill="#d13724"
                        stroke="#fff"
                        stroke-width="1"
                    >
                        <rect
                            width="13.063"
                            height="19.594"
                            rx="3"
                            stroke="none"
                        />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="12.063"
                            height="18.594"
                            rx="2.5"
                            fill="none"
                        />
                    </g>
                </g>
            </svg>
        </button>
        <textarea
            ref="textarea"
            id="send-message"
            class="send-message__textarea"
            @input="autosize"
            @keyup.enter.prevent="sendMessage(false)"
            v-model="message"
            rows="1"
            placeholder="Wyślij wiadomość..."
        ></textarea>
        <div @click="sendMessage(false)" class="send-message__icon">
            <svg
                class=""
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                viewBox="0 0 256 256"
                xml:space="preserve"
            >
                <defs></defs>
                <g
                    style="
                        stroke: none;
                        stroke-width: 0;
                        stroke-dasharray: none;
                        stroke-linecap: butt;
                        stroke-linejoin: miter;
                        stroke-miterlimit: 10;
                        fill: none;
                        fill-rule: nonzero;
                        opacity: 1;
                    "
                    transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                >
                    <path
                        d="M 89.999 3.075 C 90 3.02 90 2.967 89.999 2.912 c -0.004 -0.134 -0.017 -0.266 -0.038 -0.398 c -0.007 -0.041 -0.009 -0.081 -0.018 -0.122 c -0.034 -0.165 -0.082 -0.327 -0.144 -0.484 c -0.018 -0.046 -0.041 -0.089 -0.061 -0.134 c -0.053 -0.119 -0.113 -0.234 -0.182 -0.346 C 89.528 1.382 89.5 1.336 89.469 1.29 c -0.102 -0.147 -0.212 -0.288 -0.341 -0.417 c -0.13 -0.13 -0.273 -0.241 -0.421 -0.344 c -0.042 -0.029 -0.085 -0.056 -0.129 -0.082 c -0.118 -0.073 -0.239 -0.136 -0.364 -0.191 c -0.039 -0.017 -0.076 -0.037 -0.116 -0.053 c -0.161 -0.063 -0.327 -0.113 -0.497 -0.147 c -0.031 -0.006 -0.063 -0.008 -0.094 -0.014 c -0.142 -0.024 -0.285 -0.038 -0.429 -0.041 C 87.03 0 86.983 0 86.936 0.001 c -0.141 0.003 -0.282 0.017 -0.423 0.041 c -0.035 0.006 -0.069 0.008 -0.104 0.015 c -0.154 0.031 -0.306 0.073 -0.456 0.129 L 1.946 31.709 c -1.124 0.422 -1.888 1.473 -1.943 2.673 c -0.054 1.199 0.612 2.316 1.693 2.838 l 34.455 16.628 l 16.627 34.455 C 53.281 89.344 54.334 90 55.481 90 c 0.046 0 0.091 -0.001 0.137 -0.003 c 1.199 -0.055 2.251 -0.819 2.673 -1.943 L 89.815 4.048 c 0.056 -0.149 0.097 -0.3 0.128 -0.453 c 0.008 -0.041 0.011 -0.081 0.017 -0.122 C 89.982 3.341 89.995 3.208 89.999 3.075 z M 75.086 10.672 L 37.785 47.973 L 10.619 34.864 L 75.086 10.672 z M 55.136 79.381 L 42.027 52.216 l 37.302 -37.302 L 55.136 79.381 z"
                        style="
                            stroke: none;
                            stroke-width: 1;
                            stroke-dasharray: none;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 10;
                            fill: rgb(0, 0, 0);
                            fill-rule: nonzero;
                            opacity: 1;
                        "
                        transform=" matrix(1 0 0 1 0 0) "
                        stroke-linecap="round"
                    />
                </g>
            </svg>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import { defineEmits } from 'vue'

    const cards = [
        'Zrobienie czego stale odkładasz na później?',
        'Wierzysz w przesądy? Jakie?',
        'Z czego jesteś najbardziej dumna/dumny?',
        'Jaki kolor najlepiej odzwierciedla Twoją osobowość?',
        'Jaki zawód wydaje Ci się bardzo ciekawy? Dlaczego?',
        'Co sprawia, że kogoś podziwiasz?',
        'Co zrobiłaś/zrobiłeś niedawno, a powinnaś/powinieneś zrobić to już dawno temu?',
        'Bez jakiej potrawy nie wyobrażasz sobie życia?',
        'Czym zachwyca się (lub co robi) większość znanych Ci osób, a Ty nie pojmujesz, co im się w tym podoba?',
        'Jesteś duszą towarzystwa, czy wolisz przysłuchiwać się rozmowom?',
        'Lubisz rozmawiać z nieznajomymi? Dlaczego?',
        'Jaki jest Twój ulubiony sposób spędzania wakacji?',
        'Jakie jest Twoje określenie na stan, w którym czujesz ogromne zmęczenie?',
        'Gdyby na Ziemi wylądowali kosmici, które miejsce poleciłabyś/poleciłbyś im odwiedzić w pierwszej kolejności?',
        'Gdybyś na pstryknięcie mógł/mogła zmienić coś w swoim wyglądzie, to co by to było?',
        'Czy robiąc zakupy, zawsze chodzisz po sklepie tą samą trasą? Jakie są Twoje inne "dziwne" zwyczaje?',
        'Jaka cecha u ludzi najbardziej Cię denerwuje?',
        'Na co nigdy nie jest Ci żal pieniędzy?',
        'Czego nowego chciałabyś/chciałbyś się nauczyć, ale jakoś nie ma ku temu okazji?',
        'Do której ze swoich fryzur nigdy nie chciałabyś/nie chciałbyś powrócić?',
        'Co Cię przeraża?',
        'Jaka tradycja obchodzona przez ludzi jest dla Ciebie niezrozumiała? Dlaczego?',
        'Które święto najbardziej lubisz obchodzić? Dlaczego akurat to?',
        'Czego nie jesteś w stanie zjeść? Dlaczego?',
        'Jakie wydarzenie z Twojego życia jest najbardziej niezapomniane?',
        'Jaki jest Twój ukryty talent, o którym mało kto wie?',
        'Wierzysz, że nie ma rzeczy niemożliwych? Dlaczego?',
        'Gdybyś miał/miała opisać swój dzień w trzech słowach, to jakie słowa wybierzesz?',
        'Potrafisz ukrywać swoje emocje, czy masz je wypisane na twarzy?',
        'Kiedy ostatnio nauczyłaś/nauczyłeś się czegoś nowego? Co to było?',
        'Kiedy ostatnio pomyślałaś/pomyślałeś, że coś jest NAPRAWDĘ EKSTRA? Co to było?',
        'Jaki jest Twój ulubiony zapach?',
        'Jaką formę spędzania czasu postrzegasz jako męczarnię?',
        'Jak Twoim zdaniem w trzech słowach opisaliby Cię Twoi przyjaciele?',
        'Lubisz rywalizować z innymi? Np. w sporcie lub w pracy?',
        'Jaką głupią rzecz zrobiłaś/zrobiłeś w młodości, której teraz byś nie powtórzyła/powtórzył?',
        'Gdybyście wraz z przyjaciółmi założyli drużynę sportową, jaka nazwa idealnie by do niej pasowała?',
        'Gdybyś mogła/mógł wybrać dla siebie jedną super-moc, to co by to było?',
        'Wolisz przebywanie w samotności, czy wśród ludzi?',
        'Za co najbardziej lubisz swoją pracę?',
        'Kiedy ostatnio czułaś/czułeś się znudzona/znudzony? Dlaczego?',
        'W jakich sytuacjach czujesz, że ktoś zaczyna przekraczać Twoje granice?',
        'Jakie są Twoim zdaniem 3 rzeczy, które mamy wspólne?',
        'Co najbardziej cenisz w swoich przyjaciołach?',
        'Jakie żarty w ogóle Cię nie bawią? Które uważasz za żarty nie na miejscu?',
        'Jaki najgorszy prezent kiedykolwiek dostałaś/dostałeś?',
        'Jakich filmów nie jesteś w stanie obejrzeć do końca?',
        'Potrafisz ufać ludziom, czy hołdujesz zasadzie ograniczonego zaufania?',
        '„Łatwiej powiedzieć niż zrobić". Co Twoim zdaniem jest łatwiejsze w teorii niż w praktyce?',
        'Co byś zrobiła/zrobił, gdybyś wygrał na loterii milion złotych?',
        'Przejmujesz się tym, co inni ludzie o Tobie sądzą? Dlaczego?',
        'Jesteś wytrwała/wytrwały, czy raczej szybko się zniechęcasz?',
        'Kiedy w swoim życiu czułaś/czułeś się najbardziej szczęśliwa/szczęśliwy?',
        'W jaki sposób odreagowujesz trudne wydarzenia?',
        'Co ostatnio sprawiło, że straciłaś/straciłeś poczucie czasu?',
        'Jaką najlepszą radę usłyszałaś/usłyszałeś w życiu? Od kogo?',
        'Co stało się modne w ostatnim czasie, a jednak nie uległaś/uległeś tej modzie?',
        'W jaki sposób pokazujesz, że znajomość z kimś jest dla Ciebie ważna?',
        'Co potrafi wyprowadzić Cię z równowagi w ekspresowym tempie?',
        'Jakiego nawyku najchętniej byś się pozbyła/pozbył, ale przychodzi Ci to z trudem?',
        'Wolisz szalone imprezy czy bardziej spokojne formy spędzania czasu?',
        'Jakie produkty praktycznie zawsze lądują w Twoim koszyku z zakupami?',
        'Czego nigdy nie byłbyś/byłabyś w stanie spróbować? Dlaczego?',
        'Masz skłonność do ryzyka, czy raczej go unikasz?',
        'Czego sądziłaś/sądziłeś, że nigdy nie zrobisz, a jednak…stało się?',
        'W jakim stroju czujesz się jak w przebraniu?',
        'Kiedy ostatnio płakałaś/płakałeś? Dlaczego?',
        'Jakich lekcji nie znosiłaś/znosiłeś w szkole? Dlaczego?',
        'Co w Twoim życiu było największą stratą czasu?',
        'Które miejsce na świecie najbardziej chciałabyś/chciałbyś odwiedzić? Dlaczego akurat to?',
        'Podejmujesz decyzje szybko, czy długo rozważasz wszystkie możliwe opcje?',
        'Jaki jest Twój stosunek do zwierząt domowych? Uważasz, że są jak członkowie rodziny?',
        'O czym mogłabyś/mógłbyś powiedzieć: „Większej bzdury nie słyszałam/słyszałem!"?',
        'Jaką potrawę przyrządzasz po mistrzowsku?',
        'Bez jakiego sprzętu nie wyobrażasz sobie życia?',
        'Jakie największe marzenie udało Ci się spełnić?',
        'Jesteś sentymentalna/sentymentalny?',
        'Jak celebrujesz ważne wydarzenia w Twoim życiu?',
        'Czy są jakieś zajęcia, które uważasz za „typowo damskie" lub „typowo męskie"? Dlaczego?',
        'Czego chciałabyś/chciałbyś doświadczyć w życiu po raz pierwszy jeszcze raz?',
        'Za co najbardziej lubisz miejsce, w którym mieszkasz?',
        'Jaka liczba dzieci to Twoim zdaniem „za dużo dzieci"?',
        'Jaki film lub serial widziałaś/widziałeś kilka razy?',
        'Co Twoim zdaniem nigdy dobrze się nie kończy?',
        'Kiedy ostatnio zdarzyło Ci się zaniemówić? W jakiej sytuacji?',
        'Jakiej przyjemności nie potrafisz sobie odmówić?',
        'Jak radzisz sobie ze stresem?',
        'Wygrałaś/wygrałaś kiedykolwiek w jakimś konkursie? Co to był za konkurs? Co było nagrodą?',
        'W jakim programie telewizyjnym nigdy nie wzięłabyś/nie wziąłbyś udziału? Dlaczego?',
        'Co w Twoim życiu było świetnym pomysłem, a okazało się być najgorszą opcją?',
        'Ile czasu w ciągu dnia poświęcasz na przeglądanie social mediów?',
        'Co najbardziej Cię rozprasza, gdy próbujesz się skupić?',
        'Jaką historię ze swojego życia zdarzyło Ci się opowiadać i zaczynać ją od słów „Nie uwierzysz…"? Opowiedz ją.',
        'Jaką rzecz, której nie można kupić, najbardziej chciałabyś/chciałbyś posiadać?',
        'Jaka jest Twoja ulubiona pora dnia? Dlaczego?',
        'Jaki rodzaj muzyki uważasz za najbardziej irytujący?',
        'Jaka cecha sprawia, że od razu kogoś lubisz?',
        'Jak w trzech słowach określiłabyś/określiłbyś swoje relacje z rodziną?',
        'Co poprawia Ci nastrój w trudnych momentach?',
        'Gdybyś mogła/mógł zmienić 3 rzeczy w swoim życiu, to co by to było? Dlaczego?',
        'Jakiej ważnej, życiowej umiejętności powinniśmy uczyć się w szkole, a tego nie robimy?',
        'W jakiej sytuacji zdarzyło Ci się ostatnio pomyśleć „Świat jest mały!"?',
        'Zgadzasz się z powiedzeniem „Nigdy nie mów nigdy"?',
        'Za co ostatnio musiałaś/musiałeś kogoś przeprosić?',
        'Jaki jest Twój ulubiony sposób komunikacji? Poza spotkaniem na żywo.',
        'Kto ostatnio zepsuł Ci nastrój i dlaczego?',
        'Gdybyś przez cały dzień musiała/musiał mówić tylko prawdę, kogo unikałabyś/unikałbyś jak ognia?',
        'Kiedy ostatnio musiałaś/musiałeś przyznać, że się myliłaś/myliłeś? W jakiej kwestii?',
        'Kiedy ostatnio poprosiłaś/poprosiłeś kogoś o pomoc? W jakiej sytuacji?',
        'Zgadzasz się z twierdzeniem, że "mniej znaczy więcej"?'
    ]

    const currentCard = ref(cards[0])
    const isAnimating = ref(false)
    const isPopupOpen = ref(false)
    const message = ref('')
    const textarea = ref(null)

    const emit = defineEmits(['sendMessage'])

    const drawCard = async () => {
        if (isAnimating.value) return

        isAnimating.value = true

        for (let i = 0; i < 20; i++) {
            currentCard.value = cards[Math.floor(Math.random() * cards.length)]
            await new Promise(resolve => setTimeout(resolve, 100))
        }

        currentCard.value = cards[Math.floor(Math.random() * cards.length)]

        isAnimating.value = false
    }

    const sendMessage = (isCard = false) => {
        if (isCard) {
            emit('sendMessage', currentCard.value, isCard)
            closePopup()
            return
        }

        if (message.value) {
            emit('sendMessage', message.value, isCard)
            message.value = ''
        }
    }

    const autosize = () => {
        if (textarea.value) {
            textarea.value.style.height = 'auto'
            textarea.value.style.height = textarea.value.scrollHeight + 'px'
        }
    }

    const openPopup = () => {
        isPopupOpen.value = true
    }

    const closePopup = () => {
        isPopupOpen.value = false
    }

    onMounted(() => {
        if (textarea.value) {
            textarea.value.addEventListener('input', autosize)
        }
    })
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    #send-message {
        padding: 14px 90px 14px 20px;
        @include breakpoint-min('large') {
            padding: 14px 140px 14px 36px;
        }
    }

    .popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .popup-content {
        background-color: $gray;
        padding: 20px;
        width: 90%;
        max-width: 500px;
        border: 1px solid $black;
    }

    .popup-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .close-button {
        background: none;
        border: none;
        font-size: 16px;
        cursor: pointer;
    }

    .lottery-machine {
        width: 100%;
        height: 150px;
        border: 2px solid #333;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        overflow: hidden;
    }

    .lottery-card {
        width: 90%;
        height: 80%;
        background-color: $white;
        border: 1px solid #ddd;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        transition: transform 0.1s ease-in-out;
    }

    .lottery-card.active {
        animation: shake 0.5s ease-in-out infinite;
    }

    .popup-buttons {
        display: flex;
        justify-content: space-between;
    }

    .popup-buttons button {
        width: 100%;
        max-width: 200px;
        padding: 15px 10px;
        border: 1px solid $black;
        background-color: #e1dbd5;
        color: $black;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 700;
        cursor: pointer;
        &:hover {
            background-color: #d13724;
            color: #fff;
            transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
    }

    .open-popup-button {
        position: absolute;
        right: 10px;
        top: 10px;
        background: none;
        border: none;
        cursor: pointer;
    }

    .send-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        margin: 0px 10px;

        &__popup {
            position: fixed;
            max-width: 600px;
            width: 100%;
            border: 1px solid $black;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $gray;
            z-index: 1000;

            &-header {
                width: 100%;
                background-color: $pink;
                color: $white;
                padding: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                h2 {
                    margin: 0;
                    font-size: 1.6rem;
                }

                button {
                    background: none;
                    border: none;
                    color: $white;
                    cursor: pointer;
                    font-size: 1.4rem;
                }
            }

            &-buttons {
                display: flex;
                justify-content: space-around;
                padding: 20px 0;

                button {
                    cursor: pointer;
                    appearance: none;
                    color: #000;
                    border: 1px solid $black;
                    background-color: #e1dbd5;
                    padding: 15px 0;
                    width: 200px;
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                    font-weight: 700;
                    font-family: 'Open Sans', sans-serif;
                    transition: 0.3s ease;

                    &:hover {
                        background-color: #d13724;
                        color: #fff;
                    }
                }
            }
        }

        &__cards {
            position: absolute;
            z-index: 999;
            right: 5px;
            background-color: $pink;
            padding: 10px;
            border-radius: 50px;
            cursor: pointer;
            border: none;

            &:hover {
                background-color: darken($pink, 5%);
            }
        }

        &__textarea {
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
            overflow: hidden;
            min-height: 40px;
            width: 100%;
            font-size: 16px;
            resize: none;
            display: block;
            border-radius: 50px;
            border: none;
            outline: none;
            background-color: #f4f2ee;
            position: relative;
            line-height: 30px;
            height: 58px !important;

            @include breakpoint-min('v-large') {
                margin: 0px 50px;
            }

            &::placeholder {
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
            }
        }

        &__icon {
            position: absolute;
            right: -44px;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            cursor: pointer;
        }
    }

    @keyframes shake {
        0% {
            transform: translateX(0);
        }
        25% {
            transform: translateX(-5px);
        }
        50% {
            transform: translateX(5px);
        }
        75% {
            transform: translateX(-5px);
        }
        100% {
            transform: translateX(0);
        }
    }
</style>

<!-- <template>
    <div class="send-message">
      <div v-if="isPopupOpen" class="popup">
        <div class="popup-content">
          <div class="popup-header">
            <h2>Otwarte karty</h2>
            <button @click="closePopup" class="close-button">Zamknij</button>
          </div>
          <div class="lottery-machine">
            <div class="lottery-card" :class="{ 'active': isAnimating }">
              {{ currentCard }}
            </div>
          </div>
          <div class="popup-buttons">
            <button @click="drawCard" :disabled="isAnimating">Losuj kartę</button>
            <button @click="sendMessage">Wyślij</button>
          </div>
        </div>
      </div>
  
      <button class="open-popup-button" @click="openPopup">
        <svg xmlns="http://www.w3.org/2000/svg" width="25.906" height="25.204" viewBox="0 0 25.906 25.204">
          <g id="Group_319" data-name="Group 319" transform="translate(-106.695 -530.634)">
            <g id="Rectangle_152" data-name="Rectangle 152" transform="translate(106.695 537.003) rotate(-16)" fill="#d13724" stroke="#fff" stroke-width="1">
              <rect width="13.063" height="19.594" rx="3" stroke="none"/>
              <rect x="0.5" y="0.5" width="12.063" height="18.594" rx="2.5" fill="none"/>
            </g>
            <g id="Rectangle_153" data-name="Rectangle 153" transform="translate(120.49 530.634) rotate(22)" fill="#d13724" stroke="#fff" stroke-width="1">
              <rect width="13.063" height="19.594" rx="3" stroke="none"/>
              <rect x="0.5" y="0.5" width="12.063" height="18.594" rx="2.5" fill="none"/>
            </g>
          </g>
        </svg>
      </button>
      
      <textarea
        ref="textarea"
        v-model="message"
        @input="autosize"
        class="message-textarea"
        placeholder="Wyślij wiadomość..."
      ></textarea>
      
      <button class="send-button" @click="sendMessage">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
          <path d="M89.999 3.075C90 3.02 90 2.967 89.999 2.912c-0.004-0.134-0.017-0.266-0.038-0.398-0.007-0.041-0.009-0.081-0.018-0.122-0.034-0.165-0.082-0.327-0.144-0.484-0.018-0.046-0.041-0.089-0.061-0.134-0.053-0.119-0.113-0.234-0.182-0.346C89.528 1.382 89.5 1.336 89.469 1.29c-0.102-0.147-0.212-0.288-0.341-0.417-0.13-0.13-0.273-0.241-0.421-0.344-0.042-0.029-0.085-0.056-0.129-0.082-0.118-0.073-0.239-0.136-0.364-0.191-0.039-0.017-0.076-0.037-0.116-0.053-0.161-0.063-0.327-0.113-0.497-0.147-0.031-0.006-0.063-0.008-0.094-0.014C87.365 0.018 87.222 0.004 87.078 0.001 87.03 0 86.983 0 86.936 0.001c-0.141 0.003-0.282 0.017-0.423 0.041-0.035 0.006-0.069 0.008-0.104 0.015-0.154 0.031-0.306 0.073-0.456 0.129L1.946 31.709c-1.124 0.422-1.888 1.473-1.943 2.673-0.054 1.199 0.612 2.316 1.693 2.838l34.455 16.628 16.627 34.455C53.281 89.344 54.334 90 55.481 90c0.046 0 0.091-0.001 0.137-0.003 1.199-0.055 2.251-0.819 2.673-1.943L89.815 4.048c0.056-0.149 0.097-0.3 0.128-0.453 0.008-0.041 0.011-0.081 0.017-0.122C89.982 3.341 89.995 3.208 89.999 3.075zM75.086 10.672L37.785 47.973 10.619 34.864 75.086 10.672zM55.136 79.381L42.027 52.216l37.302-37.302L55.136 79.381z" fill="#000000"/>
        </svg>
      </button>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue'
  
  const cards = [
    'Zrobienie czego stale odkładasz na później?',
    'Wierzysz w przesądy? Jakie?',
    'Z czego jesteś najbardziej dumna/dumny?',
    'Jaki kolor najlepiej odzwierciedla Twoją osobowość?',
    'Jaki zawód wydaje Ci się bardzo ciekawy? Dlaczego?',
    // Dodaj więcej pytań tutaj...
  ]
  
  const currentCard = ref(cards[0])
  const isAnimating = ref(false)
  const isPopupOpen = ref(false)
  const message = ref('')
  const textarea = ref(null)
  
  const drawCard = async () => {
    if (isAnimating.value) return
  
    isAnimating.value = true
    
    for (let i = 0; i < 20; i++) {
      currentCard.value = cards[Math.floor(Math.random() * cards.length)]
      await new Promise(resolve => setTimeout(resolve, 100))
    }
  
    currentCard.value = cards[Math.floor(Math.random() * cards.length)]
    
    isAnimating.value = false
  }
  
  const sendMessage = () => {
    if (message.value.trim()) {
      console.log('Wysłana wiadomość:', message.value)
      message.value = ''
      autosize()
    }
  }
  
  const autosize = () => {
    if (textarea.value) {
      textarea.value.style.height = 'auto'
      textarea.value.style.height = textarea.value.scrollHeight + 'px'
    }
  }
  
  const openPopup = () => {
    isPopupOpen.value = true
  }
  
  const closePopup = () => {
    isPopupOpen.value = false
  }
  
  onMounted(() => {
    if (textarea.value) {
      textarea.value.addEventListener('input', autosize)
    }
  })
  </script>
  
  <style scoped>
  .send-message {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
  }
  
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .lottery-machine {
    width: 100%;
    height: 150px;
    border: 2px solid #333;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    overflow: hidden;
  }
  
  .lottery-card {
    width: 90%;
    height: 80%;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    transition: transform 0.1s ease-in-out;
  }
  
  .lottery-card.active {
    animation: shake 0.5s ease-in-out infinite;
  }
  
  .popup-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .popup-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .open-popup-button {
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .message-textarea {
    width: calc(100% - 60px);
    min-height: 40px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 20px;
    resize: none;
    margin-right: 10px;
  }
  
  .send-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: none;
    border: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
  
  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }
  </style> -->
