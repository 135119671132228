
<template>
    <section class="image-and-text">
        <div class="scroll-div" id="o-mnie"></div>
        <div class="image-and-text__wrapper">
            <div class="image-and-text__left">
                <img src="@/assets/images/basia-na-krzesle.png" alt="" class="image-and-text__image-first">
                <img src="@/assets/images/basia-na-krzesle-2.png" alt="" class="image-and-text__image-second">
            </div>
            <div class="image-and-text__right">
                
                <div class="image-and-text__content">
                    <div class="image-and-text__title-box">
                        <svg class="image-and-text__title-mobile" width="88" height="293" viewBox="0 0 88 293" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M87.6613 267.428C87.6613 273.812 86.5388 278.863 84.2938 282.582C82.0488 286.3 78.8215 288.966 74.6121 290.58C70.4027 292.193 65.3864 293 59.5634 293H27.8875C21.9943 293 16.978 292.193 12.8388 290.58C8.69949 288.966 5.54241 286.3 3.36754 282.582C1.12251 278.863 0 273.812 0 267.428C0 260.973 1.12251 255.887 3.36754 252.169C5.54241 248.38 8.69949 245.679 12.8388 244.065C16.978 242.452 21.9943 241.645 27.8875 241.645H59.5634C65.3864 241.645 70.4027 242.452 74.6121 244.065C78.8215 245.679 82.0488 248.38 84.2938 252.169C86.5388 255.887 87.6613 260.973 87.6613 267.428ZM74.2964 267.428C74.2964 265.463 73.8053 264.025 72.8231 263.113C71.8409 262.201 70.5781 261.605 69.0346 261.324C67.4912 261.043 65.9126 260.903 64.299 260.903H23.2571C21.5733 260.903 19.9948 261.043 18.5215 261.324C16.978 261.605 15.7152 262.201 14.733 263.113C13.7508 264.025 13.2597 265.463 13.2597 267.428C13.2597 269.252 13.7508 270.62 14.733 271.532C15.7152 272.444 16.978 273.04 18.5215 273.321C19.9948 273.602 21.5733 273.742 23.2571 273.742H64.299C65.9126 273.742 67.4912 273.637 69.0346 273.426C70.5781 273.146 71.8409 272.584 72.8231 271.742C73.8053 270.83 74.2964 269.392 74.2964 267.428Z" fill="#F4F2EE"/>
                            <path d="M86.3985 203.571L1.15759 202.098V181.892L50.7236 172.527L1.15759 162.424V142.955L86.3985 141.482V154.847L29.6765 156.425L86.3985 166.318V178.209L29.2555 188.733L86.3985 189.996V203.571Z" fill="#F4F2EE"/>
                            <path d="M86.3985 129.159H1.15759V115.899L42.1995 98.4299H1.15759V82.855H86.3985V95.5885L42.1995 113.058H86.3985V129.159Z" fill="#F4F2EE"/>
                            <path d="M86.3985 70.0639H1.15759V51.2268H86.3985V70.0639Z" fill="#F4F2EE"/>
                            <path d="M86.3985 38.5163H1.15759V0.210472H13.9963V19.6791H35.1487V4.84084H48.198V19.6791H73.665V0H86.3985V38.5163Z" fill="#F4F2EE"/>
                        </svg>
                        <div class="image-and-text__placeholder">
                            <img src="@/assets/images/images-bundle.png" alt="" class="image-and-text__title-bundle">
                        </div>
                    </div>
                    <p class="image-and-text__text">
                        <strong>Nazywam się Barbara Strójwąs i pomagam tysiącom osób budować szczęśliwe relacje.</strong>
                    </p>
                    <p class="image-and-text__text">
                        Jestem psychologiem i od lat zajmuję się wszystkim tym, co związane jest z miłością. Pomagam ludziom budować dobre relacje, znajdować miłość i pozbierać się po rozstaniach. Moje publikacje każdego dnia oglądają i czytają w sieci setki tysięcy ludzi.
                    </p>
                    <p class="image-and-text__text">
                        Nie boję się wysokich standardów, bo uważam, że są w życiu ważne. Wraz z moim zespołem spędziłam mnóstwo czasu na tworzeniu biura randkowego. Oferta biura stanowi odpowiedź na najczęściej pojawiające się pytanie: „Gdzie można kogoś poznać?”. Dzisiaj mogę już powiedzieć, że tutaj. Mam nadzieję, że spotkasz tu osobę, której szukasz. Jesteś w dobrych rękach.
                    </p>
                </div>
                <svg class="image-and-text__title-desktop" width="249" height="831" viewBox="0 0 249 831" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M248.576 757.984C248.576 776.063 245.397 790.367 239.04 800.896C232.682 811.425 223.544 818.975 211.624 823.544C199.704 828.113 185.499 830.398 169.01 830.398H79.3118C62.6238 830.398 48.4191 828.113 36.6978 823.544C24.9765 818.975 16.0365 811.425 9.8778 800.896C3.52046 790.367 0.341797 776.063 0.341797 757.984C0.341797 739.707 3.52046 725.303 9.8778 714.774C16.0365 704.046 24.9765 696.397 36.6978 691.828C48.4191 687.259 62.6238 684.974 79.3118 684.974H169.01C185.499 684.974 199.704 687.259 211.624 691.828C223.544 696.397 232.682 704.046 239.04 714.774C245.397 725.303 248.576 739.707 248.576 757.984ZM210.73 757.984C210.73 752.421 209.339 748.349 206.558 745.766C203.776 743.183 200.2 741.495 195.83 740.7C191.459 739.905 186.989 739.508 182.42 739.508H66.1998C61.4318 739.508 56.9618 739.905 52.7898 740.7C48.4191 741.495 44.8431 743.183 42.0618 745.766C39.2805 748.349 37.8898 752.421 37.8898 757.984C37.8898 763.149 39.2805 767.023 42.0618 769.606C44.8431 772.189 48.4191 773.877 52.7898 774.672C56.9618 775.467 61.4318 775.864 66.1998 775.864H182.42C186.989 775.864 191.459 775.566 195.83 774.97C200.2 774.175 203.776 772.586 206.558 770.202C209.339 767.619 210.73 763.547 210.73 757.984Z" fill="#F4F2EE"/>
                    <path d="M245 577.159L3.61979 572.987V515.771L143.978 489.249L3.61979 460.641V405.511L245 401.339V439.185L84.3778 443.655L245 471.667V505.341L83.1858 535.141L245 538.717V577.159Z" fill="#F4F2EE"/>
                    <path d="M245 366.442H3.61979V328.894L119.84 279.426H3.61979V235.322H245V271.38L119.84 320.848H245V366.442Z" fill="#F4F2EE"/>
                    <path d="M245 199.101H3.61979V145.759H245V199.101Z" fill="#F4F2EE"/>
                    <path d="M245 109.766H3.61979V1.29449H39.9758V56.4245H99.8738V14.4065H136.826V56.4245H208.942V0.698486H245V109.766Z" fill="#F4F2EE"/>
                </svg>
            </div>
        </div>
    </section>
</template>
  
  <script>


    export default {
    data() {
        return {
        };
    },
    props: {
        
    },
    components: {

    },
};
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";
    @import "@/assets/scss/breakpoints.scss";
    @import "@/assets/scss/containers.scss";
    @import "@/assets/scss/Nunito.css";
    @import "@/assets/scss/global.scss";


    .image-and-text {
        border-bottom: 1px solid $black;
        
        &__wrapper {
            display: flex;
            flex-direction: row-reverse;
            // height: 100vh;
            min-height: 600px;

            @include breakpoint-max('tablet') {
                flex-direction: column-reverse;
                height: initial;

            }
        }
        &__left {
            flex: 1;
            display: flex;
            justify-content: center;
            column-gap: 20px;
            padding-left: 48px;
            img {
                object-fit: contain;
                width: 50%;
            }
            @include breakpoint-max('tablet') {
                display: none;

            }

        }
        &__content {
            max-width: 439px;
            margin-right: 30px;
            padding: 50px 0;
            margin-left: 143px;
            position: relative;
            z-index: 2;
            color: $black3;
            @include breakpoint-max('m-large') {
                margin-left: 30px;
            }
            @include breakpoint-max('tablet') {
                margin-left: 0;
                padding: 50px 30px;
                margin: 0 auto;
            }   
        }

        &__text {
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin-bottom: 30px;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
            strong {
                font-size: 4.5rem;
                line-height: 5.5rem;
                font-family: 'Promenade', serif;
                font-weight: 400;
                @include breakpoint-max('mobile') {
                    font-size: 35px;
                    line-height: 40px;
                }
            }
            @include breakpoint-max('mobile') {
                font-size: 16px;

            }
        }

        &__right {
            flex: 1;
            border-right: 1px solid $black;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            background-color: $bege3;
            overflow: hidden;

            @include breakpoint-max('tablet') {
                border: none;
                border-top: 1px solid $black;
                max-width: 100%;
                flex: 1;

            }
        }
        &__img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            @include breakpoint-max('tablet') {
                object-position: center 20%;
            }
        }
        &__title-desktop {
            height: 100%;
            @include breakpoint-max('tablet') {
                display: none;
            }
        }
        &__title-mobile {
            margin-bottom: 38px;
        }
        &__title {
            font-size: 80px;
            line-height: 85px;
            font-family: 'Oswald', sans-serif;
            font-weight: 800;
            margin-bottom: 28px;
            color: $begeLight3;
            display: none;
            @include breakpoint-max('xl-tablet') {
                font-size: 48px;
                line-height: 55px;
                display: inline-block;

            }
        }
        &__subtitle {
            font-size: 6.4rem;
            line-height: 7rem;
            font-family: 'adobe-caslon-pro', serif;
            font-weight: 800;
            margin-bottom: 8%;
            @include breakpoint-max('tablet') {
                font-size: 28px;
                line-height: 35px;
            }
        }
        &__images-bundle {
            position: absolute;
            left: 0;
            bottom: 0;
        }
        &__placeholder {
            position: relative;
            margin-left: 20px;
            width: 0;
            max-width: 200px;
        }
        &__title-bundle{
            max-width: 300px;
        }
        &__title-box {
            display: none;
            @include breakpoint-max('tablet') {
                display: flex;
            }
        }
    }

</style>
  