<template>
  <section class="instagram">
        <div class="instagram__wrapper">
            <div class="instagram__title-box">
                <h3 class="instagram__title">Odwiedź mój Instagram</h3>
            </div>
            <div class="instagram__items">
                <a target="_blank" :href="item.link" v-for="item in items" :key="item">
                    <img :src="item.img" alt="">
                </a>
            </div>
            
        </div>
  </section>
</template>

<script>
export default {
    data() {
        return {
           items: [
                { link: 'https://www.instagram.com/trudnasztuka/?hl=pl', img: require('@/assets/images/1.png') },
                { link: 'https://www.instagram.com/trudnasztuka/?hl=pl', img: require('@/assets/images/2.png') },
                { link: 'https://www.instagram.com/trudnasztuka/?hl=pl', img: require('@/assets/images/3.png') },
                { link: 'https://www.instagram.com/trudnasztuka/?hl=pl', img: require('@/assets/images/4.png') },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";
    @import "@/assets/scss/breakpoints.scss";
    @import "@/assets/scss/containers.scss";
    @import "@/assets/scss/global.scss";

    .instagram {
        background-color: #2A2A29;
        padding: 115px 52px 115px 143px;
        @include breakpoint-max('m-large') {
            padding: 115px 30px 115px 30px;
        }
        @include breakpoint-max('xl-tablet') {
                padding: 90px 30px 90px 30px;
            }
         &__title-box {
            flex: 1;
        }
        &__title {
            font-size: 4.4rem;
            line-height: 5.2rem;
            color: #fff;
            font-family: "Promenade", serif;
            font-weight: 400;
            @include breakpoint-max('xl-tablet') {
                margin-bottom: 60px;
                font-size: 44px;
                line-height: 52px;
                text-align: center;
            }
        }
        &__wrapper {
            display: flex;
            align-items: center;
            column-gap: 20px;
            @include breakpoint-max('xl-tablet') {
                flex-direction: column;
            }
        }
        &__items {
            flex: 4;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 15px;
            @include breakpoint-max('xl-tablet') {
                grid-template-columns: repeat(2, 1fr);
            }
            a {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    aspect-ratio: calc(310 / 310);
                    display: block;
                    @include breakpoint-max('xl-tablet') {
                        max-width: 510px;
                    }
                }
            }
        }
    }

</style>