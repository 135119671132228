import { createStore } from 'vuex'

const store = createStore({
    state: {
		user: {
			id: null,
            name: '',
            authToken: null,
            isAuthenticated: false,
		}
	},
	mutations: {
		updateUser(state, payload, authToken) {
			const {
				name,
				_id,
			} = payload
			state.user.name = name;
			state.user.id = _id;
			state.user.authToken = authToken;
		},
		removeUser(state) {
			state.user.name = null;
			state.user.id = null;
			state.user.authToken = null;
		},
        setAuth(state, isAuthenticated) {
            state.user.isAuthenticated = isAuthenticated;
        },
	},
	getters: {
		getUserID(state) {
			return state.user.id;
		}
	},
	actions: {
	},
	modules: {
	}
});

export default store;