<template>
    <div>
        <HeaderComponent />
        <section class="login">
            <div class="container">
                <p class="login__info" v-if="isRegistered">
                    Twoje konto zostało zarejestrowane. Możesz się zalogować.
                </p>
                <h1 class="login__title">LOGOWANIE</h1>
                <div class="login__form">
                    <form @submit.prevent="login">
                        <label for="email">
                            <input
                                id="email"
                                type="email"
                                v-model="email"
                                class="login__form-email"
                            />
                            <span class="login__form-error">
                                {{ feedbackEmail }}
                            </span>
                            <p>E-MAIL:</p>
                        </label>
                        <label for="password">
                            <input
                                id="password"
                                type="password"
                                v-model="password"
                                class="login__form-password"
                            />
                            <svg
                                class="eyePassword"
                                @click="inputTypeChange($event)"
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 -960 960 960"
                                width="24"
                            >
                                <path
                                    d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"
                                />
                            </svg>
                            <span class="login__form-error password-error">
                                {{ feedbackPassword }}
                            </span>
                            <p>HASŁO:</p>
                        </label>
                        <div class="login__form-pass">
                            <p>
                                Zapomniałeś hasła?
                                <router-link to="password-reset">
                                    Zresetuj je
                                </router-link>
                                .
                            </p>
                        </div>
                        <div class="login__form-error-login">
                            <p>{{ feedback }}</p>
                        </div>
                        <div class="login__wrapper-bottom">
                            <button v-if="!loaderAnimation">Zaloguj się</button>
                            <button disabled v-else class="loader">
                                <svg viewBox="25 25 50 50">
                                    <circle r="20" cy="50" cx="50"></circle>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
        <Footer />
    </div>
</template>
<script>
    import axios from 'axios'
    import HeaderComponent from '@/components/landing-03/HeaderComponent.vue'
    import Footer from '@/components/landing-03/footer.vue'
    import Input from '@/components/atoms/Input.vue'
    import Button from '@/components/atoms/Button.vue'

    import { useUserStore } from '@/stores/user'
    import { useTemporaryMatchStore } from '@/stores/temporaryMatch'

    export default {
        setup() {
            const store = useUserStore()
            const temporaryMatchStore = useTemporaryMatchStore()
            return { store, temporaryMatchStore }
        },

        data() {
            return {
                email: '',
                password: '',
                feedback: '',
                feedbackEmail: '',
                feedbackPassword: '',
                emailKey: 0,
                passwordKey: 0,
                popup: false,
                error: false,
                isRegistered: false,
                loaderAnimation: false
            }
        },
        components: {
            Input,
            Button,
            HeaderComponent,
            Footer
        },
        methods: {
            login() {
                this.loaderAnimation = true
                const isEmailValid = this.checkEmailValid()
                const isPasswdValid = this.checkPasswdValid()

                this.feedback = ''

                if (isEmailValid && isPasswdValid) {
                    axios
                        .post(
                            `${this.url}api/login`,
                            {
                                email: this.email,
                                password: this.password
                            },
                            {
                                withCredentials: true
                            }
                        )
                        .then(res => {
                            this.loaderAnimation = false

                            if (res.data.error) {
                                this.feedback =
                                    'Login lub hasło są niepoprawne.'
                                this.loading = false
                            } else {
                                const authToken = res.data.authToken
                                window.localStorage.setItem(
                                    'userId',
                                    res.data.user._id
                                )
                                window.localStorage.setItem(
                                    'isAdmin',
                                    res.data.user.role === 'admin' ? 1 : 0
                                )

                                this.setAuthCookie(authToken)

                                if (res.data.user.role == 'admin') {
                                    this.$router.push('/admin/users/Warszawa/1')
                                    return
                                } else {
                                    axios
                                        .post(
                                            `${this.url}api/login/check-questionnaire/${res.data.user._id}`
                                        )
                                        .then(res => {
                                            this.$router.push(
                                                '/panel/notification'
                                            )
                                        })
                                        .catch(err => {
                                            this.$router.push('/zapisz-sie')
                                        })
                                    return
                                }
                            }
                        })
                        .catch(err => {
                            this.loaderAnimation = false
                            if (err) {
                                this.feedback =
                                    err.response?.data?.error ||
                                    'Wystąpił błąd serwera, skontaktuj się z administratorem'
                                this.loading = false
                            }
                        })
                }
            },
            setAuthCookie(token) {
                const expirationDate = new Date()
                expirationDate.setDate(expirationDate.getDate() + 7)
                document.cookie = `authToken=${token}; expires=${expirationDate.toUTCString()}; path=/`
            },
            checkLoggedIn() {
                const authToken = this.getCookie('authToken')
                if (authToken) {
                    this.$router.push(`/`)
                }
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            checkEmailValid() {
                if (this.email === '' || this.email === null) {
                    this.feedbackEmail = 'Email jest wymagany.'
                    this.loaderAnimation = false
                    return false
                } else {
                    this.feedbackEmail = ''
                    return true
                }
            },
            checkPasswdValid() {
                if (this.password === '' || this.password === null) {
                    this.feedbackPassword = 'Hasło jest wymagane.'
                    this.loaderAnimation = false
                    return false
                } else {
                    this.feedbackPassword = ''
                    return true
                }
            },
            inputTypeChange(e) {
                e.target.closest('svg').previousSibling.type == 'text'
                    ? (e.target.closest('svg').previousSibling.type =
                          'password')
                    : (e.target.closest('svg').previousSibling.type = 'text')
            }
        },
        created() {
            this.checkLoggedIn()
        },
        mounted() {
            if (localStorage.getItem('registered') === 'ok') {
                this.isRegistered = true
                localStorage.removeItem('registered')
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .login {
        padding: 170px 0 86px;
        background-color: $bege3;
        min-height: 70vh;
        @include breakpoint-max('mobile') {
            padding-top: 90px;
        }
        .password-error {
            right: 60px;
        }
        &__title {
            width: 100%;
            font-family: 'Oswald', sans-serif;
            font-size: 55px;
            line-height: 6rem;
            font-weight: 700;
            margin-bottom: 77px;
            color: $black;
            text-align: center;
            @include breakpoint-max('xs-tablet') {
                font-size: 55px;
                margin-bottom: 40px;
            }
            @include breakpoint-max('mobile') {
                font-size: 55px;
                line-height: 6rem;
            }
            @include breakpoint-max('s-mobile') {
                font-size: 6rem;
            }
        }
        &__form {
            display: flex;
            flex-direction: column;
            max-width: 1075px;
            margin: 0 auto;
            input {
                padding: 27px 25px;
                color: $black;
                font-size: 16px;
                line-height: 30px;
                border-radius: 0;
                font-family: 'Open Sans', sans-serif;
                outline: none;
                border: 1px solid $black;
                background-color: transparent;
                width: 100%;
                @include breakpoint-max('xs-tablet') {
                    padding: 17px 15px;
                }
                @include breakpoint-max('mobile') {
                    padding-top: 53px;
                    padding-left: 25px !important;
                }
            }
            label {
                display: block;
                position: relative;
                p {
                    font-weight: 700;
                    color: $black;
                    font-size: 16px;
                    line-height: 30px;
                    font-family: 'Open Sans', sans-serif;
                    position: absolute;
                    top: 51%;
                    transform: translateY(-50%);
                    left: 27px;
                    @include breakpoint-max('mobile') {
                        top: 20px;
                        transform: translateY(0%);
                        left: 28px;
                    }
                }
                .eyePassword {
                    position: absolute;
                    top: 50%;
                    right: 25px;
                    transform: translateY(-50%);
                    cursor: pointer;
                    @include breakpoint-max('mobile') {
                        top: 23px;
                        transform: translate(0);
                    }
                }
                #email,
                #password {
                    padding-left: 100px;
                }
                &:nth-child(1) {
                    input {
                        border-bottom: none;
                    }
                }
            }
        }
        &__form-error {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 27px;
            font-weight: 700;
            color: $pink;
            font-size: 1.3rem;
            line-height: 3rem;
            font-family: 'Open Sans', sans-serif;
            @include breakpoint-max('mobile') {
                top: 35%;
            }
        }
        &__form-error-login {
            margin-top: 30px;
            p {
                font-weight: 700;
                color: $pink;
                font-size: 1.6rem;
                line-height: 3rem;
                font-family: 'Open Sans', sans-serif;
                text-align: center;
            }
        }
        &__form-pass {
            margin-top: 30px;
            p {
                font-weight: 700;
                font-size: 1.6rem;
                line-height: 3rem;
                font-family: 'Open Sans', sans-serif;
                text-align: center;
                a {
                    color: #000;
                }
            }
        }
        &__wrapper-bottom {
            display: flex;
            justify-content: center;
            text-align: center;
            margin-top: 60px;
            button {
                outline: none;
                font-size: 2.4rem;
                background-color: $pink;
                line-height: 3rem;
                color: $white;
                text-transform: uppercase;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                padding: 10px 40px;
                cursor: pointer;
            }
        }
        .loader {
            width: 227px;
            display: grid;
            place-items: center;
            svg {
                width: 24px;
                transform-origin: center;
                animation: rotate4 2s linear infinite;
            }

            circle {
                fill: none;
                stroke: $white;
                stroke-width: 2;
                stroke-dasharray: 1, 200;
                stroke-dashoffset: 0;
                stroke-linecap: round;
                animation: dash4 1.5s ease-in-out infinite;
            }

            @keyframes rotate4 {
                100% {
                    transform: rotate(360deg);
                }
            }

            @keyframes dash4 {
                0% {
                    stroke-dasharray: 1, 200;
                    stroke-dashoffset: 0;
                }

                50% {
                    stroke-dasharray: 90, 200;
                    stroke-dashoffset: -35px;
                }

                100% {
                    stroke-dashoffset: -125px;
                }
            }
        }
        &__info {
            text-align: center;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            margin-bottom: 50px;
            font-size: 2rem;
            line-height: 2.2rem;
            color: $blackRGBA;
        }
    }
</style>
