<template>
  <section class="pros-cons">
    <div class="scroll-div" id="cennik"></div>
    <div class="container-header">
      <div class="first">
        <h2 class="pros-cons__title">CENNIK</h2>
        <h3 class="pros-cons__subtitle">KROK 1</h3>
        <h4 class="pros-cons__list-title">PAKIET BASIC 500 zł</h4>
        <ul class="pros-cons__wrapper">
          <li v-for="item in items" :key="item" class="pros-cons__item">
            <div class="pros-cons__item-line">
              <div class="pros-cons__item-title">
                <p>{{ item.title }}</p>
                <span v-if="item.text">{{ item.text }}</span>
              </div>
              <div class="pros-cons__second-col-sign">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.267"
                  height="48.434"
                  viewBox="0 0 38.267 48.434"
                >
                  <path
                    id="Path_157"
                    data-name="Path 157"
                    d="M-3110.574,10316.744s3.836-18.917,15.763-17.157-1.86,26.956-6.026,33.515-7.528,14.589-12.2,14.8-21.51-30.97-12.862-41.176S-3110.574,10316.744-3110.574,10316.744Z"
                    transform="translate(3128.328 -10299.473)"
                    fill="#d13724"
                  />
                </svg>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="second">
        <h3 class="pros-cons__subtitle">KROK 2</h3>
        <h4>
          PAKIET PLUS
          <br />
          Tylko dla posiadaczy konta BASIC
        </h4>
        <h5>
          W PAKIECIE PLUS ZAWARTE SĄ
          <br />
          WSZYSTKIE OPCJE PAKIETU BASIC
          <br />
          I DODATKOWO:
        </h5>
        <ul class="pros-cons__wrapper">
          <li v-for="item in items2" :key="item" class="pros-cons__item">
            <div class="pros-cons__item-line">
              <div class="pros-cons__item-title">
                <p>{{ item.title }}</p>
                <span v-if="item.text">{{ item.text }}</span>
              </div>
              <div class="pros-cons__second-col-sign">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.267"
                  height="48.434"
                  viewBox="0 0 38.267 48.434"
                >
                  <path
                    id="Path_157"
                    data-name="Path 157"
                    d="M-3110.574,10316.744s3.836-18.917,15.763-17.157-1.86,26.956-6.026,33.515-7.528,14.589-12.2,14.8-21.51-30.97-12.862-41.176S-3110.574,10316.744-3110.574,10316.744Z"
                    transform="translate(3128.328 -10299.473)"
                    fill="#d13724"
                  />
                </svg>
              </div>
            </div>
          </li>
        </ul>
        <h5>MOŻLIWE OPCJE PŁATNOŚCI:</h5>
        <div class="pros-cons__payments">
          <div v-for="el in payments" class="pros-cons__payments-item">
            <p>{{ el.title }}</p>
            <span>{{ el.text }}</span>
            <div class="pros-cons__discount" v-if="el.promo">
              {{ el.promo }}
            </div>
            <div class="pros-cons__bottom">
              (Cena regularna: 199 zł / miesiąc)
            </div>
          </div>
        </div>
      </div>
      <div class="third">
        <h3 class="pros-cons__subtitle">KROK 3</h3>
        <h4 class="pros-cons__list-title">
          PAKIET PREMIUM
          <br />
          4500 zł/3 miesiące
        </h4>
        <ul class="pros-cons__wrapper">
          <li v-for="item in items3" :key="item" class="pros-cons__item">
            <div class="pros-cons__item-line">
              <div class="pros-cons__item-title">
                <p>{{ item.title }}</p>
                <span v-if="item.text">{{ item.text }}</span>
              </div>
              <div class="pros-cons__second-col-sign">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.267"
                  height="48.434"
                  viewBox="0 0 38.267 48.434"
                >
                  <path
                    id="Path_157"
                    data-name="Path 157"
                    d="M-3110.574,10316.744s3.836-18.917,15.763-17.157-1.86,26.956-6.026,33.515-7.528,14.589-12.2,14.8-21.51-30.97-12.862-41.176S-3110.574,10316.744-3110.574,10316.744Z"
                    transform="translate(3128.328 -10299.473)"
                    fill="#d13724"
                  />
                </svg>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!-- <div class="pros-cons__button-box">
                <SimpleButton
                    text="Zapisz się"
                    route="/register"
                    :is-on-black=false
                />
            </div> -->
    </div>
  </section>
</template>

<script>
  import SimpleButton from '@/components/landing-03/atoms/SimpleButton.vue'

  export default {
    data() {
      return {
        items: [
          {
            title: 'Rejestracja konta użytkownika w Biurze Randkowym'
          },
          {
            title: 'Członkostwo w Biurze Randkowym przez 12 miesięcy'
          },
          {
            title: 'Weryfikacja kwestionariusza zgłoszeniowego'
          },
          {
            title: 'Sprawdzenie zgodności i spójności przesłanych informacji'
          },
          {
            title: 'Publikacja wizytówki w bazie Biura Randkowego',
            text: '(Wizytówka jest prezentowana dopasowanej osobie i zawiera ograniczoną liczbę informacji o użytkowniku)'
          },
          {
            title: 'Stworzenie rozbudowanego profilu singla',
            text: 'Profil singla jest tworzony na potrzeby doboru, widoczny tylko dla specjalisty.'
          },
          {
            title:
              'Nielimitowana prezentacja profilu osobom korzystającym z pakietów Plus i Premium'
          },
          {
            title: 'Możliwość umówienia się na randkę z przedstawioną osobą'
          },
          {
            title: 'Dodatkowe funkcje',
            text: '(możliwość korzystania z funkcji czatu i jego dodatkowych opcji w przypadku pozytywnego dopasowania)'
          },
          {
            title: 'Możliwość wprowadzania zmian',
            text: '(Możliwość wprowadzania przez użytkownika zmian w niektórych elementach jego profilu)'
          },
          {
            title: 'Możliwość podwyższenia pakietu do wersji PLUS'
          }
        ],
        items2: [
          {
            title:
              'Udział w zaawansowanym, spersonalizowanym procesie poszukiwania'
          },
          {
            title:
              'Dobór przez konsultanta odpowiadających profilowi singla dopasowań'
          },
          {
            title:
              'Nielimitowana prezentacja dopasowań wybranych przez konsultanta innym osobom korzystającym z pakietu BASIC, PLUS i PREMIUM'
          }
        ],
        items3: [
          {
            title: 'Indywidualna opieka i współpraca z Barbarą Strójwąs'
          },
          {
            title:
              '3x45min spotkanie rozpoczynające współpracę, oraz 2 spotkania w trakcie współpracy'
          },
          {
            title: 'Nielimitowana prezentacja'
          },
          {
            title: 'Pierwszeństwo w akceptacji przedstawianych dopasowań'
          },
          {
            title:
              'Udzielanie informacji zwrotnej po każdej prezentacji dopasowanej osoby'
          },
          {
            title: 'Możliwość zawieszenia aktywności na 3 miesiące'
          }
        ],
        payments: [
          {
            title: '99 zł / miesiąc',
            text: 'Płatność z góry za 6 miesięcy',
            promo: '-50%'
          },
          {
            title: '139 zł / miesiąc',
            text: 'Płatność z góry za 3 miesiące',
            promo: '-30%'
          }
        ]
      }
    },
    components: {
      SimpleButton
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variables.scss';
  @import '@/assets/scss/breakpoints.scss';
  @import '@/assets/scss/containers.scss';
  @import '@/assets/scss/Nunito.css';
  @import '@/assets/scss/global.scss';

  .container-header {
    @include breakpoint-max('mobile') {
      padding-right: 0;
    }
  }
  .first {
    padding-bottom: 147px;
    @include breakpoint-max('xl-tablet') {
      padding-bottom: 80px;
    }
    @include breakpoint-max('mobile') {
      padding-bottom: 76px;
    }
  }
  .second {
    padding-bottom: 141px;
    .pros-cons__subtitle {
      margin-bottom: 6px;
      @include breakpoint-max('mobile') {
        margin-bottom: 35px;
      }
    }
    h4 {
      font-size: 4.5rem;
      line-height: 4.4rem;
      color: $black;
      font-weight: 400;
      font-family: 'Promenade', serif;
      margin-bottom: 37px;
      text-align: center;
      @include breakpoint-max('mobile') {
        font-size: 45px;
        line-height: 44px;
        padding-right: 30px;
      }
    }
    h5 {
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: $black;
      font-weight: 700;
      margin-bottom: 68px;
      text-align: center;
      font-family: 'Open sans', sans-serif;
      @include breakpoint-max('mobile') {
        font-size: 24px;
        line-height: 28px;
        padding-right: 30px;
        max-width: 240px;
        margin: 0 auto;
        margin-bottom: 43px;
      }
    }
    ul {
      margin-bottom: 56px;
      @include breakpoint-max('mobile') {
        margin-bottom: 43px;
      }
    }
    @include breakpoint-max('xl-tablet') {
      padding-bottom: 80px;
    }
    @include breakpoint-max('mobile') {
      padding-bottom: 76px;
    }
  }
  .third {
    padding-bottom: 161px;
    .pros-cons__list-title {
      max-width: 310px;
      @include breakpoint-max('mobile') {
        padding-right: 30px;
        font-size: 45px;
        line-height: 44px;
        max-width: 1000px;
        margin-bottom: 55px;
      }
    }
    @include breakpoint-max('xl-tablet') {
      padding-bottom: 80px;
    }
    @include breakpoint-max('mobile') {
      padding-bottom: 95px;
    }
  }
  .pros-cons {
    overflow: hidden;
    background-color: #f4f2ee;
    padding-top: 86px;
    &__bottom {
      margin-top: 10px;
    }
    @include breakpoint-max('xl-tablet') {
      padding-top: 36px;
    }
    &__discount {
      position: absolute;
      top: -24px;
      right: -15px;
      background-color: $pink;
      color: #fff;
      font-size: 2.2rem;
      padding: 10px;
      transform: rotate(8deg);
      font-weight: 700;
    }
    &__wrapper {
      list-style: none;
    }
    &__item {
      padding: 25px 0;
      border-bottom: 1px solid $black;
      &:first-child {
        border-top: 1px solid $black;
      }
      @include breakpoint-max('mobile') {
        padding: 17px 0;
        padding-right: 30px;
      }
    }
    &__title {
      margin-left: -4px;
      font-size: 12rem;
      line-height: 17rem;
      text-transform: uppercase;
      color: $black3;
      font-weight: 600;
      margin-bottom: 94px;
      font-family: 'Oswald', sans-serif;
      @include breakpoint-max('xl-tablet') {
        margin-bottom: 40px;
      }
      @include breakpoint-max('mobile') {
        font-size: 100px;
        line-height: 140px;
        margin-bottom: 28px;
      }
    }
    &__subtitle {
      font-size: 10rem;
      line-height: 14rem;
      text-transform: uppercase;
      color: $black3;
      font-weight: 600;
      font-family: 'Oswald', sans-serif;
      margin-bottom: 50px;
      position: relative;
      width: fit-content;
      margin-bottom: 23px;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 121%;
        width: 1500px;
        height: 11px;
        background-color: $black;
      }
      @include breakpoint-max('mobile') {
        font-size: 60px;
        line-height: 90px;
        margin-bottom: 37px;
      }
    }
    &__list-title {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 260px;
      font-size: 4.5rem;
      line-height: 4.4rem;
      color: $black;
      font-weight: 400;
      text-align: center;
      font-family: 'Promenade', serif;
      margin-bottom: 32px;
      @include breakpoint-max('mobile') {
        font-size: 45px;
        line-height: 44px;
        margin-bottom: 29px;
        max-width: 240px;
        padding-right: 30px;
      }
    }
    &__empty {
      flex: 2;
    }
    &__col-title {
      flex: 1;
      text-align: center;
      font-size: 2rem;
      line-height: 3rem;
      color: $black3;
      font-weight: 700;
      p {
        color: lighten($black3, 20%);
        font-size: 1.8rem;
        line-height: 3.5rem;
        font-weight: 400;
        font-family: 'Open sans', sans-serif;
      }
      @include breakpoint-max('mobile') {
        font-size: 12px;
        line-height: 15px;
      }
    }
    &__col-title-mob {
      display: none !important;
      font-size: 12px;
      line-height: 18px;
      @include breakpoint-max('mobile') {
        display: inline !important;
      }
    }
    &__item-line {
      display: flex;
      text-align: center;
      column-gap: 430px;
      font-size: 2.4rem;
      line-height: 3rem;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      @include breakpoint-max('xl-tablet') {
        column-gap: 100px;
      }
      @include breakpoint-max('mobile') {
        column-gap: 29px;
      }
    }
    &__item-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 17px;
      width: 100%;
      text-align: left;
      font-size: 2rem;
      line-height: 3rem;
      p {
        font-size: 2.4rem;
        line-height: 3rem;
        color: $black;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        @include breakpoint-max('mobile') {
          font-size: 16px;
          line-height: 20px;
        }
      }
      span {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: $black;
        font-weight: 400;
        @include breakpoint-max('mobile') {
          font-size: 16px;
          line-height: 20px;
        }
      }
      @include breakpoint-max('mobile') {
        font-size: 18px;
        line-height: 25px;
        row-gap: 10px;
      }
    }
    &__first-col-sign,
    &__second-col-sign {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        min-width: 38px;
        @include breakpoint-max('xs-tablet') {
          min-width: 25px;
          width: 25px;
          height: 32px;
        }
      }
    }
    &__text {
      font-family: 'Open Sans', sans-serif;
      margin-top: 5px;
      max-width: 496px;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 500;
      @include breakpoint-max('mobile') {
        font-size: 16px;
        line-height: 25px;
      }
    }
    &__button-box {
      display: flex;
      justify-content: center;
      padding-top: 62px;
      border: 1px solid $white;
      @include breakpoint-max('mobile') {
        padding-top: 44px;
      }
      :deep .simple-button {
        font-size: 2.4rem;
        line-height: 2.8rem;
        font-weight: 600;
      }
    }
    &__payments {
      display: flex;
      justify-content: center;
      column-gap: 103px;
      @include breakpoint-max('tablet') {
        column-gap: 30px;
      }
      @include breakpoint-max('xs-tablet') {
        flex-direction: column;
        align-items: center;
        row-gap: 43px;
      }
      @include breakpoint-max('mobile') {
        padding-right: 30px;
      }
    }
    &__payments-item {
      padding: 18px 34px;
      background-color: #e1dbd5;
      border: 1px solid $black;
      position: relative;
      p {
        text-align: center;
        font-size: 2.4rem;
        line-height: 3rem;
        color: $black;
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
      }
      span {
        text-align: center;
        font-size: 1.6rem;
        line-height: 2.2rem;
        font-weight: 400;
        font-family: 'Open Sans', sans-serif;
      }
      @include breakpoint-max('s-tablet') {
        padding: 16px 20px;
      }
      @include breakpoint-max('xs-tablet') {
        padding: 18px 34px;
        width: fit-content;
      }
    }
  }
</style>
