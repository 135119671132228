import { defineStore } from 'pinia'
import axios from 'axios'

export const useTemporaryMatchStore = defineStore('temporarymatch', {
    state: () => ({
        temporaryMatch: null,
        isValid: null,
        existingUserIds: [],
        notExistingUserIds: [],
        bothBasic: null,
        bothPlus: null,
        usersHasDifferentPayments: null,
        matchProfile: null,
        premiumAccepted: null,
        message: null,
        waitingForAcceptance: null
    }),
    actions: {
        getCookie(name) {
            const value = `; ${document.cookie}`
            const parts = value.split(`; ${name}=`)
            if (parts.length === 2) {
                return parts.pop().split(';').shift()
            }
        },
        async getTemporaryMatch() {
            console.log('[🍍pinia] getTemporaryMatch')
            const authToken = this.getCookie('authToken')
            const config = {
                headers: { Authorization: `Bearer ${authToken}` }
            }
            try {
                const response = await axios.get(
                    `${
                        process.env.VUE_APP_API
                    }api/temporaryMatch/user/${localStorage.getItem('userId')}`,
                    config
                )
                this.temporaryMatch = response?.data?.temporaryMaches?.[0]
                this.isValid = response?.data?.isValid
                this.existingUserIds = response?.data?.existingUserIds
                this.notExistingUserIds = response?.data?.notExistingUserIds

                if (this.temporaryMatch) {
                    await this.checkUsersPockets()
                    await this.checkPaymentsDiff()
                    await this.getMatchProfile()
                }
            } catch (e) {
                console.log(e)
            }
        },
        async checkUsersPockets() {
            console.log('[🍍pinia] checkUsersPockets')
            const authToken = this.getCookie('authToken')

            const config = {
                headers: { Authorization: `Bearer ${authToken}` }
            }

            try {
                const response = await axios.get(
                    `${process.env.VUE_APP_API}api/users/check-users-pockets/${this.temporaryMatch.userId}/${this.temporaryMatch.secondUserId}`,
                    config
                )
                this.bothBasic = response?.data?.bothBasic
                this.bothPlus = response?.data?.bothPlus
            } catch (e) {
                console.log('error')
                console.log(e)
            }
        },
        async checkPaymentsDiff() {
            console.log('[🍍pinia] checkPaymentsDiff')
            const authToken = this.getCookie('authToken')

            const config = {
                headers: { Authorization: `Bearer ${authToken}` }
            }
            try {
                const response = await axios.post(
                    `${process.env.VUE_APP_API}api/temporarymatch/check-plus-difference/`,
                    { temporaryMatchId: this.temporaryMatch._id },
                    config
                )
                this.usersHasDifferentPayments =
                    response?.data?.different || null
            } catch (e) {
                console.log('error')
                console.log(e)
            }
        },
        async getMatchProfile() {
            console.log('[🍍pinia] getMatchProfile')
            const authToken = this.getCookie('authToken')
            const config = {
                headers: { Authorization: `Bearer ${authToken}` }
            }
            let secondUserId =
                this.temporaryMatch.secondUserId ===
                localStorage.getItem('userId')
                    ? this.temporaryMatch.userId
                    : this.temporaryMatch.secondUserId

            const secondUserNotExists =
                this.notExistingUserIds.includes(secondUserId)

            if (secondUserNotExists) {
                this.matchProfile = null
                return
            }
            try {
                const response = await axios.get(
                    `${process.env.VUE_APP_API}api/users/get-match-profile/${secondUserId}`,
                    config
                )

                this.matchProfile = response?.data
                this.premiumAccepted = response?.data?.premiumAccepted
            } catch (e) {
                console.log(e)
            }
        }
    },
    getters: {
        isWaitingForOtherPerson: state => {
            // If there's no match or match is rejected
            if (!state.temporaryMatch || state.temporaryMatch.rejected) {
                state.message =
                    'Nie znaleziono meczu lub mecz został odrzucony.'
                return false
            }

            const currentUserId = localStorage.getItem('userId')

            // Check if current user is part of the match
            const isCurrentUserMatch =
                state.temporaryMatch.userId === currentUserId ||
                state.temporaryMatch.secondUserId === currentUserId

            if (!isCurrentUserMatch) {
                state.message =
                    'Nie znaleziono meczu dla aktualnego użytkownika.'
                return false
            }

            const isCurrentUserFirstUser =
                state.temporaryMatch.userId === currentUserId

            // If both users have accepted
            if (
                state.temporaryMatch.firstUserAccept === 1 &&
                state.temporaryMatch.secondUserAccept === 1
            ) {
                state.message = 'Jesteście połączeni. Przejdź do rozmowy.'
                state.waitingForAcceptance = false
                return false
            }

            // If current user is first user and waiting for second user
            if (
                isCurrentUserFirstUser &&
                state.temporaryMatch.firstUserAccept === 1 &&
                state.temporaryMatch.secondUserAccept === 0
            ) {
                state.message = 'Czekamy na akceptację drugiej osoby.'
                state.waitingForAcceptance = true
                return true
            }

            // If current user is second user and waiting for first user
            if (
                !isCurrentUserFirstUser &&
                state.temporaryMatch.firstUserAccept === 0 &&
                state.temporaryMatch.secondUserAccept === 1
            ) {
                state.message = 'Czekamy na akceptację drugiej osoby.'
                state.waitingForAcceptance = true
                return true
            }

            // Default case - waiting for current user's acceptance
            state.message = 'Oczekiwanie na twoją akceptację.'
            state.waitingForAcceptance = true
            return true
        },
        isCurrentUserFirstUser: state => {
            if (!state.temporaryMatch) {
                return false
            }

            const currentUserId = localStorage.getItem('userId')
            return state.temporaryMatch.userId === currentUserId
        }
    }
})
