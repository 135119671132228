<template>
    <section class="pros-cons">
        <div class="scroll-div" id="porownanie"></div>
        <div class="container-landing">
            <h2 class="pros-cons__title">STANDARDY</h2>
            <h3 class="pros-cons__subtitle">Dlaczego wybrać biuro?</h3>
            <ul class="pros-cons__wrapper">
                <li class="pros-cons__item">
                    <span class="pros-cons__empty"></span>
                    <div class="pros-cons__col-title">
                        BIURO
                    </div>
                    <div class="pros-cons__col-title">
                        APLIKACJE
                    </div>
                </li>
                <li v-for="item in items" :key="item" class="pros-cons__item">
                    <div class="pros-cons__item-line">
                        <span class="pros-cons__item-title">
                            {{ item.title }}
                        </span>
                        <div class="pros-cons__first-col-sign">
                            <svg v-if="item.firstCol" xmlns="http://www.w3.org/2000/svg" width="36.258" height="32.848" viewBox="0 0 36.258 32.848">
                                <path id="Path_147" data-name="Path 147" d="M1065.818,9855.131l7.66,9.613,17.378-23.248" transform="translate(-1060.197 -9835.896)" fill="none" stroke="#d13724" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
                            </svg>
                            <svg v-if="!item.firstCol" xmlns="http://www.w3.org/2000/svg" width="34.071" height="34.07" viewBox="0 0 34.071 34.07">
                                <g id="Group_156" data-name="Group 156" transform="translate(-1085.964 -7136.965)">
                                    <line id="Line_68" data-name="Line 68" y1="27" x2="27" transform="translate(1089.5 7140.5)" fill="none" stroke="#2a2a29" stroke-linecap="round" stroke-width="5"/>
                                    <line id="Line_69" data-name="Line 69" x2="27" y2="27" transform="translate(1089.5 7140.5)" fill="none" stroke="#2a2a29" stroke-linecap="round" stroke-width="5"/>
                                </g>
                            </svg>
                        </div>
                        <div class="pros-cons__second-col-sign">
                            <svg v-if="item.secondCol" xmlns="http://www.w3.org/2000/svg" width="36.258" height="32.848" viewBox="0 0 36.258 32.848">
                                <path id="Path_147" data-name="Path 147" d="M1065.818,9855.131l7.66,9.613,17.378-23.248" transform="translate(-1060.197 -9835.896)" fill="none" stroke="#d13724" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
                            </svg>
                            <svg v-if="!item.secondCol" xmlns="http://www.w3.org/2000/svg" width="34.071" height="34.07" viewBox="0 0 34.071 34.07">
                                <g id="Group_156" data-name="Group 156" transform="translate(-1085.964 -7136.965)">
                                    <line id="Line_68" data-name="Line 68" y1="27" x2="27" transform="translate(1089.5 7140.5)" fill="none" stroke="#2a2a29" stroke-linecap="round" stroke-width="5"/>
                                    <line id="Line_69" data-name="Line 69" x2="27" y2="27" transform="translate(1089.5 7140.5)" fill="none" stroke="#2a2a29" stroke-linecap="round" stroke-width="5"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="pros-cons__text">
                        {{ item.text }}
                    </div>
                </li>
            </ul>
            
        </div>
    </section>
</template>
  
<script>

    export default {
        data() {
            return {
                items: [
                    {
                        title: 'Obowiązkowa weryfikacja użytkowników',
                        text: 'Formularz zgłoszeniowy jest weryfikowany przez człowieka.',
                        firstCol: true,
                        secondCol: false,
                    },
                    {
                        title: 'Profil użytkownika',
                        text: 'Tworzymy szczegółowy profil uwzględniający oczekiwania i preferencje każdej osoby. Według niego poszukujemy odpowiednich dopasowań.',
                        firstCol: true,
                        secondCol: false,
                    },
                    {
                        title: 'Zamknięta społeczność',
                        text: 'Konieczne jest przejście przez etap weryfikacji i przestrzeganie biurowych standardów.',
                        firstCol: true,
                        secondCol: false,
                    },
                    {
                        title: 'Autorska metoda doboru',
                        text: 'Autorska metoda doboru opracowana przez psychologa. Dopasowania wybiera człowiek, nie algorytm.',
                        firstCol: true,
                        secondCol: false,
                    },
                    {
                        title: 'Dobór na podstawie szczegółowych preferencji',
                        text: 'Formularz zgłoszeniowy składa się z kilkudziesięciu pytań (otwartych i zamkniętych).',
                        firstCol: true,
                        secondCol: false,
                    },
                    {
                        title: 'Nie czujesz się jak w sklepie z ludźmi',
                        text: 'Po zaakceptowaniu dopasowania dwie osoby mogą rozmawiać tylko ze sobą. Jeśli zdecydują się na rozpoczęcie kolejnych poszukiwań, dopiero wtedy zostanie im przedstawiona następna osoba. ',
                        firstCol: true,
                        secondCol: false,
                    },
                    {
                        title: 'Twój profil jest wyjątkowy',
                        text: 'Jest przedstawiany jako jedyny, a nie jeden z wielu. ',
                        firstCol: true,
                        secondCol: false,
                    },
                    {
                        title: 'Proporcje',
                        text: 'W aplikacjach randkowych stosunek ilości kobiet do mężczyzn wprowadza ogromne zamieszanie i sprawia, że wartościowe osoby nie mają możliwości poznania kogokolwiek. W biurze nie ma możliwości przeglądania setek profili. Stawiamy na jakość, nie ilość.',
                        firstCol: true,
                        secondCol: false,
                    },
                ]
            };
        },

    };
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";
    @import "@/assets/scss/breakpoints.scss";
    @import "@/assets/scss/containers.scss";
    @import "@/assets/scss/Nunito.css";
    @import "@/assets/scss/global.scss";


    .pros-cons {
        padding: 68px 0 164px;
        @include breakpoint-max("mobile") {
        padding: 68px 0 64px;
        }
        &__wrapper {
            list-style: none;
        }
        &__item {
            padding: 25px 0;
            border-bottom: 1px solid $black;
            &:first-child {
                display: flex;
                align-items: flex-end;
            }
            @include breakpoint-max("mobile") {
                padding: 20px 0;
            }
        }
        &__empty {
            flex: 2;
        }
        &__title {
            font-size: 12rem;
            line-height: 13rem;
            text-transform: uppercase;
            color: $black3;
            font-weight: 600;
            font-family: 'Oswald', sans-serif;
            @include breakpoint-max('mobile') {
                font-size: 48px;
                line-height: 50px;
            }
        }
        &__subtitle {
            font-size: 4rem;
            line-height: 5rem;
            text-transform: uppercase;
            color: $black3;
            font-weight: 600;
            font-family: 'Oswald', sans-serif;
            margin-bottom: 50px;
            @include breakpoint-max('mobile') {
                font-size: 27px;
                line-height: 35px;
            }
        }
        &__col-title {
            flex: 1;
            text-align: center;
            font-size: 2rem;
            line-height: 3rem;
            color: $black3;
            font-weight: 700;
            @include breakpoint-max('mobile') {
                font-size: 14px;
                line-height: 15px;
            }
        }
        &__item-line {
            display: flex;
            text-align: center;
            font-size: 2.4rem;
            line-height: 3rem;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
        }
        &__item-title {
            flex: 2;
            text-align: left;
            font-size: 2.4rem;
            line-height: 3rem;
            @include breakpoint-max('mobile') {
                font-size: 18px;
                line-height: 25px;
            }
        }
        &__first-col-sign, &__second-col-sign {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                max-width: 25px;
                max-height: 25px;
                @include breakpoint-max("mobile") {
                    max-width: 20px;
                    max-height: 20px;
                }
            }
        }
        &__text {
            font-family: 'Open Sans', sans-serif;
            margin-top: 30px;
            max-width: 496px;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 500;
            @include breakpoint-max('mobile') {
                font-size: 15px;
                line-height: 20px;
                margin-top: 15px;
            }
        }
    }

</style>