import { ref } from 'vue'
import axios from 'axios'

const API_URL = process.env.VUE_APP_API

const getCookie = name => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) {
        return parts.pop().split(';').shift()
    }
}

export function useReport() {
    const data = ref(null)
    const error = ref(null)
    const error_msg = ref(null)
    const loading = ref(false)

    const sendReport = async (reportFrom, userIDtoReport, reportMessage) => {
        const authToken = getCookie('authToken')
        const config = { headers: { Authorization: `Bearer ${authToken}` } }

        loading.value = true
        try {
            const response = await axios.post(
                `${API_URL}api/report`,
                {
                    reportFrom: reportFrom,
                    userIDtoReport: userIDtoReport,
                    reportMessage: reportMessage
                },
                config
            )
            loading.value = false
        } catch (err) {
            error.value = true
            error_msg.value = err.message
            loading.value = false
        }
    }

    return {
        data,
        error,
        error_msg,
        loading,
        sendReport
    }
}
