<template>
    <HeaderComponent />
    <div class="payment-success">
        <div class="payment-success__wrapper">
            <h1>Dziękuję!<br>Płatność przeszła pomyślnie.</h1>
            <router-link to="/panel">Wróć</router-link>
        </div>
    </div>
</template>
<script>
import HeaderComponent from "@/components/landing-03/HeaderComponent.vue";
    export default {
        components: {
            HeaderComponent
        },
    }
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/Nunito.css";
@import "@/assets/scss/global.scss";

    .payment-success {
        height: 100vh;
        display: grid;
        place-items: center;
        &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            row-gap: 117px;
            h1 {
                text-align: center;
                font-size: 4.5rem;
                line-height: 5rem;
                font-family: "Promenade", serif;
                font-weight: 400;
            }
            a {
                width: 200px;
                padding: 15px 0;
                background-color: $pink;
                outline: none;
                border: none;
                text-decoration: none;
                color: $white;
                text-align: center;
                font-size: 1.6rem;
                font-family: 'Open Sans', sans-serif;
                font-weight: bold;
                text-transform: uppercase;
                display: block;
                margin: 0 auto
            }
        }
    }
    
</style>