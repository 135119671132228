<template>
    <div class="btn-main" @click="handleClick">REZYGNACJA</div>
</template>

<script setup>
    import { defineEmits } from 'vue'

    const emit = defineEmits(['reject-mobile'])

    const handleClick = () => {
        emit('reject')
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';
    @import '@/assets/scss/buttons.scss';

    .btn-main {
        margin: 0 auto;
    }
</style>
