<template>
    <div class="questionnaire-sixth-part">
    <h2 class="questionnaire-sixth-part__title">Część VI</h2>
    <div class="questionnaire-sixth-part__line"></div>
        <div class="questionnaire-sixth-part__item" v-for="el in partSixth">
            <div v-if="el" class="questionnaire-sixth-part__item-box-left">
                <div class="questionnaire-sixth-part__text">
                    <h3>{{ el.text }}</h3>
                    <div v-if="el.value">
                        <p v-for="item in el.value"><span>{{ item }}</span></p>
                    </div>
                </div>
            </div>
            <div class="questionnaire-sixth-part__item-line"></div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            partSixth: Array
        }
    }
</script>
<style lang="scss">
@import "@/assets/scss/Nunito.css";
@import "@vueform/multiselect/themes/default.css";
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/global.scss";
@import "@/assets/scss/Nunito.css";

    .questionnaire-sixth-part {
        width: 100%;
        max-width: 505px;
        padding-right: 30px !important;
        margin-bottom: 44px !important;
        margin-top: 43px !important;
        @include breakpoint-max("xs-tablet") {
            padding-right: 0px;
        }
        &__title {
            font-size: 3.5rem;
            line-height: 5rem;
            color: $black;
            padding-bottom: 17px;
            width: 100%;
            max-width: 506px;
            font-family: 'Promenade';
            font-weight: 400;
        }
        &__line {
            margin-bottom: 17px;
            height: 3px;
            background-color: $black;
            width: 100%;
            @include breakpoint-max("xl-tablet") {
                width: 65%;
            }
        }
        &__item {
            max-width: 1200px;
            width: 100%;
            &:last-child {
                .admin-to-verify__item-line {
                    display: none;
                }
            }
        }
        &__item-box {
            padding-top: 3px;
            display: flex;
            align-items: flex-start;
            column-gap: 41px;
            @include breakpoint-max("m-large") {
                column-gap: 15px;
            }
            @include breakpoint-max("xl-tablet") {
                flex-direction: column;
            }
        }
        &__item-box-left {
            flex: 68;
            display: flex;
            column-gap: 41px;
            @include breakpoint-max("m-large") {
                column-gap: 15px;
            }
            @include breakpoint-max("xl-tablet") {
                width: 100%;
            }
        }
        &__text {
            flex: 50;
            padding-bottom: 21px;
            margin-bottom: 3px;
            h3 {
                margin-bottom: 3px;
                font-size: 1.6rem;
                line-height: 3rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                color: $black;
            }
            p {
                font-size: 1.6rem;
                line-height: 2.2rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                color: $black;
            }
            em {
                font-style: normal;
                color: $pink;
                font-weight: 700;
            }
            @include breakpoint-max("large") {
                flex: 35;
            }
            @include breakpoint-max("xl-tablet") {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
        &__item {
            max-width: 1200px;
            width: 100%;
            &:last-child {
                .admin-to-verify__item-line {
                    display: none;
                }
            }
        }
        &__item-line {
            margin-bottom: 17px;
            width: 100%;
            height: 1px;
            background-color: $black;
            @include breakpoint-max("xl-tablet") {
                margin-top: 20px;
                width: 65%;
            }
        }
    }
</style>