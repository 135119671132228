<template>
    <div>
        <HeaderComponent />
        <section class="thank-you-page">
            <h1 class="thank-you-page__heading">
                {{ heading }}
            </h1>
            <p class="thank-you-page__text" v-if="message">{{ message }}</p>
            <button
                v-if="showLoginBtn"
                @click="login"
                class="thank-you-page__logout"
            >
                Zaloguj się
            </button>
        </section>
    </div>
</template>
<script>
    import HeaderComponent from '@/components/landing-03/HeaderComponent.vue'
    import axios from 'axios'

    export default {
        data() {
            return {
                message: '',
                heading: '',
                showLoginBtn: false
            }
        },
        components: {
            HeaderComponent
        },
        methods: {
            login() {
                this.$router.push('/login')
            }
        },
        async mounted() {
            try {
                const token = this.$route.query.token
                const response = await axios.get(
                    `${this.url}api/register/verify?token=${token}`
                )

                this.message = await response.data.message
                this.showLoginBtn = true

                switch (response.status) {
                    case 200:
                        this.$gtm.trackEvent({ event: 'formularz-verify' })
                        this.message = await response.data.message
                        this.showLoginBtn = true
                        this.heading =
                            'Dziękujemy za potwierdzenie rejestracji!'
                        break
                    case 400:
                    case 500:
                        this.message = await response.data.message
                        this.heading = 'Wystąpił błąd!'
                        this.showLoginBtn = false
                        break
                }
            } catch (error) {
                this.heading = 'Wystąpił błąd!'
                this.message = await error.response.data.message
            }
        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/Nunito.css';
    @import '@/assets/scss/global.scss';

    .thank-you-page {
        margin-top: 120px;
        min-height: calc(100vh - 120px);
        background-color: $bege3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include breakpoint-max('mobile') {
            padding-bottom: 100px;
        }
        &__heading {
            margin-bottom: 39px;
            letter-spacing: 0px;
            color: $black;
            max-width: 450px;
            text-align: center;
            font-size: 4.5rem;
            line-height: 5.5rem;
            font-weight: 400;
            font-family: 'Promenade', sans-serif;
            @include breakpoint-max('mobile') {
                font-size: 2.5rem;
                line-height: 2.9rem;
                margin-bottom: 30px;
                max-width: 250px;
            }
            @include breakpoint-max('xs-mobile') {
            }
        }

        &__text {
            text-align: center;
            font-family: 'Open Sans', sans-serif;
            color: $black;
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 2.4rem;
            padding: 0 15px;
            margin-bottom: 50px;
            text-transform: uppercase;
        }
        &__logout {
            background-color: $pink;
            color: $white;
            text-transform: uppercase;
            padding: 17px 24px;
            font-weight: 700;
            cursor: pointer;
            //
            outline: none;
            font-size: 2.4rem;
            line-height: 3rem;
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            padding: 10px 40px;
            cursor: pointer;
            background-color: $pink;
            color: $white;
        }
    }
</style>
