<template>
  <div v-if="show && deferredPrompt" class="install-pwa">
    <div class="install-pwa__inner">
      <div @click="close" class="install-pwa__close">X</div>
      <header>
        <p class="install-pwa__title">Zainstaluj jako aplikację PWA</p>
        <p class="install-pwa__subtitle">
          Dzięki technologii PWA możesz zainstalować biuro randkowe w taki sam
          sposób jak klasyczne aplikację na telefon
        </p>
      </header>
      <button class="btn-main" @click="installApp">Zainstaluj aplikację</button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        deferredPrompt: null,
        show: true,
        isSafari: false,
        isFirefox: false
      }
    },
    methods: {
      close() {
        this.show = false
      },
      installApp() {
        if (this.deferredPrompt) {
          this.deferredPrompt.prompt()
          this.deferredPrompt.userChoice.then(choiceResult => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the install prompt')
            } else {
              console.log('User dismissed the install prompt')
            }
            this.deferredPrompt = null
          })
        }
      },
      isSafariBrowser() {
        const isSafari =
          /constructor/i.test(window.HTMLElement) ||
          (function (p) {
            return p.toString() === '[object SafariRemoteNotification]'
          })(
            !window['safari'] ||
              (typeof safari !== 'undefined' &&
                window['safari'].pushNotification)
          )
        this.isSafari = isSafari
      },
      isFirefoxBrowser() {
        const isFirefox = typeof InstallTrigger !== 'undefined'
        this.isFirefox = isFirefox
      },
      handleBeforeInstallPrompt(event) {
        console.log('beforeinstallprompt event fired')
        // Prevent the mini-infobar from appearing on mobile
        event.preventDefault()
        // Stash the event so it can be triggered later
        this.deferredPrompt = event
        // Update the support flag
        this.supportsBeforeInstallPrompt = true
      }
    },
    mounted() {
      window.addEventListener(
        'beforeinstallprompt',
        this.handleBeforeInstallPrompt
      )
      this.isFirefoxBrowser()
      this.isSafariBrowser()
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variables.scss';
  @import '@/assets/scss/breakpoints.scss';
  @import '@/assets/scss/containers.scss';
  @import '@/assets/scss/Nunito.css';
  @import '@/assets/scss/global.scss';
  @import '@/assets/scss/buttons.scss';
  .install-pwa {
    width: 100dvw;
    padding: 40px 20px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 99;
    background-color: #fff;
    -webkit-box-shadow: 5px 1px 26px 1px rgba(66, 68, 90, 0.37);
    -moz-box-shadow: 5px 1px 26px 1px rgba(66, 68, 90, 0.37);
    box-shadow: 5px 1px 26px 1px rgba(66, 68, 90, 0.37);

    @include breakpoint-max('mobile') {
      bottom: 65px;
    }

    &__inner {
      display: flex;
      gap: 20px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    header {
      text-align: center;
    }

    &__title {
      font-family: 'Nunito', sans-serif;
      font-size: 2.5rem;
      font-weight: 700;
      color: #000;
      margin-bottom: 1rem;
    }

    &__subtitle {
      font-size: 2rem;
    }

    &__close {
      position: absolute;
      top: 20px;
      right: 60px;
      font-size: 2rem;
      cursor: pointer;
      color: #000;
      font-weight: bold;
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      &:hover {
        opacity: 0.8;
      }
    }
  }
</style>
