<template>
    <div class="youre-package">
        <!-- CURRENT -->
        <div class="youre-package__current">
            <h2 class="youre-package__current-title">PAKIET BASIC</h2>
            <p class="youre-package__current-time">{{ calculatedBasic }}</p>
            <div class="youre-package__current-content">
                <h3 class="youre-package__current-content-title">
                    Co zawiera pakiet?
                </h3>
                <div
                    :class="[
                        { currentPackageShow: currentPackageShow },
                        'youre-package__current-content-text'
                    ]"
                >
                    <div>
                        <ul>
                            <li v-for="el in basic">{{ el.text }}</li>
                        </ul>
                    </div>
                </div>
                <div
                    @click="openContent()"
                    class="youre-package__current-content-circle"
                >
                    <svg
                        :class="{ svgRotate: currentPackageShow }"
                        xmlns="http://www.w3.org/2000/svg"
                        width="27px"
                        height="19px"
                        viewBox="0 0 512 512"
                    >
                        <path
                            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                            fill="white"
                        />
                    </svg>
                </div>
            </div>
        </div>
        <!-- END CURRENT -->

        <!-- plus -->
        <div class="youre-package__premium">
            <h2 class="youre-package__premium-title">PAKIET PLUS</h2>
            <ul class="youre-package__premium-list">
                <li v-for="el in premium">{{ el.text }}</li>
            </ul>

            <div class="youre-package__premium-buttons" v-if="!calculated">
                <div class="youre-package__premium-buttons-left">
                    <div class="youre-package__premium-buttons-left-desc">
                        <div
                            class="youre-package__premium-buttons-left-desc-price"
                        >
                            99 zł/mies.
                        </div>
                        <div
                            class="youre-package__premium-buttons-left-desc-text"
                        >
                            (Przy płatności z góry za 6 m-cy, cena regularna:
                            199 zł/miesiąc)
                        </div>
                    </div>

                    <router-link
                        v-if="!calculated && user?.isVerifiedByAdmin"
                        to="/payment-plus"
                    >
                        AKTYWUJ
                    </router-link>

                    <button
                        @click="
                            handlePaymentPlusBtnTextForNoVerified(
                                'paymentPlusBtnTextForNoVerified'
                            )
                        "
                        v-if="!user?.isVerifiedByAdmin"
                    >
                        {{ paymentPlusBtnTextForNoVerified }}
                    </button>
                </div>
                <div class="youre-package__premium-buttons-right">
                    <div class="youre-package__premium-buttons-right-desc">
                        <div
                            class="youre-package__premium-buttons-right-desc-price"
                        >
                            139 zł/mies.
                        </div>
                        <div
                            class="youre-package__premium-buttons-right-desc-text"
                        >
                            (Przy płatności z góry za 3 m-ce, cena regularna:
                            199 zł/miesiąc)
                        </div>
                    </div>
                    <router-link
                        v-if="!calculated && user?.isVerifiedByAdmin"
                        to="/payment-plus-3"
                    >
                        AKTYWUJ
                    </router-link>
                    <button
                        @click="
                            handlePaymentPlusBtnTextForNoVerified(
                                'paymentPlus3MonthsBtnTextForNoVerified'
                            )
                        "
                        v-if="!user?.isVerifiedByAdmin"
                    >
                        {{ paymentPlus3MonthsBtnTextForNoVerified }}
                    </button>
                </div>
            </div>
            <div v-else>
                <p class="youre-package__calculated">{{ calculated }}</p>
            </div>
        </div>
        <!-- END PLUS -->

        <!-- PAKIET PREMIUM -->
        <div class="youre-package__premium-description">
            <h2 class="youre-package__premium-description-left-title">
                PAKIET PREMIUM
            </h2>
            <div class="youre-package__premium-description-wrapper">
                <div class="youre-package__premium-description-left">
                    <ul class="youre-package__premium-description-left-list">
                        <li v-for="el in premiumList">{{ el.text }}</li>
                    </ul>
                    <p class="youre-package__premium-description-left-price">
                        4500 zł /3 miesiące
                    </p>
                </div>
                <div class="youre-package__premium-description-right">
                    <div class="youre-package__premium-description-right-box">
                        <a href="mailto:kontakt@biurorandkowe.pl">
                            ZAPYTAJ O PAKIET
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- END PAKIET PREMIUM -->
    </div>
</template>
<script>
    import { useUserStore } from '@/stores/user'
    import { storeToRefs } from 'pinia'
    import { ref } from 'vue'

    export default {
        setup() {
            const userStore = useUserStore()
            const { user } = storeToRefs(userStore)

            const paymentPlusBtnTextForNoVerified = ref('Aktywuj')
            const paymentPlus3MonthsBtnTextForNoVerified = ref('Aktywuj')

            const handlePaymentPlusBtnTextForNoVerified = async clickedBtn => {
                if (clickedBtn === 'paymentPlusBtnTextForNoVerified') {
                    paymentPlusBtnTextForNoVerified.value =
                        'Dostępne po weryfikacji'
                } else {
                    paymentPlus3MonthsBtnTextForNoVerified.value =
                        'Dostępne po weryfikacji'
                }

                await userStore.sentNotifyAboutClickedInActivatePlusButton()
            }

            return {
                user,
                paymentPlusBtnTextForNoVerified,
                paymentPlus3MonthsBtnTextForNoVerified,
                handlePaymentPlusBtnTextForNoVerified
            }
        },
        data() {
            return {
                currentPackageShow: false,
                basic: [
                    {
                        text: 'Rejestracja konta użytkownika w Biurze Randkowym'
                    },
                    {
                        text: 'Członkostwo w Biurze Randkowym przez 12 miesięcy'
                    },
                    { text: 'Weryfikacja kwestionariusza zgłoszeniowego' },
                    {
                        text: 'Sprawdzenie zgodności i spójności przesłanych informacji'
                    },
                    {
                        text: 'Publikacja wizytówki w bazie Biura Randkowego (Wizytówka jest prezentowana dopasowanej osobie i zawiera ograniczoną liczbę informacji o użytkowniku)'
                    },
                    {
                        text: 'Profil singla jest tworzony na potrzeby doboru, widoczny tylko dla specjalisty.'
                    },
                    {
                        text: 'Nielimitowana prezentacja profilu osobom korzystającym z pakietu Plus lub Premium'
                    },
                    {
                        text: 'Możliwość umówienia się na randkę z przedstawioną osobą'
                    },
                    {
                        text: 'Dodatkowe funkcje (możliwość korzystania z funkcji czatu i jego dodatkowych opcji w przypadku pozytywnego dopasowania)'
                    },
                    {
                        text: 'Możliwość wprowadzania zmian (Możliwość wprowadzania przez użytkownika zmian w niektórych elementach jego profilu)'
                    },
                    { text: 'Możliwość podwyższenia pakietu do wersji PLUS' }
                ],
                premium: [
                    {
                        text: 'Udział w zaawansowanym, spersonalizowanym procesie poszukiwań'
                    },
                    {
                        text: 'Dobór przez konsultanta odpowiadających profilowi singla dopasowań'
                    },
                    {
                        text: 'Nielimitowana prezentacja dopasowań wybranych przez konsultanta innym osobom korzystającym z pakietu BASIC, PLUS i PREMIUM'
                    }
                ],
                premiumList: [
                    {
                        text: 'Indywidualna opieka i współpraca z Barbarą Strójwąs'
                    },
                    {
                        text: '3x45min spotkanie rozpoczynające współpracę, oraz 2 spotkania w trakcie współpracy'
                    },
                    { text: 'Nielimitowana prezentacja' },
                    {
                        text: 'Pierwszeństwo w akceptacji przedstawianych dopasowań'
                    },
                    {
                        text: 'Udzielanie informacji zwrotnej po każdej prezentacji dopasowanej osoby'
                    },
                    { text: 'Możliwość zawieszenia aktywności na 3 miesiące' }
                ],
                calculated: false,
                calculatedBasic: null,
                months: null
            }
        },
        methods: {
            openContent() {
                if (this.currentPackageShow) {
                    this.currentPackageShow = false
                } else {
                    this.currentPackageShow = true
                }
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },

            calculatePackageExpiration() {
                if (this.user?.paymentPlusExpirationDate) {
                    const expirationDate = new Date(
                        this.user.paymentPlusExpirationDate
                    )
                    this.calculated =
                        'Wygasa: ' + expirationDate.toISOString().split('T')[0]
                }

                if (
                    this.user?.paymentPlusDate &&
                    !this.user?.paymentPlusExpirationDate
                ) {
                    console.log(this.user?.paymentPlusDate)

                    const startDate = new Date(this.user?.paymentPlusDate)
                    let monthsToAdd = 0

                    if (this.user?.paymentPlusThreeMonths === true) {
                        monthsToAdd = 3
                        this.months = true
                    } else {
                        monthsToAdd = 6
                    }

                    const expirationDate = new Date(
                        startDate.setMonth(startDate.getMonth() + monthsToAdd)
                    )
                    this.calculated =
                        'Pakiet wygasa: ' +
                        expirationDate.toISOString().split('T')[0]
                }

                if (
                    !this.user?.paymentPlusDate &&
                    !this.user?.paymentPlusDate
                ) {
                    return
                }
            },
            calculateBasic() {
                const startDate = new Date(this.user?.paymentBasicDate)
                if (isNaN(startDate.getTime())) return
                let monthsToAdd = 12

                // if (this.user?.paymentPlusThreeMonths === true) {
                //     monthsToAdd = 3;
                //     this.months = true;
                // } else {
                //     monthsToAdd = 6;
                // }

                const expirationDate = new Date(
                    startDate.setMonth(startDate.getMonth() + monthsToAdd)
                )
                this.calculatedBasic =
                    'Wygasa: ' + expirationDate.toISOString().split('T')[0]
            }
        },
        created() {
            this.calculateBasic()
            this.calculatePackageExpiration()
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .youre-package {
        overflow: hidden;
        padding-bottom: 81px;
        padding-right: 30px;
        @include breakpoint-max('xs-tablet') {
            padding-left: 30px;
        }
        // #region CURRENT
        &__current {
            width: 100%;
            max-width: 780px;
            margin-bottom: 80px;
        }
        &__current-title {
            width: 100%;
            padding: 14px;
            color: $white;
            background-color: $pink;
            text-align: center;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 2rem;
        }
        &__current-time {
            width: 100%;
            text-align: center;
            padding: 23px 0;
            font-size: 1.6rem;
            line-height: 2rem;
            font-weight: 700;
            background-color: #e1dbd5;
            border: 1px solid $black;
        }
        &__current-content {
            position: relative;
            text-align: center;
        }
        &__current-content-circle {
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $pink;
            display: grid;
            place-items: center;
            cursor: pointer;
            svg {
                transition: ease-out 0.3s;
            }
        }
        &__current-content-title {
            font-size: 1.6rem;
            line-height: 2rem;
            font-weight: 400;
            padding: 28px 0;
            border: 1px solid $black;
            border-top: 0;
        }
        &__current-content-text {
            max-height: 0;
            overflow: hidden;
            transition: ease-out 0.3s;
            div {
                padding: 12px 30px;
                border: 1px solid $black;
                border-top: 0;
                transition: ease-out 0.3s;
            }
            ul {
                list-style: none;
                li {
                    padding: 10px 0;
                    border-bottom: 1px solid $black;
                    text-align: left;
                    font-size: 1.2rem;
                    line-height: 2.2rem;
                    font-weight: 400;
                    transition: ease-out 0.3s;
                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
            p {
                font-size: 1.4rem;
                line-height: 2rem;
                font-weight: 400;
                transition: ease-out 0.3s;
                @include breakpoint-max('mobile') {
                    padding: 28px 15px;
                }
            }
        }
        .currentPackageShow {
            max-height: 850px;
            transition: ease-out 0.3s;
            p {
                transition: ease-out 0.3s;
            }
        }
        .svgRotate {
            rotate: 180deg;
        }

        &__premium {
            width: 100%;
            max-width: 780px;
            margin-bottom: 87px;
        }
        &__premium-title {
            width: 100%;
            padding: 14px;
            color: $white;
            background-color: $pink;
            text-align: center;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 2rem;
        }
        &__premium-list {
            margin-bottom: 38px;
            padding: 10px 30px;
            list-style: none;
            border: 1px solid $black;
            li {
                border-bottom: 1px solid $black;
                padding: 10px 0;
                font-size: 1.2rem;
                line-height: 2.2rem;
                font-weight: 400;
                &:last-child {
                    border-bottom: 0;
                }
            }
            @include breakpoint-max('mobile') {
                padding: 10px 15px;
            }
        }
        &__premium-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 25px;
            @include breakpoint-max('mobile') {
                flex-direction: column;
                row-gap: 60px;
            }
        }
        &__premium-buttons-left,
        &__premium-buttons-right {
            display: flex;
            flex-direction: column;
            row-gap: 26px;
            width: 200px;
            a,
            button {
                display: block;
                text-decoration: none;
                text-align: center;
                width: 100%;
                background-color: $pink;
                outline: none;
                border: none;
                font-size: 1.6rem;
                line-height: 3rem;
                font-weight: 700;
                color: $white;
                padding: 15px 0;
                cursor: pointer;
            }
            @include breakpoint-max('mobile') {
                row-gap: 10px;
            }
        }
        &__premium-buttons-left-desc,
        &__premium-buttons-right-desc {
            padding: 7px 10px;
            width: 100%;
            border: 1px solid $black;
            background-color: #e1dbd5;
        }
        &__premium-buttons-left-desc-price,
        &__premium-buttons-right-desc-price {
            text-align: center;
            font-size: 2.4rem;
            line-height: 3rem;
            font-weight: 700;
            font-family: 'Open Sans', sans-serif;
        }
        &__premium-buttons-left-desc-text,
        &__premium-buttons-right-desc-text {
            text-align: center;
            font-size: 1.2rem;
            line-height: 3rem;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
        }
        // #endregion
        //
        // #region PREMIUM-CONTENT
        &__premium-description {
        }
        &__premium-description-wrapper {
            display: flex;
            align-items: center;
            column-gap: 33px;
            @include breakpoint-max('xl-tablet') {
                flex-direction: column;
                row-gap: 60px;
            }
        }
        &__premium-description-left-title {
            font-size: 4.5rem;
            line-height: 4.4rem;
            color: $black;
            font-family: 'Promenade', serif;
            position: relative;
            width: fit-content;
            margin-bottom: 14px;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 110%;
                transform: translateY(-50%);
                width: 450%;
                height: 5px;
                background-color: $pink;
            }
        }
        &__premium-description-left {
            width: 100%;
            max-width: 780px;
        }
        &__premium-description-left-list {
            padding: 30px 38px;
            border: 1px solid $black;
            background-color: #e1dbd5;
            margin-bottom: 13px;
            list-style: none;
            li {
                padding: 12px 0;
                border-bottom: 1px solid $black;
                font-size: 1.6rem;
                line-height: 3rem;
                font-weight: 400;
                color: $black;
                font-family: 'Open Sans', sans-serif;
                &:last-child {
                    border: 0;
                }
            }
            @include breakpoint-max('mobile') {
                padding: 30px 15px;
            }
        }
        &__premium-description-left-price {
            text-align: center;
            font-family: 'Promenade', serif;
            font-size: 3.5rem;
            line-height: 2.5rem;
            margin-top: 20px;
            color: $black;
        }
        &__premium-description-right {
            max-width: 200px;
        }
        &__premium-description-right-box {
            a {
                display: block;
                text-decoration: none;
                width: 100%;
                padding: 15px 20px;
                color: $white;
                font-size: 1.6rem;
                line-height: 3rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                background-color: $pink;
                text-align: center;
                margin-bottom: 18px;
            }
            p {
                width: 100%;
                text-align: center;
                font-size: 1.6rem;
                line-height: 2.2rem;
                color: $black;
                font-weight: 500;
                em {
                    font-style: normal;
                    font-weight: 700;
                }
            }
        }
        &__calculated {
            font-weight: 700;
            text-align: center;
            color: $pink;
        }
        // #endregion
    }
</style>
