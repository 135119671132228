<template>
    <li class="admin-user">
        <div
            :class="[
                { premiumOption: premiumOption },
                'admin-user__content-users-wrapper'
            ]"
        >
            <picture>
                <img v-if="imgSrc" :src="imgSrc" />
                <img v-else src="../../assets/images/ludzik1.png" />
            </picture>
            <p class="admin-user__content-users-info">{{ name }}, {{ age }}</p>
            <router-link
                v-if="!pathName"
                target="_blank"
                :to="`/admin/users/user/${id}`"
                @click="savePath"
                class="admin-user__content-users-link"
            >
                zobacz profil
            </router-link>
            <router-link
                v-else
                target="_blank"
                :to="`/admin/verify/user/${id}`"
                @click="savePath"
                class="admin-user__content-users-link"
            >
                zobacz profil
            </router-link>
        </div>
    </li>
</template>
<script>
    export default {
        data() {
            return {
                flagMap: {
                    0: { color: 'red', text: 'Czerwona' },
                    1: { color: 'yellow', text: 'Żółta' },
                    2: { color: 'blue', text: 'Niebieska' },
                    3: { color: 'green', text: 'Zielona' },
                    default: { color: 'red', text: 'Czerwona' }
                },
                pathName: false,
                finalDate: ''
            }
        },
        props: {
            id: String,
            name: String,
            imgSrc: String,
            phone: String,
            mail: String,
            matches: Number,
            flag: Number,
            age: Number,
            sex: String,
            access: Boolean,
            premiumOption: Boolean
        },
        mounted() {
            this.setLink()
        },
        methods: {
            savePath() {
                localStorage.setItem('adminUrlPrev', location.pathname)
            },
            setLink() {
                if (location.pathname.indexOf('/admin/verify/') > -1) {
                    this.pathName = true
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';

    .admin-user {
        &__content-users-wrapper {
            position: relative;
            border: 1px solid $black;
            width: 100%;
            picture {
                display: block;
                img {
                    display: block;
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    object-position: center;
                    aspect-ratio: 1;
                }
            }
        }
        .premiumOption {
            border-color: $pink;
        }
        &__content-users-info {
            text-align: center;
            padding: 37px 0 60px;
            font-family: 'Open Sans', sans-serif;
            font-size: 1.6rem;
            line-height: 2rem;
            color: $black;
            font-weight: 700;
            text-transform: uppercase;
            @include breakpoint-max('m-large') {
                padding: 15px 0 40px;
            }
            @include breakpoint-max('mobile') {
                padding: 8px 0 25px;
            }
        }
        &__content-users-link {
            text-decoration: none;
            position: absolute;
            bottom: -26px;
            left: 50%;
            transform: translateX(-50%);
            background-color: $pink;
            padding: 15px 0;
            width: 75%;
            display: grid;
            place-items: center;
            text-transform: uppercase;
            font-family: 'Open Sans', sans-serif;
            font-size: 1.6rem;
            line-height: 2rem;
            color: $white;
            font-weight: 700;
            @include breakpoint-max('m-large') {
                padding: 9px 0;
                bottom: -19px;
            }
            @include breakpoint-max('mobile') {
                font-size: 1rem;
                bottom: -11px;
                padding: 4px 0;
                width: 80px;
            }
        }
    }
</style>
