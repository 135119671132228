<template>
    <section class="video">
        <div class="container-landing">
            <h1 class="video__title">Odpowiedzi</h1>
            <h2 class="video__subtitle">na wasze pytania</h2>
            <div class="video__video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/mKrxmntMwzU?si=RzDxoB-3bQgBfMx0" frameborder="0" allowfullscreen></iframe>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        
    }
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/Nunito.css";
@import "@/assets/scss/global.scss";

    .video {
        border-top: 1px solid $black;
        padding: 90px 0 130px;
        @include breakpoint-max('mobile') {        
            padding: 50px 0 50px;
        }
        &__video {
            display: flex;
            justify-content: center;
            iframe {
                max-width: 1104px;
                width: 100%;
                height: 100%;
                max-height: 614px;
                display: block;
                object-fit: cover;
                aspect-ratio: calc(1104 / 614);
            }
        }
        &__title {
            font-size: 12rem;
            line-height: 13rem;
            text-transform: uppercase;
            color: $black3;
            font-weight: 600;
            font-family: 'Oswald', sans-serif;
            margin-left: -5px;
            @include breakpoint-max('mobile') {
                font-size: 48px;
                line-height: 50px;
            }
        }
        &__subtitle {
            font-size: 4rem;
            line-height: 5rem;
            text-transform: uppercase;
            color: $black3;
            font-weight: 600;
            font-family: 'Oswald', sans-serif;
            margin-bottom: 80px;
            @include breakpoint-max('mobile') {
                font-size: 27px;
                line-height: 35px;
                margin-bottom: 40px;
            }
        }
    }

</style>