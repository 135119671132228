<template>
    <section class="only-text">
        <div class="container-landing">
            <div class="only-text__content" v-html="content"></div>
        </div>
    </section>
</template>
  
<script>



    export default {
        data() {
            return {
            };
        },
        props: {
            content: String,
        }
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";
    @import "@/assets/scss/breakpoints.scss";
    @import "@/assets/scss/containers.scss";
    @import "@/assets/scss/Nunito.css";
    @import "@/assets/scss/global.scss";


    .only-text {
        margin-bottom: 130px;
        :deep &__content {
            p, li {
                font-size: 1.6rem;
                line-height: 2.4rem;
                margin-bottom: 30px;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                strong {
                    font-size: 16px;
                }
                @include breakpoint-max('mobile') {
                    font-size: 14px;
                    line-height: 20px;
    
                }
            }
            h1, h2, h3, h4, h5, h6 {
                font-family: 'Oswald', sans-serif;
                font-weight: 800;
                margin-bottom: 30px;
            }
            h1 {
                font-size: 6rem;
                line-height: 6.7rem;
            }
            h2 {
                font-size: 4rem;
                line-height: 4.7rem;
            }
            h3 {
                font-size: 2.4rem;
                line-height: 3rem;
            }
            h4 {
                font-size: 1.8rem;
                line-height: 2.3rem;
            }
            ul, ol {
                li {
                    margin-bottom: 10px;
                }
            }
        }
    }

</style>