<template>
    <div class="questionnaire-firs-part">
        <h2 class="questionnaire-firs-part__title">Część I</h2>
        <div class="questionnaire-firs-part__line"></div>
        <p v-for="el in partOne">
            {{ el.text }}:
            <span v-if="typeof el.value == 'string'">{{ el.value }}</span>
            <span
                v-else-if="
                    el?.value &&
                    el?.value.length &&
                    typeof el.value[0] == 'number'
                "
            >
                <span v-if="el.value[0] == 0">Kobiety</span>
                <span v-if="el.value[0] == 1">Mężczyzny</span>
                <span v-if="el.value[0] == 2">Osoby niebinarnej</span>
            </span>
            <span v-else v-for="item in el.value">{{ item }}</span>
        </p>
        <p v-if="partOneSocials[0]">Media społecznościowe:</p>
        <a
            v-for="el in partOneSocials"
            target="_blank"
            class="questionnaire-firs-part__social"
            :href="el.value"
        >
            {{ el.text }}
        </a>
    </div>
</template>
<script>
    export default {
        props: {
            partOne: Array,
            partOneSocials: Array
        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/Nunito.css';
    @import '@vueform/multiselect/themes/default.css';
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .questionnaire-firs-part {
        width: 100%;
        max-width: 505px;
        padding-right: 30px;
        margin-bottom: 44px;
        @include breakpoint-max('xs-tablet') {
            padding-right: 0px;
        }
        p {
            font-size: 1.6rem;
            line-height: 2rem;
            color: $black;
            font-weight: 700;
            font-family: 'Open Sans', sans-serif;
            margin-bottom: 17px;
            span {
                font-weight: 400;
                display: inline-block;
                margin-left: 10px;
                span {
                    margin-left: 0;
                }
            }
        }
        &__title {
            margin-top: 35px;
            font-size: 3.5rem;
            line-height: 5rem;
            font-family: 'Promenade', serif;
            font-weight: 400;
        }
        &__line {
            margin-bottom: 17px;
            height: 3px;
            background-color: $black;
            width: 100%;
            @include breakpoint-max('xl-tablet') {
                width: 65%;
            }
        }
        &__social {
            display: block;
            margin-bottom: 7px;
            color: $black;
            text-decoration: none;
            font-family: 'Open Sans', sans-serif;
            font-size: 1.6rem;
            line-height: 3rem;
            font-weight: 400;
            transition: 0.3s;
            width: fit-content;
            &:hover {
                color: $pink;
            }
        }
    }
</style>
