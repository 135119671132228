<template>
    <!-- <div v-if="showUpdateAlert" class="update-prompt">
        <div class="update-prompt__content">
            <div class="update-prompt__header">
                <h3>Dostępna nowa wersja</h3>
                <button @click="closePrompt" class="update-prompt__close">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </button>
            </div>
            <p>
                Nowa wersja aplikacji jest dostępna. Zaktualizuj teraz, aby
                korzystać z najnowszych funkcji.
            </p>
            <div class="update-prompt__actions">
                <button
                    @click="closePrompt"
                    class="update-prompt__button update-prompt__button--secondary"
                >
                    Później
                </button>
                <button
                    @click="updateApplication"
                    class="update-prompt__button update-prompt__button--primary"
                >
                    Aktualizuj teraz
                </button>
            </div>
        </div>
    </div> -->
</template>

<script>
    export default {
        name: 'UpdatePrompt',
        data() {
            return {
                showUpdateAlert: false,
                waitingWorker: null,
                updateChannel: null
            }
        },
        methods: {
            checkVersion() {
                const currentVersion = process.env.VUE_APP_VERSION
                const storedVersion = localStorage.getItem('appVersion')
                if (storedVersion !== currentVersion) {
                    this.showUpdateAlert = true
                    localStorage.setItem('appVersion', currentVersion)
                }
            },
            closePrompt() {
                this.showUpdateAlert = false
            },
            async updateApplication() {
                if (this.waitingWorker) {
                    // Send message to service worker to skip waiting
                    this.waitingWorker.postMessage({ type: 'SKIP_WAITING' })

                    // Wait for the new service worker to take control
                    this.waitingWorker.addEventListener('statechange', () => {
                        if (this.waitingWorker.state === 'activated') {
                            // window.location.reload()
                            console.log('.statechange activated')
                        }
                    })
                }
            },
            setupServiceWorker() {
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.ready.then(registration => {
                        // Check if there's a waiting worker
                        if (registration.waiting) {
                            this.waitingWorker = registration.waiting
                            this.showUpdateAlert = true
                        }

                        // Listen for new waiting workers
                        registration.addEventListener('waiting', event => {
                            this.waitingWorker = event.target
                            this.showUpdateAlert = true
                        })

                        // Listen for controlling change
                        navigator.serviceWorker.addEventListener(
                            'controllerchange',
                            () => {
                                if (this.refreshing) return
                                this.refreshing = true
                                // window.location.reload()
                            }
                        )
                    })
                }
            },
            setupBroadcastChannel() {
                try {
                    this.updateChannel = new BroadcastChannel(
                        'app-update-channel'
                    )
                    this.updateChannel.addEventListener('message', event => {
                        if (event.data === 'update-available') {
                            this.showUpdateAlert = true
                        }
                    })
                } catch (error) {
                    console.warn('BroadcastChannel not supported:', error)
                }
            }
        },
        mounted() {
            this.setupServiceWorker()
            this.setupBroadcastChannel()
            this.checkVersion()
        },
        beforeUnmount() {
            // Clean up broadcast channel
            if (this.updateChannel) {
                this.updateChannel.close()
            }
        }
    }
</script>

<style scoped lang="scss">
    .update-prompt {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 1000;
        max-width: 400px;
        background: white;
        border-radius: 8px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -1px rgba(0, 0, 0, 0.06);

        &__content {
            padding: 16px;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;

            h3 {
                font-size: 18px;
                font-weight: 600;
                margin: 0;
            }
        }

        &__close {
            background: none;
            border: none;
            padding: 4px;
            cursor: pointer;
            color: #666;

            &:hover {
                color: #333;
            }
        }

        p {
            margin: 0 0 16px 0;
            color: #666;
            line-height: 1.5;
        }

        &__actions {
            display: flex;
            justify-content: flex-end;
            gap: 8px;
        }

        &__button {
            padding: 8px 16px;
            border-radius: 4px;
            font-weight: 500;
            cursor: pointer;
            transition: background-color 0.2s;

            &--primary {
                background-color: #4dba87;
                color: white;
                border: none;

                &:hover {
                    background-color: darken(#4dba87, 5%);
                }
            }

            &--secondary {
                background-color: transparent;
                color: #666;
                border: 1px solid #ddd;

                &:hover {
                    background-color: #f5f5f5;
                }
            }
        }
    }
</style>
