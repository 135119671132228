<template>
    <section class="call-to-action">
        <SimpleButton
            text="Zapisz się"
            route="/register"
            :is-on-black=true
        />
    </section>
</template>
  
<script>
    import SimpleButton from '@/components/landing-03/atoms/SimpleButton.vue';


    export default {
        data() {
            return {
            };
        },
        props: {
            
        },
        computed: {
            
        },
        components: {
            SimpleButton
        }
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";
    @import "@/assets/scss/breakpoints.scss";
    @import "@/assets/scss/containers.scss";
    @import "@/assets/scss/Nunito.css";
    @import "@/assets/scss/global.scss";


    .call-to-action {
        background-color: $black3;
        padding: 119px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        :deep .simple-button {
            font-size: 9rem;
            line-height: 9.5rem;
            padding: 20px 52px;
            @include breakpoint-max('mobile') {
                font-size: 40px;
                line-height: 55px;
                padding: 15px 42px;

            }
            &__link-heart {
                transform: translate(-50%, 200%) scale(2);
            }
            &:hover {
                border-color: $white;
                .simple-button__link-heart {
                    transform: translate(-50%, -50%)  scale(2);
                }
                &::before {
                    transform: translate(-50%, -50%) scale(2.5);
                    transition-delay: .4s;
                }
                span {
                    color: $white;
                }
            }
        }
    }

</style>