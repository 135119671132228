<template>
  <div>
    <HeaderComponent/>
    <div class="opening-questions">
        <div class="opening-questions__wrapper">
            <div class="opening-questions__content-wrap">
                <div class="opening-questions__content">
                    <p>
                    Witaj,<br>za chwilę rozpoczniesz uzupełnianie formularza. Przy każdym pytaniu znajdziesz informajcję o tym, czy będzie widoczne w Twoim profilu oraz ewentualnie wyjaśnienia dotyczące pytań.
                    </p>
                    <p>
                        Na końcu poproszę Cię o sprawdzenie swoich odpowiedzi. Pamiętaj, że nie ma dobrych i złych odpowiedzi, są tylko Twoje najwspanialsze. Dzięki nim będę w stanie dopasować do Ciebie osobę, z którą randka może wszystko zmienić.
                    </p>
                    <p>Basia Pelasia</p>
                </div>
                <router-link to="/questions2" class="opening-questions__link">
                    <span>
                        PRZEJDŹ DO FORMULARZA
                    </span>
                </router-link>
            </div>
            <div class="opening-questions__img">
                <img src="@/assets/images/b2.png" >
            </div>
        </div>
    </div>
    <Footer/>
  </div>
</template>

<script>

import HeaderComponent from "@/components/landing-03/HeaderComponent.vue";
import Footer from "@/components/landing-03/footer.vue";

export default {
 components: {
        Footer,
        HeaderComponent,
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/global.scss";
@import "@/assets/scss/Nunito.css";

.opening-questions {
    height: calc(100vh - 121px);
    margin-top: 121px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__wrapper {
        display: flex;
        column-gap: 26px;
    }
    &__content-wrap {
        text-align: center;
    }
    &__content {
        margin-bottom: 45px;
        padding: 46px;
        max-width: 560px;
        border: 1px solid #2A2A29;
        border-radius: 30px;
        border-top-right-radius: 0;
        background-color: #E1DBD5;
        text-align: left;
        p {
            margin-bottom: 20px;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            font-family: 'Open Sans';
        }
        p:last-of-type {
            margin-bottom: 0;
        }
    }
    &__link {
        display: inline-block;
        text-align: center;
        padding: 15px 20px;
        color: #FFF;
        font-weight: 600;
        font-size: 14px;
        font-family: 'Open Sans';
        background-color: #D13724;
        transition: .3s ease;
        border: 1px solid #D13724;
        text-decoration: none;
        min-width: 300px;
        &:hover {
            background-color: #fff;
            color: #D13724;
        }
    }
    &__img {
        img {
            margin-top: -48px;
            height: 96px;
            width: 96px;
            border-radius: 50%;
        }
    }
}
</style>