<template>
    <div class="price-list main-landing">
        <HeaderComponent/>
        <ListWithHearts/>
        <Footer />
    </div>
</template>
<script>
import HeaderComponent from "@/components/landing-03/HeaderComponent.vue";
import ListWithHearts from "@/components/landing-03/ListWithHearts.vue";
import Footer from "@/components/landing-03/footer.vue";
        export default {
            components: {
                HeaderComponent,
                ListWithHearts,
                Footer
            }
        }
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/Nunito.css";
@import "@/assets/scss/global.scss";

    .price-list {
        padding-top: 121px;
        @include breakpoint-max("xs-tablet") {
            padding-top: 50px;
        }
        .container-landing {
            max-width: 1000px;
        }
    }
</style>