export const registerServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
        try {
            const registration = await navigator.serviceWorker.register(
                '/firebase-messaging-sw.js'
            )
            checkForUpdates(registration)
        } catch (error) {
            console.error('ServiceWorker registration failed:', error)
        }
    }
}

const checkForUpdates = registration => {
    registration.addEventListener('updatefound', () => {
        const newWorker = registration.installing
        newWorker.addEventListener('statechange', () => {
            if (
                newWorker.state === 'installed' &&
                navigator.serviceWorker.controller
            ) {
                forceUpdate()
            }
        })
    })
}

export const forceUpdate = async () => {
    try {
        const registrations = await navigator.serviceWorker.getRegistrations()
        await Promise.all(
            registrations.map(reg => {
                if (reg.waiting) {
                    reg.waiting.postMessage({ type: 'SKIP_WAITING' })
                }
                return reg.unregister()
            })
        )

        const cacheKeys = await caches.keys()
        await Promise.all(cacheKeys.map(key => caches.delete(key)))

        window.location.reload()
    } catch (error) {
        console.error('Force update failed:', error)
    }
}
