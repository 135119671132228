<template>
    <div>
        <HeaderComponent />
        <section class="login">
            <div class="container">
                <h1 class="login__title">LOGOWANIE NA KONTO USERA</h1>
                <div class="login__form">
                    <form @submit.prevent="login">
                        <label for="userID">
                            <input
                                id="userID"
                                type="text"
                                v-model="userID"
                                class="login__form-userID"
                            />

                            <p>user ID:</p>
                        </label>

                        <div class="login__form-error-login">
                            <p>{{ feedback }}</p>
                        </div>
                        <div class="login__wrapper-bottom">
                            <button v-if="!loaderAnimation">Zaloguj się</button>
                            <button disabled v-else class="loader">
                                <svg viewBox="25 25 50 50">
                                    <circle r="20" cy="50" cx="50"></circle>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
        <Footer />
    </div>
</template>
<script>
    import axios from 'axios'

    import HeaderComponent from '@/components/landing-03/HeaderComponent.vue'
    import Footer from '@/components/landing-03/footer.vue'
    import Input from '@/components/atoms/Input.vue'
    import Button from '@/components/atoms/Button.vue'

    export default {
        data() {
            return {
                userID: '',
                password: '',
                feedback: '',
                feedbackEmail: '',
                feedbackPassword: '',
                emailKey: 0,
                passwordKey: 0,
                popup: false,
                error: false,
                isRegistered: false,
                loaderAnimation: false
            }
        },
        components: {
            Input,
            Button,
            HeaderComponent,
            Footer
        },
        methods: {
            login() {
                this.loaderAnimation = true
                try {
                    const response = axios.post(
                        `${this.url}api/login/as-user`,
                        {
                            userID: this.userID
                        },
                        {
                            withCredentials: true
                        }
                    )

                    response.then(res => {
                        this.loading = false
                        // console.log(res)
                        const authToken = res.data.authToken
                        window.localStorage.setItem('userId', res.data.user._id)
                        window.localStorage.setItem(
                            'isAdmin',
                            res.data.user.role === 'admin' ? 1 : 0
                        )

                        this.setAuthCookie(authToken)
                        this.checkUserQuestionnaire(res.data.user._id)
                    })
                } catch (e) {
                    console.log(e)
                    this.feedback = e
                    this.loading = false
                }
            },
            checkUserQuestionnaire(userID) {
                console.log('checkUserQuestionnaire..')
                try {
                    const response = axios
                        .post(
                            `${this.url}api/login/check-questionnaire/${userID}`
                        )
                        .then(res => {
                            // console.log(res)
                            this.$router.push('/panel/notification')
                        })
                } catch (e) {
                    console.log(e)
                }
            },

            setAuthCookie(token) {
                const expirationDate = new Date()
                expirationDate.setDate(expirationDate.getDate() + 7)
                document.cookie = `authToken=${token}; expires=${expirationDate.toUTCString()}; path=/`
            },

            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },

            inputTypeChange(e) {
                e.target.closest('svg').previousSibling.type == 'text'
                    ? (e.target.closest('svg').previousSibling.type =
                          'password')
                    : (e.target.closest('svg').previousSibling.type = 'text')
            }
        },

        mounted() {}
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .login {
        padding: 170px 0 86px;
        background-color: $bege3;
        min-height: 70vh;
        @include breakpoint-max('mobile') {
            padding-top: 90px;
        }
        .password-error {
            right: 60px;
        }
        &__title {
            width: 100%;
            font-family: 'Oswald', sans-serif;
            font-size: 55px;
            line-height: 6rem;
            font-weight: 700;
            margin-bottom: 77px;
            color: $black;
            text-align: center;
            @include breakpoint-max('xs-tablet') {
                font-size: 55px;
                margin-bottom: 40px;
            }
            @include breakpoint-max('mobile') {
                font-size: 55px;
                line-height: 6rem;
            }
            @include breakpoint-max('s-mobile') {
                font-size: 6rem;
            }
        }
        &__form {
            display: flex;
            flex-direction: column;
            max-width: 1075px;
            margin: 0 auto;
            input {
                padding: 27px 25px;
                color: $black;
                font-size: 16px;
                line-height: 30px;
                border-radius: 0;
                font-family: 'Open Sans', sans-serif;
                outline: none;
                border: 1px solid $black;
                background-color: transparent;
                width: 100%;
                @include breakpoint-max('xs-tablet') {
                    padding: 17px 15px;
                }
                @include breakpoint-max('mobile') {
                    padding-top: 53px;
                    padding-left: 25px !important;
                }
            }
            label {
                display: block;
                position: relative;
                p {
                    font-weight: 700;
                    color: $black;
                    font-size: 16px;
                    line-height: 30px;
                    font-family: 'Open Sans', sans-serif;
                    position: absolute;
                    top: 51%;
                    transform: translateY(-50%);
                    left: 27px;
                    @include breakpoint-max('mobile') {
                        top: 20px;
                        transform: translateY(0%);
                        left: 28px;
                    }
                }
                .eyePassword {
                    position: absolute;
                    top: 50%;
                    right: 25px;
                    transform: translateY(-50%);
                    cursor: pointer;
                    @include breakpoint-max('mobile') {
                        top: 23px;
                        transform: translate(0);
                    }
                }
                #userID {
                    padding-left: 100px;
                }
            }
        }
        &__form-error {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 27px;
            font-weight: 700;
            color: $pink;
            font-size: 1.3rem;
            line-height: 3rem;
            font-family: 'Open Sans', sans-serif;
            @include breakpoint-max('mobile') {
                top: 35%;
            }
        }
        &__form-error-login {
            margin-top: 30px;
            p {
                font-weight: 700;
                color: $pink;
                font-size: 1.6rem;
                line-height: 3rem;
                font-family: 'Open Sans', sans-serif;
                text-align: center;
            }
        }
        &__form-pass {
            margin-top: 30px;
            p {
                font-weight: 700;
                font-size: 1.6rem;
                line-height: 3rem;
                font-family: 'Open Sans', sans-serif;
                text-align: center;
                a {
                    color: #000;
                }
            }
        }
        &__wrapper-bottom {
            display: flex;
            justify-content: center;
            text-align: center;
            margin-top: 60px;
            button {
                outline: none;
                font-size: 2.4rem;
                background-color: $pink;
                line-height: 3rem;
                color: $white;
                text-transform: uppercase;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                padding: 10px 40px;
                cursor: pointer;
            }
        }
        .loader {
            width: 227px;
            display: grid;
            place-items: center;
            svg {
                width: 24px;
                transform-origin: center;
                animation: rotate4 2s linear infinite;
            }

            circle {
                fill: none;
                stroke: $white;
                stroke-width: 2;
                stroke-dasharray: 1, 200;
                stroke-dashoffset: 0;
                stroke-linecap: round;
                animation: dash4 1.5s ease-in-out infinite;
            }

            @keyframes rotate4 {
                100% {
                    transform: rotate(360deg);
                }
            }

            @keyframes dash4 {
                0% {
                    stroke-dasharray: 1, 200;
                    stroke-dashoffset: 0;
                }

                50% {
                    stroke-dasharray: 90, 200;
                    stroke-dashoffset: -35px;
                }

                100% {
                    stroke-dashoffset: -125px;
                }
            }
        }
        &__info {
            text-align: center;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            margin-bottom: 50px;
            font-size: 2rem;
            line-height: 2.2rem;
            color: $blackRGBA;
        }
    }
</style>
